import { Payload } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import {
  getRolePlayHistory,
  getRolePlayHistoryV2,
  getRolePlayRelatedProblems,
} from "@/server/lb-chat-role-play";

export interface IRolePlayState {
  showRolePlayPopup: boolean;
  showRolePlayToast: boolean;
  // 最近聊天历史
  history: NRolePlay.IRoleHistoryItem[];
  // 当前对话角色扮演信息
  currentRolePlayInfo: Partial<NRolePlay.IRoleHistoryItem>;
  relatedProblems: any[];
}

export default {
  state: {
    showRolePlayPopup: false,
    showRolePlayToast: false,
    // 最近聊天历史
    history: [],
    currentRolePlayInfo: {},
    relatedProblems: [],
  } as IRolePlayState,
  reducers: {
    setRelatedProblems: (state: IRolePlayState, { payload }: Payload) => {
      return {
        ...state,
        relatedProblems: [...payload],
      };
    },
    setCurrentRolePlayInfo: (state: IRolePlayState, { payload }: Payload) => {
      return {
        ...state,
        currentRolePlayInfo: payload,
      };
    },
    setRolePlayPopupShow: (state: IRolePlayState, { payload }: Payload) => {
      return {
        ...state,
        showRolePlayPopup: payload,
      };
    },
    setRolePlayToastShow: (state: IRolePlayState, { payload }: Payload) => {
      return {
        ...state,
        showRolePlayToast: payload,
      };
    },
    // 设置历史对话列表
    setHistoryList: (state: IRolePlayState, { payload }: Payload) => {
      return {
        ...state,
        history: [...payload],
      };
    },
  },
  effects: {
    // 更新历史对话列表
    *updateHistory({}: Payload, { call, put }: EffectsCommandMap): Generator {
      const history = yield call(getRolePlayHistory);
      yield put({
        type: "setHistoryList",
        payload: (history as any).data,
      });
    },
    // 更新历史对话列表
    *updateHistoryV2({payload}: Payload, { call, put }: EffectsCommandMap): Generator {
      const history = yield call(getRolePlayHistoryV2, payload);
      yield put({
        type: "setHistoryList",
        payload: (history as any).data,
      });
    },
    // 更新当前对话角色扮演相关问题
    *updateRelatedProblems(
      { payload }: Payload,
      { call, put }: EffectsCommandMap,
    ): Generator {
      const list: any = yield call(getRolePlayRelatedProblems, payload);
      if (list.data.length) {
        yield put({
          type: "setRelatedProblems",
          payload: list.data
            .split("\n")
            .map((item: string) => item.replace(/^\d+(\.|、)\s*/, "")),
        });
      } else {
        yield put({
          type: "setRelatedProblems",
          payload: [],
        });
      }
    },
  },
};
