import axios from "axios";
import { IS_PROD } from "@/const";
import { uniq } from 'lodash'

/**
 * 测试环境检测域名列表
 */
export const devDomainList = [
  "https://dev-chat.checkmore.net",
  "https://dev2-chat.checkmore.net",
  "https://dev3-chat.checkmore.net",
  "https://test-chat.checkmore.net",
  "https://stg-chat.checkmore.net",
  "https://test-chat.chatyy.com",
];

/**
 * 生产环境检测域名列表
 */
const prodDomainList = [
  "https://api.xueshuweishi.com",
  "https://api.cha-tai.com",
  "https://www.chatyy.org",
  "https://www.chatleft.com",
  "https://www.chatthree.com",
];

const SWITCH_DOMAIN_KEY = "lbSwitchDomain";

export const getSwitchDomain = () => localStorage.getItem(SWITCH_DOMAIN_KEY) || "";

/**
 * 切换域名
 * localStorage 有值时使用该值并清空
 * 调用 setDomain 时在 localStorage 保存该值并刷新页面
 */
export const switchDomain = () => {
  const domain = getSwitchDomain();
  const setDomain = (domain: string) => {
    localStorage.setItem(SWITCH_DOMAIN_KEY, domain);
    location.reload();
  };

  if (domain) {
    axios.defaults.baseURL = domain;
    localStorage.removeItem(SWITCH_DOMAIN_KEY);
  }

  return { setDomain };
};

/**
 * 检测域名访问性
 * @param delay 多久检测一次
 */
const checkDomainAvailability = (delay = 30000) => {
  const { setDomain } = switchDomain();

  const nextCheck = () => {
    setTimeout(() => {
      checkDomainAvailability(delay);
    }, delay);
  };

  // 首先检测当前使用域名
  let domainList = IS_PROD ? [...prodDomainList] : [...devDomainList];
  domainList = uniq([axios.defaults.baseURL as string, ...domainList]);

  const check = (index: number) => {
    if (index >= domainList.length) {
      nextCheck();
    } else {
      const domain = domainList[index]
      axios.create().get(`${domain}/chat-server/`).then(response => {
        // 返回为json并且code为0判定该域名可用
        if (typeof response === "object" && ((response as any).code === 0 || (response as any)?.data?.code === 0)) {
          // 检测可用域名与当前使用域名不同时保存可用域名刷新页面
          if (axios.defaults.baseURL !== domain) {
            nextCheck();
            return setDomain(domain);
          } else {
            nextCheck()
          }
        } else {
          check(++index);
        }
      }, () => {
        check(++index);
      })
    }
  };

  check(0);
};

export default checkDomainAvailability;
