import {Payload} from "@/types/type";
export interface ChatDataAnalysis {
  // 是否禁止切换历史记录and 新建
  disableChangeHistory: boolean
  // 提示vip
  vipTipModalShow: boolean
}

export default {
  state: {
    disableChangeHistory: false,
    vipTipModalShow: false,
    refuelingBag:false
  } as ChatDataAnalysis,
  reducers: {
    setDisableChangeHistory(state: ChatDataAnalysis, {payload}: Payload) {
      return {
        ...state,
        disableChangeHistory: payload
      }
    },
    setVipTipModalShow(state: ChatDataAnalysis, {payload}: Payload) {
      return {
        ...state,
        vipTipModalShow: payload
      }
    },
    reset() {
      return {
        disableChangeHistory: false,
        vipTipModalShow: false
      }
    },
    setRefuelingBag(state: ChatDataAnalysis, {payload}: Payload) {
      return {
        ...state,
        refuelingBag: payload
      }
    },
  }
};
