import { catch_http } from "./request";
import { checkBenefitInfoDataTs } from "@/server/types/lb-painting";
import { isDesk } from "@/utils/utils";

// export const paymentQrcode = (data: object) =>
//   catch_http("post", "/chat-server/payment/qrcode", data, {
//     headers: getPromoteUser(),
//   });

// 加载prompt分类列表
export const getPromptCategoryList = () =>
  catch_http("post", `/chat-server${isDesk() ? '/desk' : ''}/user/prompt-scene-category/list`);

// 根据id获取prompt详情列表
// export const getPromptDetailList = (id: string) =>
//     catch_http("get", `/chat-server/user/prompt-scene-content/get-detail/${id}`);

export const getPromptDetailList = (data: {
  current: number;
  size: number;
  categoryId: string;
}) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? '/desk' : ''}/user/prompt-scene-content/page-of-list`,
    data
  );

// 搜索
export const searchPromptDetailList = (data: { title: string }) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? '/desk' : ''}/user/prompt-scene-content/search-list`,
    data
  );

// 收藏
export const collectPrompt = (data: { targetId: string }) =>
  catch_http("post", `/chat-server${isDesk() ? '/desk' : ''}/user/user-collect-record/add`, data);

//取消收藏
export const removeCollect = (id: string) =>
  catch_http("post", `/chat-server${isDesk() ? '/desk' : ''}/user/user-collect-record/remove/${id}`);

// 收藏列表
export const collectionList = () =>
  catch_http("post", `/chat-server${isDesk() ? '/desk' : ''}/user/user-collect-record/list`, {
    current: 1,
    size: 1,
  });

// 获取gpt的模式
export const getGptModes = () =>
  catch_http("get", "/chat-server/api/chat/model-config");

// 获取gpt的模式V2
export const getGptModesV2 = () =>
  catch_http("get", `/chat-server/api/chat/model-config-${isDesk() ? "v3" : "v2"}`).then(res => {
    // res.data = res.data.slice(2)
    // res.data[0].isDefault = true

    // return new Promise(resolve => {
    //   setTimeout(() => resolve(res), 3000)
    // })
    return Promise.resolve(res)
  });

/**
 * 获取会员信息
 * @returns
 */
export const checkBenefitInfo = () => {
  return catch_http<checkBenefitInfoDataTs>("get", "/chat-server/api/chat/check-benefit-info");
}

// 获取个人模板列表
export const getPromptTemplete = (data: {
  current: number;
  size: number;
  title: string;
}) => catch_http("post", "/chat-server/api/user/template/list", data);

//根据id更新个人模板
export const updateTemplateById = (data: {
  content: string;
  id: string;
  title: string;
}) => catch_http("post", "/chat-server/api/user/template/update", data);

//添加个人模板
export const addTemplate = (data: {
  content: string;
  targetType: "session" | "draw";
  title: string;
  systemTemplateId?: string;
}) => catch_http("post", "/chat-server/api/user/template/add", data);

//置顶个人模板
export const topTemplate = (data: { id: string }) =>
  catch_http("get", "/chat-server/api/user/template/top", data);

//取消置顶
export const topTemplateCancel = (data: { id: string }) =>
  catch_http("get", "/chat-server/api/user/template/cancel-top", data);

//删除个人模板-批量
export const templateDel = (data: { ids: string[] }) =>
  catch_http("post", "/chat-server/api/user/template/delete-batch", data);
