import {copyOrigin} from "@/utils/utils";
import {message} from "antd";

export const duplication = (text?: string | null) => {
  if (!text) {
    return
  }

  try {
    copyOrigin(text);
  } catch (err) {
    message.warning("无法复制文本");
  }
};