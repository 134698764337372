/*
 * 够团vip
 * */
import {Payload, RootState} from "@/types/type";
import {EffectsCommandMap} from "@umijs/max";
import {requestGroupVipExchange, requestGroupVipPayRecords} from "@/server/group-vip";

// 是否有检查过购买团购记录， 只检查一次就行
let hasCheckedHasBuyGroupVipRecord = false

export default {

  state: {
    // 购买弹框是否展示
    showGroupVipBuyPop: false,
    // 购买记录弹框是否展示
    showGroupVipBuyRecordsPop: false,

    // 是否有购买过团购vip
    hasBuyGroupVipRecord: false,

  } as NGroupVip.IModelState,

  reducers: {

    set: (
      state: NGroupVip.IModelState,
      {payload}: Payload
    ) => {
      return {
        ...state,
        ...payload
      };
    },

  },

  effects: {

    // 兑换会员
    * exchange(
      {payload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      const exchangeCode = payload

      const res = (yield call(
        () => requestGroupVipExchange(exchangeCode)
      )) as NCommon.IHttpRes<NGroupVip.IExchangeResult>;

      const {isSuccess, code, data} = res

      // 更新下权益相关信息
      if (isSuccess) {
        yield put({type: "userInfo/refreshUserInfo"});
        yield put({type: "leftSideX/refreshVipsEndTimeList"});
        yield put({type: "userParameter/getUserBenefit"});
      }

      return res
    },

    // 检查用户是否有购买过
    * checkHasBuyGroupVipRecord(
      {payload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      if (hasCheckedHasBuyGroupVipRecord) {
        return
      }

      const res = (yield call(
        () => requestGroupVipPayRecords()
      )) as NCommon.IHttpRes<NCommon.RecordResponse<NGroupVip.IPayRecordsItem>>;

      const {isSuccess, code, data} = res

      if (!isSuccess) {
        return
      }

      hasCheckedHasBuyGroupVipRecord = true

      yield put({
        type: "set",
        payload: {
          hasBuyGroupVipRecord: data.records.length > 0
        },
      });
    },

  },

};
