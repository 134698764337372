/*
* 网盘上传用
* */
import { getFileName, getFileSuffix, getUID, uploadFileByParts } from "@/utils";
import { Payload, RootState } from "@/types/type";
import { EffectsCommandMap, getDvaApp } from "@umijs/max";
import { PubSubType } from "@/const";

interface IQueueItem {
  uid: string;
  file: File;
  folderId: string;
  status: NUpload.TStatus;
  mission: NUpload.IUploadMission | null;
}

// 上传队列
const queue: IQueueItem[] = [];

export default {
  state: {
    // 上传任务列表
    missionList: [],

    // 上传总摘要
    summary: {
      show: false,
      lastFileName: "",
      activeMission: null,
      totalCount: 0,
      uploadingIndex: 0,
      successCount: 0,
      errorCount: 0,
      errorMissionList: [],
    },
  },

  reducers: {
    setSummary: (state: NUpload.IUploadFilesState, { payload }: Payload) => {
      return {
        ...state,
        summary: payload,
      };
    },
  },

  effects: {
    *addToUploadFileQueue(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      // 用户也有可能就是想上传多份（再不同的文件夹里）
      // if (queue.find((a) => a.file === payload.file)) {
      //   return;
      // }

      const item: IQueueItem = {
        uid: getUID("uploadQueue"),
        file: payload.file,
        folderId: payload.folderId,
        status: "waiting",
        mission: null,
      };

      const suffix = getFileSuffix(payload.file);

      if (!["png", "jpg", "jpeg", "pdf"].includes(suffix)) {
        item.status = "error";
        item.mission = {
          uid: item.uid,
          name: getFileName(payload.file),
          suffix,
          size: payload.file.size,
          progress: 0,
          status: "error",
          reason: "不支持的文件类型",
          cancel: () => {},
        };
      }

      queue.push(item);

      yield put({
        type: "updateSummary",
      });
    },

    *updateSummary(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const summary = (yield select(
        (state: RootState) => state.useUploadFiles.summary
      )) as NUpload.IUploadFilesSummary;

      let theActive = queue.find((a) => a.status === "uploading");

      if (!theActive) {
        const theWaiting = queue.find((a) => a.status === "waiting");

        if (theWaiting) {
          // 防止updateSummary被多次执行时，重复进入了这里
          theWaiting.status = "uploading";

          yield put({
            type: "uploadFile",
            payload: {
              file: theWaiting.file,
              folderId: theWaiting.folderId,
              uid: theWaiting.uid,
            },
          });

          theActive = theWaiting;
        }
      }

      const errorMissionList = queue
        .filter((a) => a.status === "error")
        .map((a) => a.mission!);

      const fileType = theActive?.file ? getFileSuffix(theActive.file) : summary.fileType;
      
      yield put({
        type: "setSummary",
        payload: {
          show: queue.length > 0,
          fileType: fileType,
          lastFileName: theActive?.mission?.name || summary.lastFileName || "",
          activeMission: theActive?.mission || null,
          totalCount: queue.length,
          uploadingIndex: theActive
            ? queue.findIndex((a) => a === theActive)
            : 0,
          successCount: queue.filter((a) => a.status === "success").length,
          errorCount: errorMissionList.length,
          errorMissionList: errorMissionList,
        },
      });
    },

    *closeUploadPop(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ) {
      const summary = (yield select(
        (state: RootState) => state.useUploadFiles.summary
      )) as NUpload.IUploadFilesSummary;
      getDvaApp()._store.dispatch({
        type: "useUploadFiles/setSummary",
        payload: {
          ...summary,
          progress: 0,
          show: false,
        },
      });

      queue.splice(0, queue.length);
    },

    *uploadFile(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const uid = payload.uid;
      const theQueueItem = queue.find((a) => a.uid === uid);

      const missionItem = uploadFileByParts({
        uid: payload.uid,
        file: payload.file,
        chunkSize: 3,
        folderId: payload.folderId,
        onStatusChange: function (status) {
          const theQueueItem = queue.find((a) => a.uid === uid);

          if (!theQueueItem) {
            return;
          }

          theQueueItem.status = status;

          getDvaApp()._store.dispatch({ type: "useUploadFiles/updateSummary" });
        },
        onProgress() {
          getDvaApp()._store.dispatch({ type: "useUploadFiles/updateSummary" });
        },
        onComplete(status: NUpload.TStatus) {
          if (status === "success") {
            PubSub.publish(PubSubType.REFRESH_NET_DIST_LIST, {uid});
            PubSub.publish(PubSubType.NET_DIST_REFRESH_CAPACITY);
          }
        },
      });

      if (theQueueItem) {
        theQueueItem.mission = missionItem;
      }
    },

    *clear(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      queue.forEach((item) => {
        if (item.mission && item.mission.status === "uploading") {
          item.mission.cancel();
        }
      });

      queue.splice(0, queue.length);

      yield put({ type: "updateSummary" });
    },

    *cancelMission(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const index = queue.findIndex((a) => a.uid === payload.uid);
      // console.error('cancelMission payload.uid', payload.uid, index)
      if (index <= -1) {
        return;
      }

      const item = queue[index];

      item.mission?.cancel();

      queue.splice(index, 1);

      yield put({ type: "updateSummary" });
    },
  },
};
