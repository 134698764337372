import SparkMD5 from "spark-md5";

export const getUID = (function () {
  let id = 1;

  return function (prefix?: string) {
    return (prefix || "f") + "-" + id++;
  };
})();

// 获取文件的md5值
export const getFileMd5 = function (file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      // 获取文件内容
      const spark = new SparkMD5.ArrayBuffer();

      spark.append(event.target!.result as ArrayBuffer);

      // 计算文件内容的md5值
      const hash = spark.end() as string;

      resolve(hash);
    };

    reader.onerror = function (event) {
      reject("文件读取失败(md5)");
    };

    // 读取文件内容
    reader.readAsArrayBuffer(file);
  });
};

// 获取文件的后缀
export const getFileSuffix = function (file: File): string {
  const names = file.name.split(".");

  if (names.length > 1) {
    return names[names.length - 1].toLowerCase();
  }

  if (file.type.match(/pdf/i)) {
    return "pdf";
  }

  if (file.type.match(/word/i)) {
    return "docx";
  }

  if (file.type.match(/image/i)) {
    return file.type.replace("image/", "").toLowerCase();
  }

  // console.log('file.type', file.type)

  return "unknown";
};

export const getFileName = function (file: File): string {
  return file.name.replace(/\.[^.]*$/, "");
};

//文本转md格式
export const getMdUrl = (text: string) => {
  const blob = new Blob([text], {
    type: "text/markdown",
  });
  const objectURL = URL.createObjectURL(blob);
  return objectURL;
};

export const base64ToBlob = function (code: string): Blob {
  const parts = code.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new window.Blob([uInt8Array], { type: contentType })
}

//下载
export const downloadFile = (url: string | Blob, fileName: string) => {
  const a = document.createElement("a");

  let blob: Blob|null= null
  let theUrl: string = ''

  if(typeof url ==='string' && url.match(/;base64/)){
    blob = base64ToBlob(url)
  }

  if(url instanceof Blob){
    blob = url
  }

  if(blob){
    theUrl = URL.createObjectURL(blob)
  }else{
    theUrl = url as string
  }

  a.href = theUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  if(blob){
    setTimeout(() => {
      window.URL.revokeObjectURL(theUrl)
      document.body.removeChild(a);
    }, 0)
  }
};

// json转markdown
export const jsonToMd = (json: any[], level = 1) => {
  let output = "";
  json.forEach((item) => {
    output += `${"#".repeat(level) + " " + item.content.title}\n\n`;
    if (item.children.length > 0) {
      output += jsonToMd(item.children, level + 1);
    }
  });
  return output;
};
