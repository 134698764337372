export function getBase64Image(imgUrl: string) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.setAttribute("crossOrigin", "anonymous"); // 解决跨域 Canvas 污染问题

    img.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    img.onerror = reject;

    img.src = imgUrl;
  });
}
