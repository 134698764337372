import {
  getIdbyCanversationId,
  paintingMessageSearch,
  paintingMessageSend,
  paintingPageList,
} from "@/server/lb-painting";
import { Payload, RootState } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import { message } from "antd";

export interface PaintConf {
  conversationId: string;
  algorithm: string;
  creativity: number;
  dimensionMax: number;
  dimensionMin: number;
  id: string;
  model: number;
  upbeta: number;
  niji: number;
  photography: number;
  seed: string | number | null;
  materialFilter: string;
  diversity: number;
}

export interface PaintingParameter {
  hasPaintingConversation?: boolean;
  isPaintingScrollEnd?: boolean;
  paintingMessageResult?: any;
  paintingTextareaContent?: string;
  taskSelect?: any;
  chatPaintingList?: any;
  paintingItem?: {
    id?: string;
  };
  drawLoading?: boolean;
  image2promptLoading: number;
  paddingPollingResult?: any;
  currentChoosePainting?: number;
  paintingParams?: any;
  paintingSwitch?: string;
  paintingChatLoading?: boolean;
  paintingHistoryParams?: {
    current: number;
    size: number;
  };
  paintingLoading?: boolean;
  isPaintScrollEnd?: boolean;
  paintTaskid?: string;
  configurationId?: string;
  paintOssKey?: string;
  hasTextareaFocus?: boolean;
  isHistoryDataEnd?: boolean;
  fastCountDva?: number;
  relaxCountDva?: number;
  choosePicType?: number;
  hasGpt4Checked?: boolean;
  imgSource?: number | string;
  showOptimizeView: boolean;
  drawOption: any;
  directiveChecked: boolean;
  diagram: {
    imgUploadUrl: string;
    imgReadUploadUrl: string;
  };
  isClickIndex: boolean;
  lastTimeText: string;
  lastTimeData: { title: string; content: string }[];
  uuid: number;
  paintConf: PaintConf | null;
  showToolbar: boolean;
  isCollectSimilar: boolean;
  paintingSetting: any;
}

export default {
  state: {
    directiveChecked: false,
    diagram: {
      imgUploadUrl: "",
      imgReadUploadUrl: "",
    },
    isClickIndex: false,
    paintingTextareaContent: "", // 绘画会话输入框
    hasPaintingConversation: false, // 绘画组件初始变量
    isPaintingScrollEnd: false, // 绘画会话是否滚动到底部
    drawLoading: false, // 绘画生成loading
    image2promptLoading: 0,
    paintingMessageResult: [], // 绘画会话msg列表
    taskSelect: {}, // 任务选择
    // 左侧绘画列表
    chatPaintingList: {},
    // 会话选中的对象
    paintingItem: {}, //顶级conversationId
    // 单条会话返回的数据
    paintingItemResult: {},
    // 轮询结果
    paddingPollingResult: {},
    currentChoosePainting: 1, //当前需要操作的图
    paintingParams: {
      action: "IMAGINE", // IMAGINE:出图；UPSCALE:选中放大；VARIATION:选中其中的一张图，生成四张相似的；REROLL:重新生成; DESCRIBE:图转指令
      messageContent: "", // action=IMAGINE时，内容是prompt; action=UPSCALE/VARIATION,内容是index
      originImageOssKey: "", // 图片osskey
      taskId: "", // 任务id
      commandOptimizeSwitch: 0, //指令优化开关(0-关闭，1-开启)
      promptType: "", //prompt类型 logo提问优化：logo_optimize
      // conversationId: '',
    }, // 绘画参数
    paintingSwitch: "", // 绘画组件切换 'start', 'stop'
    paintingHistoryParams: {
      current: 1,
      size: 10,
    },
    // 列表切换loading
    paintingLoading: false,
    // 绘画会话loading, loading的时候不允许发送消息
    paintingChatLoading: false,
    // 滚动到底部才可以触顶加载
    isPaintScrollEnd: false,
    // 用户上传图片的taskid
    paintTaskid: "",
    // 会话配置id 跟会话绑定
    configurationId: "",
    paintOssKey: "",
    // 判断是否输入框聚焦
    hasTextareaFocus: false,
    // 判断历史数据是否加载完
    isHistoryDataEnd: false,
    // 剩余张数
    fastCountDva: 0,
    relaxCountDva: 0,
    // 选择生成图片类型
    choosePicType: 0,
    // 是否显示gpt4.0开关
    hasGpt4Checked: false,
    // 图片上传来源(0-用户上传，1-图片搜索，2-图片地址解析)
    imgSource: "",
    //优化界面
    showOptimizeView: false,
    //绘画要求
    drawOption: {},
    //上一次猜你想要请求的文本
    lastTimeText: "",
    //上次猜你想要数据
    lastTimeData: [],
    // uuid 用于视图强制刷新
    uuid: 0,
    paintConf: null,
    showToolbar: false,
    isCollectSimilar: false,
    paintingSetting: null,
    // paintingSetting: {
    //   algorithm: "5.2",
    //   model: 0,
    //   width: 1,
    //   height: 1,
    //   exclude: "",
    //   seedNumber: 1,
    //   openSeed: false,
    //   sliderValues: [0.25, 0, 100],
    //   drawingMode: false,
    //   imageHQ: false,
    //   animeState: "0",
    // },
    paintingSettingId: "",
  } as PaintingParameter,
  reducers: {
    setHasPaintingConversation: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        hasPaintingConversation: payload,
      };
    },
    setDirectiveChecked: (state: PaintingParameter, { payload }: Payload) => ({
      ...state,
      directiveChecked: payload,
    }),
    setDiagram: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        diagram: payload,
      };
    },
    setPaintingSetting: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingSetting: {
          ...state.paintingSetting,
          ...payload,
        },
      };
    },
    setPaintingSettingId: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingSettingId: payload,
      };
    },
    setIsClickIndex: (state: PaintingParameter, { payload }: Payload) => ({
      ...state,
      isClickIndex: payload,
    }),
    setIsPaintingScrollEnd: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        isPaintingScrollEnd: payload,
      };
    },
    setHasGpt4Checked: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        hasGpt4Checked: payload,
      };
    },
    setPaintingTextarea: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingTextareaContent: payload,
      };
    },
    setTaskSelect: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        taskSelect: payload,
      };
    },
    setChatPaintingList: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        chatPaintingList: {
          ...payload,
          records: payload?.records.map((item: any) => {
            return {
              ...item,
              currentType: "painting",
            };
          }),
        },
      };
    },
    setPaintingItem: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingItem: payload,
      };
    },
    setPaintingMessageResult: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      console.log("payload", payload);

      // let hasFindError = false;

      // // 过滤重复的id
      // if(payload && payload.length) {
      //   const ids: string[] = []

      //   payload = payload.filter((a: any) => {
      //     if(ids.includes(a.id)){
      //       hasFindError = true
      //       return false
      //     }

      //     ids.push(a.id)
      //     return true
      //   })
      // }

      // if(hasFindError && location.host.match(/192|localhost/)){
      //   console.error('setPaintingMessageResult，设置有重复的id数据')
      // }

      return {
        ...state,
        paintingMessageResult: payload,
      };
    },
    setPaintingItemResult: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingItemResult: payload,
      };
    },
    setDrawLoading: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        drawLoading: payload,
      };
    },
    setImage2promptLoading(state: PaintingParameter, { payload }: Payload) {
      return {
        ...state,
        image2promptLoading: Math.max(
          0,
          state.image2promptLoading + (payload ? 1 : -1),
        ),
      };
    },
    setPaddingPollingResult: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paddingPollingResult: payload,
      };
    },
    setCurrentChoosePainting: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        currentChoosePainting: payload,
      };
    },
    setPaintingParams: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingParams: payload,
      };
    },
    setPaintingSwitch: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingSwitch: payload,
      };
    },
    setPaintingHistoryParams: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paintingHistoryParams: payload,
      };
    },
    setPaintingLoading: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintingLoading: payload,
      };
    },
    setPaintingChatLoading: (
      state: PaintingParameter,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paintingChatLoading: payload,
      };
    },
    setIsPaintScrollEnd: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        isPaintScrollEnd: payload,
      };
    },
    setPaintOssKey: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintOssKey: payload,
      };
    },
    setConfigurationId: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        configurationId: payload,
      };
    },
    setHasTextareaFocus: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        hasTextareaFocus: payload,
      };
    },
    setIsHistoryDataEnd: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        isHistoryDataEnd: payload,
      };
    },
    setFastCountDva: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        fastCountDva: payload,
      };
    },
    setRelaxCountDva: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        relaxCountDva: payload,
      };
    },
    setChoosePicType: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        choosePicType: payload,
      };
    },
    setImgSource: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        imgSource: payload,
      };
    },
    setShowOptimizeView: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        showOptimizeView: payload,
      };
    },
    setDrawOption: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        drawOption: payload,
      };
    },
    setLastTimeText: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        lastTimeText: payload,
      };
    },
    setLastTimeData: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        lastTimeData: payload,
      };
    },
    setUuid: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        uuid: payload,
      };
    },

    setPaintConf: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        paintConf: payload,
      };
    },
    setShowToolbar: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        showToolbar: payload,
      };
    },
    setIsCollectSimilar: (state: PaintingParameter, { payload }: Payload) => {
      return {
        ...state,
        isCollectSimilar: payload,
      };
    },
  },
  effects: {
    // 获取左侧绘画列表
    *getPaintingPageList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      try {
        const res: any = yield call(paintingPageList, payload);
        if (res?.code == 0) {
          yield put({
            type: "setChatPaintingList",
            payload: res.data,
          });
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },
    // 发起会话
    *paintingChat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      try {
        const conf = (yield select((state: RootState) => {
          return state.paintingParameter.paintConf;
        })) as PaintConf;

        const res: any = yield call(paintingMessageSend, {
          ...payload,
          algorithm: conf?.algorithm || "5.2",
        });

        if (res?.code == 0) {
          yield put({
            type: "setPaintingItem",
            payload: { id: res.data.conversationId },
          });
          yield put({
            type: "setPaintingItemResult",
            payload: res.data,
          });
          message.destroy();
        } else {
          message.error(res.msg);
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },

    // 查询单词聊天
    *searchPaintingStatus(
      { payload }: Payload,
      { call, put }: EffectsCommandMap,
    ): Generator {
      try {
        const res: any = yield call(paintingMessageSearch, payload);
        if (res?.code == 0) {
          yield put({
            type: "setPaddingPollingResult",
            payload: res.data,
          });
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },
    *getPaintingMessageResult(
      { payload }: Payload,
      { call, select, put }: EffectsCommandMap,
    ): Generator {
      return yield select((state: RootState) => {
        return state.paintingParameter.paintingMessageResult;
      });
    },
    *fetchPaintConf(
      { payload }: Payload,
      { call, select, put }: EffectsCommandMap,
    ): Generator {
      const { paintingItem, paintConf } = (yield select((state: RootState) => {
        return state.paintingParameter;
      })) as PaintingParameter;

      if (paintingItem && paintingItem.id) {
        const res: any = yield call(getIdbyCanversationId, {
          conversationId: paintingItem.id,
        });
        let newData = res.data;
        if (newData && !newData?.conversationId) {
          newData.conversationId = paintingItem.id;
        }

        yield put({
          type: "setPaintConf",
          payload: newData,
        });

        return newData;
      } else {
        return paintConf?.id ? paintConf : null;
      }
    },
  },
};
