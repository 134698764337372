import {getDvaApp} from "@@/exports";

type TCallback = () => void

// store是否已经就绪, 0:未检查，1：检查中，2：已就绪
let appStoreReadyStatus = 0

// 待执行队列
const appStoreReadyQueue: TCallback[] = []

// 检查是否已经就绪
const checkStoreIsReady = function () {
  if (appStoreReadyStatus !== 0) {
    return
  }

  appStoreReadyStatus = 1

  const check = function () {
    if (!getDvaApp()?._store?.dispatch) {
      setTimeout(check, 50)
      return
    }

    appStoreReadyStatus = 2

    while (appStoreReadyQueue.length) {
      appStoreReadyQueue.pop()!()
    }
  }

  check()
}

// 当store就绪时执行
export const onStoreReady = function (callback: TCallback) {
  if (appStoreReadyStatus === 2) {
    callback()
    return
  }

  appStoreReadyQueue.push(callback)
  checkStoreIsReady()
}

export const dispatch = function (data: { type: string, payload?: any }): any {
  return new Promise(resolve => {
    onStoreReady(() => {
      resolve(getDvaApp()?._store?.dispatch(data))
    })
  })
};