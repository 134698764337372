import { API_HOST, PubSubType } from "@/const";
import { Payload, RootState } from "@/types/type";
import { eventSource } from "@/utils/eventSource";
import { EffectsCommandMap, getDvaApp, useNavigate } from "@umijs/max";
import { getLoginToken } from "./userInfo";
import { getChatTextLengthTip, getRouterParams } from "@/utils/utils";
import { conversationSessionId } from "@/server/lb-chat";
import { commonModalConfirm, getDav, showVipPayPop } from "@/utils";
import { message } from "antd";
import { userFreeTimes } from "@/server/lb-login-auth";
import { IChatOptimizeQuestionStore } from "./chatOptimizeQuestion";
import PubSub from "pubsub-js";
import { ChatParameter } from "./chatParameter";
import { getCustomLastMsg, getCustomSessionId, getCustomTestSessionId } from "@/server/customModel";

interface ChatGptConfig {
  id: string;
  frequency_penalty: number;
  max_tokens: number;
  model: string;
  presence_penalty: number;
  temperature: number;
  top_p: number;
  fileDataId?: string;
  // 多文件对话
  gptFileIds?: string[];
  fileMarkType?: string;
  sceneInfo: string;
  useInternet: 0 | 1;
  messages: Array<any>;
  beforeProblemId?: string;
  isContinue?: boolean;
  pluginType?: string[];
  sessionId?: string;
  rolePlayId?: string;
  promptId?: string | number;
  imageRecognitionId?: string;
  operationType?: string;
  editChatMessage?: boolean;
  editMessageId?: string;
  isTest?: boolean;
}
export interface CustomChatMessage {
  hasContinue: boolean;
  chatLoading: boolean;
  messageList: NCustomModel.IMessageListItem[];
  loadingHistory: boolean;
  customChatGptConfig: ChatGptConfig;
  cancelToken: AbortController;
  errorCode: Array< string>;
  userFreeTimesCount: NConversation.userFreeTimesCountTs;
  inputValue: string;
  createInfo: NCustomModel.ICreateInfo | null ;
  sessionInfo: NCustomModel.ISessionInfo | null;
}

const getDvaStore = () => {
  return getDvaApp()._store;
};

const updataLeftSideHistory = () => {
  const rootState = getDvaStore().getState() as RootState;
  // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
  if (
    rootState.customChatMessage.messageList.length > 0 &&
    rootState.customChatMessage.messageList.length <= 3
  ) {
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
  }

  const newLastItem = getDvaStore()
    .getState()
    .customChatMessage.messageList.at(-1);
  const oldLastItem = getDvaStore()
    .getState()
    .customChatMessage.messageList.at(-3);
  console.log(newLastItem, oldLastItem, "newLastItem, oldLastItem");

  // 文件解读
  if (
    newLastItem?.fileDataId &&
    newLastItem?.fileDataId !== oldLastItem?.fileDataId
  ) {
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
  }

  // 多文件对话
  if (
    newLastItem?.gptFiles !== oldLastItem?.gptFiles &&
    !!newLastItem?.gptFileIds?.length
  ) {
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY, true);
  }
  // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END
};

export const getDefaultChatGptConfig = () => {
  return {
    id: '',
    // gpt相关配置信息
    frequency_penalty: 0.0,
    max_tokens: 512,
    model: "gpt-4",
    presence_penalty: 0.0,
    temperature: 0.73,
    top_p: 1.0,
    sceneInfo: "lb-chat-ui",
    sessionId: "",
    messages: [],
    useInternet: 0,
  };
};

export default {
  state: {
    inputValue: "",
    hasContinue: false,
    chatLoading: false, // ai回答sse请求loading
    messageList: [], // 对话列表
    loadingHistory: false, // 历史数据加载loading
    customChatGptConfig: getDefaultChatGptConfig(),
    cancelToken: new AbortController(), // 取消请求
    errorCode: [
      "-20010",
      "-2002",
      "-2004",
      "-2006",
      "-2007",
      "-2021",
      "-2022",
      "-20004",
      "401",
      "-401",
      "-1",
      "-2052",
      "-2053"
    ], //错误状态码
    createInfo: null,
    sessionInfo: null,
    userFreeTimesCount: {} as NConversation.userFreeTimesCountTs, //用户免费次数
  } as CustomChatMessage,
  reducers: {
    setData: (state: CustomChatMessage, { payload }: Payload) => {
      return {
        ...state,
        ...payload,
      };
    },

    setUserFreeTimesCount: (state: CustomChatMessage, { payload }: Payload) => {
      return {
        ...state,
        userFreeTimesCount: payload,
      };
    },
  },
  effects: {
    // 获取用户免费次数
    *getUserFreeTimes(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const { lbChatUserInfo } = (yield select(
          (state: RootState) => state.userInfo
        )) as NUser.IUserInfoState;

        if (lbChatUserInfo?.loginToken && !lbChatUserInfo?.isVip) {
          const res: any = yield call(userFreeTimes, payload);
          if (res?.code == 0) {
            yield put({
              type: "setUserFreeTimesCount",
              payload: res.data,
            });
          }
          return Promise.resolve(res);
        }
      } catch (err) {
        return Promise.reject();
      }
    },
    *stop(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const { cancelToken } = (yield select(
        (state: RootState) => state.customChatMessage
      )) as CustomChatMessage;

      cancelToken.abort();
    },
    // 对话列表会话
    *chat(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {

      const {
        inputValue,
        sessionInfo,
      } = (yield select(
        (state: RootState) => state.customChatMessage
      )) as CustomChatMessage;

      let inputVal = inputValue;

      const { selectedChatRules, allChatRuleItems } = (yield select(
        (state: RootState) => {
          return state.chatOptimizeQuestion;
        }
      )) as IChatOptimizeQuestionStore;

      if (selectedChatRules.length && allChatRuleItems.length) {
        const optimizeText = allChatRuleItems
          .filter((n) => selectedChatRules.includes(n.id))
          .map((n) => n.content)
          .join(" ");
        inputVal = `${inputVal?.trim()} ${optimizeText}`;
      }

      // 判空是否未输入
      if (!payload && !inputValue) {
        message.destroy();
        message.warning("请输入要提问的内容~");
        return;
      }

      if (getChatTextLengthTip(inputValue || payload.content || "")) {
        return;
      }

      yield put({
        type: "setData",
        payload: {hasContinue: false},
      });

      // 获取用户信息
      const { lbChatUserInfo } = (yield select(
        (state: RootState) => state.userInfo
      )) as NUser.IUserInfoState;

      if (!lbChatUserInfo?.loginToken) {
        yield put({
          type: "userParameter/setHasResetPassword",
          payload: false,
        });
        yield put({ type: "userParameter/setHasLoginPop", payload: true });
        // 未登录情况下发送的消息问题
        localStorage.setItem("lb-chat-noLogin-text", payload || inputValue);
        return false;
      }

      let { messageList } = (yield select(
        (state: RootState) => state.customChatMessage
      )) as CustomChatMessage;

      const oldSessionId = getRouterParams().id;

      const modelRecordId = sessionInfo?.modelRecordId || oldSessionId;

      const cancelToken = new AbortController();
      yield put({
        type: "setData",
        payload: { chatLoading: true, cancelToken },
      });

      // // 新会话
      const { customChatGptConfig, errorCode } = (yield select(
        (state: RootState) => state.customChatMessage
      )) as CustomChatMessage;

      const notBindFileCode = errorCode.filter(
        (n) => !["-20004", "-20010"].includes(n as string)
      );

      // 开始流式传输前 解析请求信息
      let requestConfig: ChatGptConfig = {
        ...customChatGptConfig,
        fileDataId: customChatGptConfig.fileDataId,
        messages: messageList,
        sessionId: sessionInfo?.id
      };


      yield put({
        type: "setData",
        payload: { customChatGptConfig: requestConfig },
      });

      const cursorEl = `<i class="sse-cursor"></i>`;
      const newMessages: NCustomModel.IMessageListItem[] = [
        {
          content: payload?.fileName || (inputVal as string),
          isDone: true,
          tempId: Date.now().toString() + "1",
          isError: false,
          id: "",
          messageType: 1,
          ...(payload ? payload : {}),
          role: "user",
        },
        {
          isDone: false,
          tempId: Date.now().toString() + "2",
          isError: false,
          id: "",
          messageType: 2,
          ...(payload ? payload : {}),
          content: cursorEl,
          role: "assistant",
        },
      ];

      let chatMessage = newMessages[1];

      let newMessageList = [...messageList, ...newMessages];

      // 继续会话
      if (payload?.type === "continue" && messageList.length) {
        chatMessage = messageList.at(
          -1
        ) as NCustomModel.IMessageListItem
        newMessageList = [...messageList];
        requestConfig.isContinue = true;
        chatMessage.isDone = false;
      }

      yield put({
        type: "setData",
        payload: {
          messageList: newMessageList,
        },
      });

      yield put({ type: "setData", payload: {inputValue:""} });

      const serviceUrl = lbChatUserInfo?.loginToken
        ? process.env.Chat_Custom_Service_Url
        : process.env.Chat_No_Service_Url;
      const httpUrl = `${API_HOST}${serviceUrl}`;

      let isIncode = 0;
      let links: string[],
        superAdditionList: Record<string, any>,
        useInternet: number;

      eventSource(httpUrl, {
        method: "post",
        body: JSON.stringify({
          ...requestConfig,
          modelRecordId,
          messages:
            payload?.type === "continue"
              ? newMessageList
              : newMessageList.slice(0, -1),
        }),
        headers: {
          token: getLoginToken(),
          "Content-Type": "application/json",
          accept: "text/event-stream",
        },
        onmessage(e, originData) {
          superAdditionList = originData?.superAdditionList;
          if (chatMessage.isDone) return;

          let jsonData;
          links = originData?.links;

          useInternet = originData?.useInternet;

          if (e.event == "continue") {
            // 未写完的判断
            getDvaStore().dispatch({
              type: "customChatMessage/setData",
              payload: {hasContinue: true},
            });
          }

          try {
            jsonData = JSON.parse(e.data);
          } catch (e) {}

          if (Object.prototype.toString.call(jsonData) === "[object Object]") {
            console.log(jsonData, "jsonData");
            if (jsonData.code === -2004) {
              chatMessage.messageCategory = "sensitive";
              chatMessage.sensitiveType = "input";
            }
            if (jsonData.code === -20004) {
              chatMessage.messageCategory = "sensitive";
              chatMessage.sensitiveType = "output";
            }

            if (
              notBindFileCode.includes(jsonData.code?.toString()) &&
              chatMessage.fileType
            ) {
              // 敏感词不会绑定上传文件信息 清空fileDataId
              getDvaStore().dispatch({
                type: "customChatMessage/setData",
                payload: {
                  customChatGptConfig: {
                    ...getDav().customChatMessage.customChatGptConfig,
                    fileDataId: "",
                  },
                },
              });
            }
            if (jsonData.code == -20010) {
              getDvaStore().dispatch({
                type: "userParameter/setSqueezeInfo",
                payload: { ...jsonData.data, show: true },
              });
              return;
            }
            if (errorCode.includes(jsonData?.code?.toString?.())) {
              chatMessage.errCode = jsonData?.code?.toString?.();
              chatMessage.content = jsonData?.msg;
              // 刷新视图
              getDvaStore().dispatch({
                type: "customChatMessage/setData",
                payload: {},
              });
            }
            return;
          }

          // 字符串流
          if (e.data.startsWith("``")) {
            isIncode++;
          }
          let str = e.data;

          chatMessage.content = chatMessage.content?.replace(cursorEl, "");

          if (isIncode % 2 === 0) {
            str = str + cursorEl;
          }
          chatMessage.content += str;
          // 刷新视图
          getDvaStore().dispatch({
            type: "customChatMessage/setData",
            payload: {},
          });
        },
        onclose(type?: string) {
          console.log(superAdditionList, "superAdditionList");

          chatMessage.superAdditionList = superAdditionList;
          if (chatMessage.isDone) return;
          chatMessage.isDone = true;
          chatMessage.content = chatMessage.content?.replace(cursorEl, "");
          getDvaStore().dispatch({
            type: "customChatMessage/setData",
            payload: { chatLoading: false },
          });
          getDvaStore().dispatch({
            type: "customChatMessage/getUserFreeTimes",
          });

          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
          updataLeftSideHistory();
          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END

          getDvaStore().dispatch({
            type: "leftSideX/refreshConversationCount",
          });

          const notFetchIdsCode = errorCode.filter(
            (n: string) => !["-2004", "-20004"].includes(n)
          );

          if (!getDav().customChatMessage.customChatGptConfig.isTest && !notFetchIdsCode.includes(chatMessage.errCode)) {
            // 非测试数据 需要更新流式数据id
            getDvaStore().dispatch({
              type: 'customChatMessage/getLastMessageId',
              payload: {lastMessages: newMessages}
            })
          }
        },
        onerror(errMsg, errInfo) {
          if (chatMessage.isDone) return;
          console.log(errInfo, "errInfo");
          chatMessage.isDone = true;
          chatMessage.isError = true;
          chatMessage.canRegenerate = true;
          if (errInfo) {
            chatMessage.errCode = errInfo?.code?.toString?.();
          }
          chatMessage.content = `<font color='red'>${errMsg}</font>`;

          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用
          updataLeftSideHistory();
          // 更新左侧历史记录，防止响应慢时，左侧历史记录不更新，只有新对话才调用  END

          getDvaStore().dispatch({
            type: "customChatMessage/setData",
            payload: { chatLoading: false },
          });
          getDvaStore().dispatch({ type: "customChatMessage/stop" });
          if (errInfo) {
            throw Error(errInfo);
          }
        },
        tryTimes: 0,
        timeout: 1000 * 60 * 3,
        signal: cancelToken.signal,
      });

      //
      yield put({
        type: "setData",
        payload: {
          customChatGptConfig: {
            ...requestConfig,
            isContinue: undefined,
          },
        },
      });
    },
    // 发起新会话 重置stream参数 拉取sessionId
    *newChat(
      { payload }: {payload: {modelRecordId: string, isTest?: boolean}},
      { call, put, select }: EffectsCommandMap
    ): Generator {
      if (!payload.modelRecordId) return;
      try {

        const res: any = yield (payload.isTest ? call(getCustomTestSessionId) : call(getCustomSessionId, {modelRecordId: payload.modelRecordId}));
  
        const { customChatGptConfig } = (yield select(
          (state: RootState) => state.customChatMessage
        )) as CustomChatMessage;
  
        if (res.code === 0) {
          yield put({
            type: "setData",
            payload: {
              chatLoading: false,
              hasContinue: false,
              messageList: [],
              loadingHistory: false,
            },
          });
  
          const requestConfig: ChatGptConfig = {
            ...customChatGptConfig,
            isContinue: false,
            promptId: customChatGptConfig.promptId || 0,
            sessionId: res.data.sessionId,
            isTest: payload.isTest
          };
  
          yield put({
            type: "setData",
            payload: { customChatGptConfig: requestConfig, messageList: [] },
          });
        }
        if ([-2006, -2007].includes(res.code)) {
          commonModalConfirm({
            mainTitle: "温馨提示",
            content: res.msg,
            sureButtonText: '购买会员'
          }).then(() => {
            showVipPayPop()
          })
        }
        return res;
      } catch (error: any) {
      }
    },
    *getLastMessageId(
      { payload }: {payload: {lastMessages: NCustomModel.IMessageListItem[]}},
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const {lastMessages} = payload;

      const { sessionInfo } = (yield select(
        (state: RootState) => state.customChatMessage
      )) as CustomChatMessage;
      if (!sessionInfo) return
      const res: any = yield call(getCustomLastMsg, {sessionId: sessionInfo.id })

      lastMessages.forEach((item,index) => {
        item.createTime = res.data[index]?.createTime
        item.id = res.data[index]?.id
        item.messageId = res.data[index]?.messageId
        item.sessionId = res.data[index]?.sessionId
      })

      yield put({
        type: "setData",
        payload: {},
      });
    }
  },
};
