export enum PayStatusEnum {
  // start: 开始支付, fail: 支付失败, invite: 邀请支付, group: 拼团支付
  START = "start",
  FAIL = "fail",
  INVITE = "invite",
  GROUP = "group",
}

export type PayTabType = { name: string; id: string };

// 移动端支付参数类型
export type MobilePayParamsType = {
  businessId: string;
  payWay?: string;
  payMethod?: string;
  groupConfigId?: string;
  businessType?: string;
};

export enum PayUserStateEnum {
  ACTIVING = 1, // 生效
  NOPAY = 2, // 未购买
  INVALID = 3, // 失效
}

export type PayProRechargeRecordVoType = {
  count: number; // 总数量
  useFastCount: number; //剩余极速出图数量
  useRelaxCount: number; // 剩余普通出图数量
  validEndTime: string; // 截止日期
};

export type PayUserDataType = {
  benefitMonths: number; // 送几个月
  newUser: boolean; // 是否新用户
  status: PayUserStateEnum; // vip状态
  statusDesc: string; // vip状态描述
  validTime: string; // vip有效期
  validTimeLong: number; // vip有效期时间戳
  payProRechargeRecordVos: PayProRechargeRecordVoType[]; // 绘图张数数据
  count: number; // 总数量
  currentPayBenefitConfigVo: currentPayBenefitConfigVoTS;
};

type IRefuelPayTypeMap = "week" | "month" | "quarter" | "year";

export type currentPayBenefitConfigVoTS = {
  payType: IRefuelPayTypeMap;
  canByRefuel: boolean;
};
