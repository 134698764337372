import {Payload, RootState} from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";

export interface IChatOptimizeQuestionStore {
  questionTitle: string
  showChatPopup: boolean
  currentQuestionId: string
  selectedChatRules: string[]
  allChatRuleItems: Array<{ title: string; content: string, id: string }>
}

export default {
  state: {
    questionTitle: '',
    showChatPopup: false,
    currentQuestionId: '',
    selectedChatRules: [],
    allChatRuleItems: []
  } as IChatOptimizeQuestionStore,
  reducers: {
    setQuestionTitle: (state: IChatOptimizeQuestionStore, {payload}: Payload) => {
      return {
        ...state,
        questionTitle: payload || ''
      };
    },
    setShowChatPopup: (state: IChatOptimizeQuestionStore, {payload}: Payload) => {
      return {
        ...state,
        showChatPopup: payload
      };
    },
    setCurrentQuestionId: (state: IChatOptimizeQuestionStore, {payload}: Payload) => {
      return {
        ...state,
        currentQuestionId: payload
      };
    },
    setSelectedChatRules: (state: IChatOptimizeQuestionStore, {payload}: Payload) => {
      return {
        ...state,
        selectedChatRules: payload
      };
    },
    setAllChatRuleItems: (state: IChatOptimizeQuestionStore, {payload}: Payload) => {
      return {
        ...state,
        allChatRuleItems: payload
      };
    }
  },
  effects: {
    *updateCurrentQuestion({ payload } : Payload, { put, select }: EffectsCommandMap) {
      const { title, id } = payload

      const { currentQuestionId } = yield select((state: RootState) => {
        return state.chatOptimizeQuestion
      })

      if (currentQuestionId !== id) {
        yield put({
          type: 'setSelectedChatRules',
          payload: [],
        })
      }

      yield put({
        type: 'setQuestionTitle',
        payload: title,
      })

      yield put({
        type: 'setCurrentQuestionId',
        payload: id,
      })
    },
  },
};
