import { catch_http } from "./request";
import {isDesk} from "@/utils/utils";

/**
 * 获取角色分类tab列表
 */
export interface IRoleSortTab {
  id: string;
  roleName: string;
}

// export const getRoleSorts = () =>
//   catch_http<IRoleSortTab[]>(
//     "get",
//     `/chat-server/api/role-play/role-name/list`
//   );

export const getRoleSorts = (model: string) =>
  catch_http<IRoleSortTab[]>(
    "post",
    "/chat-server/api/role-play/role-name/list/v2",
    { model },
  );

/**
 * 角色名称列表
 */
export const getRoleNameList = (model: string) =>
  catch_http<NRolePlay.IRoleNameList>(
    "post",
    "/chat-server/api/role-play/role-name/list/v3",
    { model },
  );
/**
 * 角色名称列表
 */
export const getRoleNameListV4 = (model: string) =>
  catch_http<NRolePlay.IRoleNameList>(
    "post",
    "/chat-server/api/role-play/role-name/list/v4",
    { model },
  );

/**
 * 获取角色扮演列表
 */
export interface IRoleListParams {
  category?: string;
  id: string;
  current: number;
  size: number;
  word?: string;
}

export interface IRoleListItem {
  id: string;
  roleId: string;
  title: string;
  text: string;
  mood: string;
  rightNow: string;
  useHot: number;
  avatarUri: string;
  avatarUriShow: string;
  collect: boolean;
  trySpeak: string;
  category: number;
  roleShowName: string;
  share: string;
  modelList: Array<string>;
}

export const getRoleList = (params: IRoleListParams) =>
  catch_http<{
    records: IRoleListItem[];
    total: number;
    size: number;
    pages: number;
    current: number;
  }>("post", `/chat-server/api/role-play/page-of-list-${isDesk() ? "v3" : "v2"}`, params);

/**
 * 收藏列表
 */
export const getRoleCollectList = (params: IRoleListParams) =>
  catch_http<{
    records: IRoleListItem[];
    total: number;
    size: number;
    pages: number;
    current: number;
  }>("post", `/chat-server/api/role-play/collect-page`, {
    // size: params.size,
    // 收藏不做分页
    size: 10000,
    current: params.current,
  });

/**
 * 根据角色扮演id创建会话
 */

export interface IRolePlaySessionParams {
  model: string;
  rolePlayId: string;
}

export interface IRolePlaySession {
  id: string;
  title: string;
  userId: string;
  siteId: string;
  deleted: boolean;
  createBy: string;
  updateBy: string;
  createTime: string;
  updateTime: string;
  platform: string;
  gptModel: string;
  rolePlayId: number;
}

export const addNewSession = (params: IRolePlaySessionParams) =>
  catch_http<IRolePlaySession>(
    "post",
    `/chat-server/api/chat/create/by/role-play-id`,
    params,
  );

/**
 * 获取历史列表
 */
export const getRolePlayHistory = () =>
  catch_http<NRolePlay.IRoleHistoryItem[]>(
    "post",
    "/chat-server/api/user/conversation-record/latest-role-play-conversations",
  );
/**
 * 获取历史列表
 */
export const getRolePlayHistoryV2 = (data: { gptModel: string }) =>
  catch_http<NRolePlay.IRoleHistoryItem[]>(
    "post",
    "/chat-server/app/user/conversation-record/latest-role-play-conversations/v2",
    data,
  );

/**
 * 设置收藏
 */
export const setRolePlayCollect = (params: NRolePlay.ICollectParams) =>
  catch_http<{}>("post", "/chat-server/api/role-play/collect", params);

/**
 * 根据id查询角色扮演信息
 */
export const getRolePlayInfoById = (id: string) => {
  return catch_http<IRoleListItem>(
    "post",
    `/chat-server/api/role-play/get-by-id/${id}`,
    {
      id,
    },
  );
};

/**
 * 获取角色扮演相关问题
 */
export const getRolePlayRelatedProblems = (params: {
  content: string;
  gptModel: string;
  rolePlayName: string;
}) => {
  return catch_http<any[]>(
    "post",
    `/chat-server/api/role-play/get-related-problems`,
    params,
  );
};
