/*
 * @Author: 胡璐
 * @Date: 2023-05-25
 * @Description: 公共方法
 */

import { Navigate, getDvaApp } from "@umijs/max";
import { message } from "antd";
import CryptoJS from "crypto-js";
import { RootState, shareDataItem } from "@/types/type";
import { getWebSiteConfig, websiteConfigType } from "@/server/lb-chat";
import { v4 as uuidv4 } from "uuid";
import { DESK_SKIP_HOST } from "@/const";
import { getEncoding } from "js-tiktoken";
import { commonModalConfirm } from "./commonModal";

// 判断是否为支付宝内置浏览器
export const isAliBrower = () => {
  const agent = navigator.userAgent.toLowerCase();
  const ret = agent.match(/Alipay/i);
  if (ret) {
    return true; // 是
  } else {
    return false; // 否
  }
};

// 判断是否是低版本浏览器
export const isLowerBrowserVersion = () => {
  const userAgent = navigator.userAgent;  
  const vendor = navigator.vendor;  
  
  const isSafari = /Safari/.test(userAgent) && /Apple/.test(vendor);  
  
  if (!isSafari) {  
    return false;  
  }  
  
  const versionMatch = userAgent.match(/Version\/(\d+(\.\d+)?)/);  

  if (!versionMatch) {  
    return false;  
  }  
  
  const version = parseFloat(versionMatch[1]);

  return version < 17; 
}

//JS使用正则表达式校验电话号码
export function checkModbile(mobile: string): boolean {
  var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
  var result = re.test(mobile);
  if (!result) {
    return true; //若手机号码格式不正确则返回false
  }
  return false;
}

// 校验密码8位以上字母和数字
export const isPasswordValid = (password: string): boolean => {
  // 使用正则表达式来匹配密码规则
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  return passwordRegex.test(password);
};

// 是否全为数字
export const numberExp = /^\d+$/g;

// 是否全为字母
export const letterExp = /^[A-Za-z]+$/g;

// 是否为符号
export const symbolExp = /^[^a-zA-Z0-9\u4e00-\u9fa5]+$/g;

// 获取地址栏参数
export const getRouterParams = () => {
  // const paramsUrl = window.location.search.replace(/^\?/, "");
  const hash = window.location.hash || window.location.href;
  const paramsUrl = isDesk()
    ? hash.split("?")[1]
    : window.location.search.replace(/^\?/, "");
  if (!paramsUrl) {
    return {};
  }
  const params: Record<string, string> = {};
  paramsUrl.split("&").forEach((item) => {
    const itemArr = item.split("=");
    if (itemArr.length) {
      const [key, value] = itemArr;
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  });
  return params;
};

// 获取url域名
export const getUrlOrigin = () => {
  const urlOrigin = window.location.origin;
  return urlOrigin;
};

// bilibili回跳转地址
export const getBilibiliCleanedUrl = () => {
  // 获取当前页面的完整URL
  const currentURL = window.location.href;
  // 创建一个URL对象来解析URL
  const url = new URL(currentURL);
  // 获取域名
  const domain = url.origin;
  // 获取参数并排除bi_track_id参数
  const params = new URLSearchParams(url.search);
  params.delete("bi_track_id");
  const paramsUrl = params.toString() ? "?" + params.toString() : "";
  // 重新构建URL
  var newURL = domain + paramsUrl;
  return newURL;
};

// bilibili推广参数获取
export const getBiTrackId = () => {
  return localStorage.getItem("bi_track_id") || "";
};

// 清除推广参数
export const setClearExtendArgument = () => {
  localStorage.removeItem("bi_track_id");
  localStorage.removeItem("promoteUser");
};

//复制文本
export const copy = (val: string, tooltip?: boolean) => {
  tooltip = tooltip ?? true;
  return new Promise((resolve, reject) => {
    if (userAgent()) {
      navigator.clipboard.writeText(val);
      resolve(true);
      return;
    }

    const input = document.createElement("textarea");
    input.value = val;
    input.style.position = "absolute";
    input.style.left = "-1000px";
    input.style.zIndex = "-1000";
    document.body.appendChild(input);

    input.select();
    if (document.execCommand("copy")) {
      document.execCommand("copy");
      tooltip && message.success("复制成功");
      resolve(val);
    } else {
      tooltip && reject(new Error("复制失败"));
    }

    input.blur();
    document.body.removeChild(input);
  });
};

//复制保留换行
export const copyOrigin = (val: any) => {
  return new Promise((resolve) => {
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = val;
    textarea.select();
    if (document.execCommand("copy")) {
      message.success("复制成功");
    }
    resolve(val);
    document.body.removeChild(textarea);
  });
};

//多段文本复制
export const multistageCotyText = (val: any) => {
  const textarea = document.createElement("textarea");
  document.body.appendChild(textarea);
  let text = "";
  for (let i in val) {
    if (val[i] && val[i].content) {
      text += val[i].content + "\r\n";
    }
  }
  textarea.value = text;
  textarea.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
    message.success("复制成功");
  }
  document.body.removeChild(textarea);
};

// 判断当前设备
export const userAgent = () => {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent;
  // 判断是否是移动端
  return /Mobile|Android/.test(userAgent);
};

// 判断是否是ios
export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
};

// 判断是否在微信浏览器内
export const isWeChatBrowser = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("micromessenger") !== -1) {
    return true; // 在微信浏览器内
  } else {
    return false; // 不在微信浏览器内
  }
};

// 校验url
export const isValidUrl = (url: string): boolean => {
  // 正则表达式用于校验 URL，这里使用了一个简单的示例
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)?.*$/;
  return urlPattern.test(url);
};

// 推广用户类型
export type PromoteUserType = {
  [key in "keywordid" | "userid" | "type" | TypeKeyEnum]: string;
};

export enum TypeKeyEnum {
  BD_VID = "bd_vid", // 百度
  QHCLICKID = "qhclickid", // 360
  SG_VID = "sg_vid", // 搜狗
}

enum TypeEnum {
  BD_VID = "baidu",
  QHCLICKID = "360",
  SG_VID = "sougou",
}

export const getPromoteUser = (): Partial<PromoteUserType> => {
  const promoteUser = localStorage.getItem("promoteUser");
  if (!promoteUser) return {};
  try {
    const promoteUserJSON: PromoteUserType = JSON.parse(promoteUser);

    const typeMapKey: {
      [key in TypeEnum]: TypeKeyEnum;
    } = {
      [TypeEnum.BD_VID]: TypeKeyEnum.BD_VID,
      [TypeEnum.QHCLICKID]: TypeKeyEnum.QHCLICKID,
      [TypeEnum.SG_VID]: TypeKeyEnum.SG_VID,
    };

    let obj: { [key in string]: string } = {
      keywordid: promoteUserJSON.keywordid || "",
      userid: promoteUserJSON.userid || "",
      source: "PC",
      type: promoteUserJSON.type || "",
    };

    const type: TypeEnum = promoteUserJSON.type as TypeEnum;

    const key: TypeKeyEnum = typeMapKey[type] as TypeKeyEnum;

    obj[typeMapKey[type].replace("_", "-")] = promoteUserJSON[key];

    return obj;
  } catch (e) {
    return {};
  }
};

//时间戳格式化
export const formatTimestamp = (date: Date): string => {
  console.log(date);

  const now = new Date();
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const pad = (num: number): string => String(num).padStart(2, "0");

  const hh = pad(date.getHours());
  const mm = pad(date.getMinutes());
  const ss = pad(date.getSeconds());

  if (isToday) {
    console.log("今天", `今天 ${hh}:${mm}:${ss}`);
    return `今天 ${hh}:${mm}:${ss}`;
  } else {
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    console.log(`${month}:${day} ${hh}:${mm}:${ss}`);
    return `${month}:${day} ${hh}:${mm}:${ss}`;
  }
};

//时间戳格式化字符串
export const formatTimestampString = (dateString: string): string => {
  const date = new Date(dateString);

  const now = new Date();
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const pad = (num: number): string => String(num).padStart(2, "0");

  const hh = pad(date.getHours());
  const mm = pad(date.getMinutes());
  const ss = pad(date.getSeconds());

  if (isToday) {
    return `今天 ${hh}:${mm}:${ss}`;
  } else {
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    return `${month}:${day} ${hh}:${mm}:${ss}`;
  }
};

//图片下载
export const imgDownLoad = (
  src: any,
  fileName?: string,
): Promise<boolean | string> => {
  return new Promise((resolve, reject) => {
    const a = document.createElement("a");

    // 这里是将url转成blob地址，
    fetch(src) // 跨域时会报错
      .then((res) => res.blob())
      .then((blob) => {
        // 将链接地址字符内容转变成blob地址
        a.href = URL.createObjectURL(blob);
        a.download = fileName ?? ""; // 下载文件的名字
        document.body.appendChild(a);
        a.click();
        //在资源下载完成后 清除 占用的缓存资源
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);

        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadImgByBase64 = (base64: string, fileName = "image") => {
  return new Promise((resolve, reject) => {
    const a = document.createElement("a");
    a.href = base64;
    a.download = fileName ?? ""; // 下载文件的名字
    a.click();
    resolve(true);
  });
};

export const replaceWithBr = (text: string) => {
  return text?.replace?.(/\n/g, "<br />") || "";
};

// 流-2002中的点击订阅
const errPayFun = () => {
  getDvaApp()._store.dispatch({ type: "chatPay/setHasPayPop", payload: true });
};
window.errPayFun = errPayFun;

// 流-2007中的

const refuelPayFun = () => {
  getDvaApp()._store.dispatch({
    type: "refuelBagPay/setHasRebuelBagPayPop",
    payload: true,
  });
  getDvaApp()._store.dispatch({
    type: "refuelBagPay/setRebuelBagPayEntrance",
    payload: "chat",
  });
};
window.refuelPayFun = refuelPayFun;

/**
 * 防抖函数
 * @param func 需要防抖的函数
 * @param wait 延迟时间，单位毫秒
 * @returns 返回防抖后的函数
 */
export function debounce(
  func: (...args: any[]) => void,
  wait: number,
): (...args: any[]) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };
}

/**
 * 节流函数
 * @param func 需要节流的函数
 * @param wait 延迟时间，单位毫秒
 * @returns 返回节流后的函数
 */
export function throttle(
  func: (...args: any[]) => void,
  wait: number,
): (...args: any[]) => void {
  let timeout: NodeJS.Timeout | null = null;
  let lastTime: number = 0;
  return (...args: any[]) => {
    const now = Date.now();
    const remaining = wait - (now - lastTime);
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      lastTime = now;
      func(...args);
    } else if (!timeout) {
      timeout = setTimeout(() => {
        lastTime = Date.now();
        timeout = null;
        func(...args);
      }, remaining);
    }
  };
}

/**
 * 轮询某个服务
 * turnFn为准备轮询的fn,方法返回值必须是个promise.
 * frequency 触发重新请求间隔
 *
 * @returns promise
 */
export const turnsRequire = (
  turnFn: Function,
  frequency = 1500,
  handle = (res: any) => {},
) => {
  let maxRequirePics = 500; // 最大请求100次
  let timer: any = null;
  const turnPromise = new Promise((resolve, reject) => {
    const once = () => {
      maxRequirePics--;
      clearTimeout(timer);
      turnFn()
        .then((res: any) => {
          if (maxRequirePics <= 0) {
            // 手动关闭了
          } else if (res.data.status === 3) {
            // 成功了
            resolve(res);
          } else if (
            res.data.status === -1 ||
            res.data.status === 4 ||
            maxRequirePics === 0
          ) {
            reject();
            // 失败了
          } else {
            // 执行中
            timer = setTimeout(once, frequency || 1500);
          }

          handle(res);
        })
        .catch(reject);
    };
    once();
  });
  const stop = () => {
    setTimeout(timer);
    maxRequirePics = 0;
  };
  return {
    turnPromise,
    stop,
  };
};

export const findParentNode = (
  dom: HTMLElement | ParentNode | null,
  filter: (dom: HTMLElement | ParentNode) => boolean,
): HTMLElement | ParentNode | null => {
  if (!dom || dom === document.body) {
    return null;
  }
  if (filter(dom)) {
    return dom;
  } else {
    return findParentNode(dom.parentNode, filter);
  }
};
/*
 * 判断是否是移动端
 * @returns
 */
export const checkIsMobile = (): boolean => {
  if (!window || !window.navigator) return false;

  const ua = navigator.userAgent.toLowerCase();

  return (
    ua.search(
      /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/,
    ) > -1
  );
};

/**
 * 识别华为鸿蒙系统
 * @returns
 */
export const isHuaweiHarmonyOS = function (): boolean {
  if (!window || !window.navigator) return false;

  const ua = navigator.userAgent.toLowerCase();
  return ua.includes("huawei");
};

/**
 * 日期格式化
 * @param date
 * @param format
 * @returns
 */
export const formatDate = (
  date: Date | number | null,
  format = "yyyy-MM-dd",
  // format = "yyyy-MM-dd hh:mm:ss"
): string => {
  if (!date) {
    return "";
  }

  if (typeof date === "number") {
    date = new Date(date);
  }

  const year = date.getFullYear().toString();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  const hours = ("0" + date.getHours()).slice(-2);
  const min = ("0" + date.getMinutes()).slice(-2);
  const sed = ("0" + date.getSeconds()).slice(-2);
  return format
    .replace("yyyy", year)
    .replace("MM", month)
    .replace("dd", day)
    .replace("hh", hours)
    .replace("mm", min)
    .replace("ss", sed);
};

// 文件下载
export const fetchDownload = (url: string, fileName?: string) => {
  return fetch(url).then((res) => {
    res.blob().then((blob) => {
      const fileMap = {
        "application/pdf": "application/pdf",
        "application/zip": "zip",
        "image/jpeg": "jpeg",
        "application/msword": "doc",
        "application/docx": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
      };
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.style.display = "none";
      a.href = blobUrl;
      a.download =
        fileName ||
        `${Date.now()}.${fileMap[blob.type as keyof typeof fileMap]}`;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    });
  });
};

// 获取文件对象的md5
export const getFileMd5 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (data) => {
      var arrayBuffer = (data.target as FileReader).result;
      var wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
      var md5Data = CryptoJS.MD5(wordArray).toString();
      resolve(md5Data);
    };
    fileReader.onerror = (err) => reject(err);
    fileReader.readAsArrayBuffer(file);
  });
};
// 以css media查询依据作为判断移动端的条件
export const checkIsMobileByMediaScreen = () => {
  return document.documentElement.clientWidth < 600;
};

// 以css media查询依据作为判断移动端的条件1300
export const dotuMobileByMediaScreen = () => {
  return document.documentElement.clientWidth < 1300;
};

// 以css media查询依据作为判断移动端的条件 自定义宽度
export const customByMediaScreen = (width: number) => {
  return document.documentElement.clientWidth < width;
};

let websizeConfigInfo: websiteConfigType;

export const getWebSiteConfigInfo = (): Promise<websiteConfigType> => {
  return new Promise((resolve, reject) => {
    if (websizeConfigInfo) {
      resolve(websizeConfigInfo);
      return;
    }

    getWebSiteConfig()
      .then((res) => {
        if (!res || res.code !== 0 || !res.data) return;

        websizeConfigInfo = res.data;

        resolve(websizeConfigInfo);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// 验证码格式校验
export const codeCheckApi = (code: string) => {
  var regex = /^\d+$/;
  return regex.test(code);
};

export const isWin = () => {
  const agent = navigator.userAgent.toLowerCase();
  const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
    return true;
  }
  if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
    return true;
  }
  if (isMac) {
    return false;
  }
};

export const isLinux = () => {
  return navigator.userAgent.toLowerCase().indexOf("linux") > -1;
};

export const isDesk = () => {
  return !!window.require;
};

export const getLocationPath = () => {
  return isDesk() ? location.hash.slice(1).split("?")[0] : location.pathname;
};
export const deskOpen = (url: string, hasDomain?: boolean) => {
  if (window.require) {
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send(
      "openNewBroswer",
      `${hasDomain ? "" : DESK_SKIP_HOST}${url}`,
    );
  }
};
// 轮询
export function polling(fn: () => Promise<boolean>, interval = 500) {
  let timer: NodeJS.Timeout;
  const run = () => {
    fn()
      .then((res) => {
        if (!res) {
          timer = setTimeout(() => {
            run();
          }, interval);
        }
      })
      .catch(() => {
        timer = setTimeout(() => {
          run();
        }, interval);
      });
  };

  run();

  return function stop() {
    clearTimeout(timer);
  };
}

// 倒计时
export function countdown(
  num: number,
  cb?: (num: number) => boolean | void,
  interval = 1000,
) {
  let timer: NodeJS.Timeout;

  timer = setInterval(() => {
    if (num > 0) {
      num--;
      if (cb && cb(num)) {
        stop();
        return;
      }
    }
  }, interval);

  function stop() {
    clearTimeout(timer);
  }

  return stop;
}
const enNum = ["Ⅰ", "Ⅱ", "Ⅲ", "Ⅳ", "Ⅴ", "Ⅵ", "Ⅶ", "Ⅷ", "Ⅸ", "Ⅹ"];
export function numToChinese(num: number, language?: string) {
  if (language?.toLocaleLowerCase?.() === "english") {
    return enNum[num - 1];
  }
  const chineseNum = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
  ];
  const chineseUnit = ["", "十", "百", "千", "万", "亿"];
  let result = "";
  let unitPos = 0;
  let zero = true;
  while (num > 0) {
    const numUnit = num % 10; // 取个位数字
    if (numUnit === 0) {
      // 处理零
      if (!zero) {
        zero = true;
        result = chineseNum[numUnit] + result;
      }
    } else {
      zero = false;
      result = chineseNum[numUnit] + chineseUnit[unitPos] + result;
    }
    unitPos++;
    num = Math.floor(num / 10); // 去除个位数字
  }
  // 处理结果为“一十”时的情况
  if (result.startsWith("一十")) {
    result = result.substring(1);
  }
  return result;
}

/**
 * 新开窗口打开连接
 * @param url
 * @returns
 */
export const openWindow = function (url: string) {
  if (!url) return;

  const windowName = "_blank";

  if (typeof window.open === "function") {
    window.open(url, windowName);
    return;
  }

  var isIE = false || !!document.documentMode;

  if (isIE) {
    window.location.replace(url);
  } else {
    let a: HTMLAnchorElement | null = document.createElement("a");

    a.target = windowName;
    a.href = url;
    a.click();
    a = null;
  }
};

// 新开页面跳转，不被浏览器拦截
export const openWindowByClick = (url: string) => {
  let a: HTMLAnchorElement | null = document.createElement("a");
  a.target = "_blank";
  a.href = url;
  a.click();
  a = null;
};

// 网盘分享提取码
export const createRadomCode = (): string => {
  const characters =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let code = "";

  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }

  return code;
};

// 网盘分享链接拼接
export const getDiskShareLink = (key: string) => {
  return `${location.origin}/netdisk/share/${key}`;
};

export const getDiskCopyShareLink = (key: string, code?: string) => {
  const link = `链接：${location.origin}/netdisk/share/${key}`;
  const codeStr = `\n提取码：${code}`;

  if (code) {
    return link + codeStr;
  }

  return link;
};

// 计算文字数量
const letterReg = /[a-zA-Z\-]/;
const ignoreReg = /[\s,，.。:：;；?？!！’”\(\)\[\]\{\}《》、]/;
const beLetter = (chat: string) => letterReg.test(chat); // 是否是单词
const beIgnoreCalc = (chat: string) => ignoreReg.test(chat); // 是否是不用计算数量的符号
export const calcTextWordsNumber = (text: string) => {
  if (!text) return 0;
  let number = 0;
  let afterLetter = false; // 是否是跟在字母后面
  for (let i = 0, length = text.length; i < length; i++) {
    let char = text[i];
    let isLetter = beLetter(char);
    let isIgnore = beIgnoreCalc(char);
    if (!isIgnore) {
      if (afterLetter) {
        if (!isLetter) {
          number++;
        }
      } else {
        number++;
      }
    }
    afterLetter = isLetter;
  }
  return number;
};

// 正则剔除非图片html
export function filterNonImageStrings(text: string) {
  const regex = /<img.*?src="(.*?)".*?>/g;
  const matches = text.matchAll(regex);
  const images = [];

  for (const match of matches) {
    const imgTag = match[0];
    const src = match[1];
    images.push({ imgTag, src });
  }

  return images;
}

// 等指定的时间之后再执行
export const wait = function (times: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, times);
  });
};

interface IWaitConditionConfig {
  interval: number;
  overtime: number;
  overtimeResult: boolean;
}

// 等待指定的条件符合后再执行
export const waitCondition = function (
  conditionCallback: () => boolean,
  config?: Partial<IWaitConditionConfig>,
): Promise<void> {
  return new Promise((resolve) => {
    if (!conditionCallback) {
      return resolve();
    }

    const now = +new Date();

    const check = function () {
      if (+new Date() - now > (config?.overtime || 10 * 1000)) {
        console.log("waitCondition 超时未符合条件");
        return !!config?.overtimeResult;
      }

      if (conditionCallback()) {
        resolve();
        return;
      }

      setTimeout(check, config?.interval || 50);
    };

    check();
  });
};

//清空绘画配置信息
export const clearPaintConfig = () => {
  getDvaApp()._store.dispatch({
    type: "paintingParameter/setPaintConf",
    payload: null,
  });
  getDvaApp()._store.dispatch({
    type: "paintingParameter/setConfigurationId",
    payload: "",
  });

  getDvaApp()._store.dispatch({
    type: "paintingParameter/setPaintOssKey",
    payload: "",
  });
  getDvaApp()._store.dispatch({
    type: "paintingParameter/setAnimeState",
    payload: -1,
  });
};

// 判断当前浏览器是否为 Chromium 内核，如果是则判断当前Chromium 内核是否大于88
export const isChromiumAndVersionAbove88 = () => {
  let userAgent = navigator.userAgent;
  let isChromium =
    userAgent.includes("Chrome") || userAgent.includes("Chromium");

  if (isChromium) {
    let chromeVersion = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    if (chromeVersion && chromeVersion[2]) {
      return parseInt(chromeVersion[2], 10) > 88;
    }
  }

  return false;
};

export const isEmptyOrNull = (obj: object | null): boolean => {
  if (obj === null) {
    return true;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

// 获取chat输入文本长度
const chatTokenLength = getEncoding("cl100k_base");
export const getChatLength = (text: string) => {
  return chatTokenLength.encode(text.trim()).length;
};

export const getDav = function (): RootState {
  return getDvaApp()?._store.getState();
};

// 限制chat输入文本长度
export const getChatText = (txt: string) => {
  if (
    getChatLength(txt) <
    Number(getDav().site.siteConfig.ai_chat_input_length) - 1
  ) {
    return txt;
  }
  const newArr = chatTokenLength
    .encode(txt.trim())
    .slice(0, Number(getDav().site.siteConfig.ai_chat_input_length));
  return chatTokenLength.decode(newArr);
};

const roundUp = (num: number, decimalPlaces: number) => {
  // 计算进位因子
  var factor = Math.pow(10, decimalPlaces);
  // 先乘以进位因子，再使用Math.ceil进行向上进位，最后除以进位因子
  // 使用toFixed来格式化数字
  return (Math.ceil(num * factor) / factor).toFixed(decimalPlaces);
};

// 计算超出部分的百分比
const calculateExcessPercentage = (current: number, max: number) => {
  // 如果当前字数没有超过最大允许字数，则超出部分为0
  if (current <= max) {
    return 0;
  }
  // 计算超出的字数
  let excess = current - max;
  // 计算超出部分占总字数的百分比
  let percentage = (excess / current) * 100;
  console.log(percentage, "percentage");

  // 返回百分比值
  return roundUp(percentage, 2); // 保留两位小数
};

// 限制chat输入文本长度弹窗提示;
export const getChatTextLengthTip = (
  text: string,
  tokenLengthLimit: string,
) => {
  if (getChatLength(text?.trim()) > Number(tokenLengthLimit)) {
    commonModalConfirm({
      mainTitle: "温馨提示",
      content: `哦豁，输入的内容过长，超出部分占比${
        calculateExcessPercentage(
          getChatLength(text?.trim()),
          Number(tokenLengthLimit),
        ) + "%"
      }，请描述的简洁一些，更利于AI理解`,
    });
    return true;
  }
  return false;
};

// 十六进制颜色转rgb
export const hexToRgb = (hex: string) => {
  // 去掉#号
  hex = hex.replace("#", "");
  // 将hex转换为rgb
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return {
    r: r,
    g: g,
    b: b,
  };
};

// 计算天数
export const countingDays = (time: string) => {
  const createTime = new Date(time);
  const currentTime = new Date();
  const diffTime = Math.abs(currentTime.getTime() - createTime.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
