/**
 *
 * 捕获全局promise异常
 * */
import {IS_PROD} from "@/const";

window.addEventListener("unhandledrejection", (event) => {
  event.preventDefault();

  if (!IS_PROD) {
    console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
  }
});