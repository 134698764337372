import { matchRoutes, getDvaApp } from "@umijs/max";
import { message } from "antd";
import "./app.less";
import "./style/main.less";
import { getLoginToken } from "@/models/userInfo";
import "./app-initialization/index";
import { dispatch } from "./app-initialization/tools";
import PubSub from "pubsub-js";
import { MOBILE_PREFIX, PubSubType } from "@/const";
import { userAgent } from "./utils/utils";

// message 数量限制 最多1条
message.config({
  maxCount: 1,
  top: 50,
});

// 路由变更之后
export function onRouteChange({ location, clientRoutes }: any) {
  const route = matchRoutes(clientRoutes, location.pathname)?.pop()
    ?.route as NRouter.IItem;

  const visitMustLogin = !!(route && route.meta?.visitMustLogin);

  document.body.classList.toggle("visit-must-login", visitMustLogin);

  if (visitMustLogin && !getLoginToken()) {
    dispatch({
      type: "userParameter/setHasLoginPop",
      payload: true,
    });
  }

  // @ts-ignore
  const activePath = route.originPath;

  if (activePath === "/") {
    PubSub.publish(PubSubType.CLOSE_SIDEBAR_HISTORY_LIST);
  }
}
