import {Payload} from "@/types/type";
import {CategoryOption, GptModel, OptionLabel} from "@/pages/copywriting/components/GenerateTemplate/constant";
import {GenerateParams} from "@/server/types/copywriting";

export interface RecordListChild {
  type: 'ALL' | 'ALONE'
  index: number
  content: string | string[]
}

export interface CopywritingProps {
  openLeftSidebar: boolean,
  sessionId: string,
  // 更新左侧
  isRefreshLeftSidebar: boolean,
  // 优化记录列表指针
  optimizationRecordListPointer: number,
  // 优化记录列表
  optimizeRecordList: [],
  // 优化loading弹框
  optimizationLoading: boolean,
  showCustomInputDialogData: {
    //-1:关闭 1:开通会员 2：自定义输入
    type: -1 | 1 | 2,
    title?: string
  },
  // 清除其他下拉框，暂时加在这里，后期有时间使用其他方法监听,校验对应id
  closeOtherSelect: string,
  // 清除其他浮动优化弹框
  closeOtherFloatBtnModal?: number
}

export default {
  state: {
    openLeftSidebar: false,
    sessionId: "",
    isRefreshLeftSidebar: false,
    optimizationLoading: false,
    showCustomInputDialogData: {
      type: -1,
    },
    optimizationRecordListPointer: 0,
  } as CopywritingProps,
  reducers: {
    setOptimizationRecordListPointer: (state: CopywritingProps, {payload}: Payload) => {
      return {
        ...state,
        optimizationRecordListPointer: payload
      };
    },
    setSessionId: (state: CopywritingProps, {payload}: Payload) => {
      return {
        ...state,
        sessionId: payload,
      };
    },
    setOpenLeftSidebar: (state: CopywritingProps, {payload}: Payload) => {
      return {
        ...state,
        openLeftSidebar: payload,
        initialConfig: {
          ...state.initialConfig,
          dynamic: null
        }
      };
    },
    setRefreshLeftSidebar: (state: CopywritingProps, {payload}: Payload) => {
      return {
        ...state,
        isRefreshLeftSidebar: payload
      };
    }
  }
};
