// window.postMessage的事件类型

export enum WindowMessageType {
  // 同步插件的token信息，  ！！！这个值不要变 chrome插件里已经用了这个
  SYNC_TOKEN_FRO_CHROME_PLUGIN = "setLoginStatus",

  // 同步token信息
  ASYNC_TOKEN = "LB_CHAT_ASYNC_TOKEN",

  // 用户的身份信息有变更时 (如头像更换)  （如无及时更新信息的要求 那么监听端可以无视这个事件）
  ASYNC_USERINFO_CHANGE = "LB_CHAT_USERINFO_CHANGE",

  // iframe contact页内 关闭按钮被点击
  CONTACT_CLICK_POPUP_CLOSE = "CONTACT_CLICK_POPUP_CLOSE",

  // iframe contact页内 学术会员支付成功
  CONTACT_ACADEMIC_VIP_PAY_SUCCESS = "CONTACT_ACADEMIC_VIP_PAY_SUCCESS",

  // iframe contact页内 chat会员支付成功
  CONTACT_CHAT_VIP_PAY_SUCCESS = "CONTACT_CHAT_VIP_PAY_SUCCESS",

  // iframe contact页内 加油包支付成功
  CONTACT_REFUEL_PAY_SUCCESS = "CONTACT_REFUEL_PAY_SUCCESS",

  // iframe contact页内 清理token信息
  CONTACT_CLEAR_TOKEN_INFO = "CONTACT_CLEAR_TOKEN_INFO",

  // iframe contact页内  标识iframe内事件已就绪，可以接收事件了
  CONTACT_IAM_READY = "CONTACT_IAM_READY",
}
