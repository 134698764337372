/*
 * 学术vip
 * */
import {Payload} from "@/types/type";
import {EffectsCommandMap} from "@umijs/max";
import {requestVipDailyBenefit} from "@/server/vip";

const getDefault = function (): NVip.IDailyBenefit {
  return {
    detailVoList: [],

    vipTypeVoList: [],

    hasSVip: false,
    paintExpireTimeLong: '',

    "paintCount": 0,
    "paintFastCount": 0,
    "paintRelaxCount": 0,
    buttonList: [],
  }
}

export default {
  state: {
    // 每日剩余权益
    vipDailyBenefit: getDefault(),
    // 显示领取VIP弹窗
    isReceiveVipModalOpen: false,

  } as NVip.IModelState,

  reducers: {
    setIsReceiveVipModalOpen: (state: any, { payload }: Payload) => {
      return {
        ...state,
        isReceiveVipModalOpen: payload,
      };
    },

    setVipDailyBenefit: (state: any, {payload}: Payload) => {
      return {
        ...state,
        vipDailyBenefit: payload,
      };
    },

  },

  effects: {

    * updateVipDailyBenefit(
      {payload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      const {isSuccess, data} = (yield call(
        requestVipDailyBenefit
      )) as NCommon.IHttpRes<NVip.IDailyBenefit>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setVipDailyBenefit",
        payload: data,
      })

      return data
    },

  },
};
