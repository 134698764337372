import {Payload} from "@/types/type";
import {EffectsCommandMap} from "@umijs/max";
import {requestSiteInfoConfig} from "@/server/site";
import {customerInfo} from "@/server/lb-chat";

const CACHE_KEY = 'LB_SITE_CONFIG'


const getDefaultCustomer = (): NSite.ICustomerInfo => {
  return {
    faqQQAccount: '',
    faqQQImage: '',
    faqQQLink: '',
    feedbackEmail: '',
    foreignSiteDomain: '',
    siteDomain: ''
  }
}


const getFromCache = function (): Record<string, string> {
  const data = localStorage.getItem(CACHE_KEY)

  if (!data) {
    return {}
  }

  try {
    return JSON.parse(data)
  } catch (e) {
  }

  return {}
}


export default {
  state: {
    siteConfig: getFromCache(),
    customerInfo: getDefaultCustomer()
  } as NSite.IModelState,

  reducers: {

    setSiteConfig: (state: NSite.IModelState, {payload}: Payload) => {
      return {
        ...state,
        siteConfig: payload,
      };
    },

    setCustomerInfo: (state: NSite.IModelState, {payload}: Payload) => {
      return {
        ...state,
        customerInfo: payload,
      };
    },

  },

  effects: {

    * requestAndSetConfig(
      {payload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      const {isSuccess, data} = (yield call(
        requestSiteInfoConfig
      )) as NCommon.IHttpRes<NSite.ISiteInfoItem[]>;

      if (!isSuccess) {
        return;
      }

      const config: Record<string, string> = {}

      data.forEach(item => {
        config[item.attributeCode] = item.attributeValue
      })

      const biyingUetId = config["biying_uet_id"]

      localStorage.setItem(CACHE_KEY, JSON.stringify(config))

      if(biyingUetId){
        window.initBingStatistics && window.initBingStatistics(biyingUetId)
      }

      yield put({
        type: "setSiteConfig",
        payload: config,
      });

      // <link rel="shortcut icon" href="/favicon.ico">
      if (config['site_favicon']) {
        document.querySelector('#lbFavicon')?.remove()

        const $link = document.createElement('link')
        $link.setAttribute('id', 'lbFavicon')
        $link.setAttribute('rel', 'shortcut icon')
        $link.setAttribute('href', config['site_favicon'])
        document.head.appendChild($link)

        document.title = config['site_name'];
      }
    },

    * requestAndSetCustomerInfo(
      {payload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      const res = (yield call(
        customerInfo
      )) as NCommon.IHttpRes<NSite.ICustomerInfo>;

      if (res.code !== 0) {
        return;
      }

      res.data.faqQQLink = res.data.faqQQLink || ''

      yield put({
        type: "setCustomerInfo",
        payload: res.data,
      });
    },

  },
};
