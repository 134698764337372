import http, { catch_http } from "./request"
import {isDesk} from "@/utils/utils";

interface IUploadPartsInitParams {
  fileSize: number
  fileTitle: string
  md5: string
  parent: string
  suffix: string
}

// 初始化分片上传
export const uploadPartsInit = (params: IUploadPartsInitParams) => {
  return catch_http<NUpload.IApiUploadPartsInit>('post', `/chat-server/api/disk/file/upload-init${isDesk() ? '/v2' : ''}`, params)
}

interface IGetPartUploadUrlParams {
  fileId: string,
  uploadId: string,
  // 分片序号，从1开始计数
  partNumber: number
}

// 获取分片上传地址
export const getPartUploadUrl = (params: IGetPartUploadUrlParams) => {
  return catch_http<NUpload.IApiPartUploadUrl>('post', `/chat-server/api/disk/file/upload-part${isDesk() ? '/v2' : ''}`, params)
}

interface IUploadPartsMerge {
  // 文件件id，如果在跟目录，传0
  parent: string,

  fileId: string,
  fileSize: number,
  fileTitle: string,
  md5: string,
  suffix: string,
  uploadId: string,
  uploadPartitionNum: number
  fileSourceType?: number
}

// 合并分片 最终上传
export const uploadPartsMerge = (dto: IUploadPartsMerge) => {
  return catch_http<NUpload.IApiUploadPartsInit>('post', `/chat-server/api/disk/file/upload`, dto)
}

// 上传单文件，获取上传地址
export const getSingleUploadFileUrl = (fileMd5: string, fileSuffix: string) => {
  return catch_http<NUpload.IApiUploadUrl>('get', `/chat-server/api/file/upload-url?md5=${fileMd5}&suffix=${fileSuffix}`)
}

// 分片上传，取消上传
export const uploadPartsCancel = function (fileId: string, uploadId: string){
  return http<NUpload.IApiUploadPartsInit>('get', `/chat-server/api/disk/file/upload-cancel`, {
    fileId,
    uploadId
  })
}
