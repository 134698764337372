import { Axios, CancelToken } from "axios";
import http, {catch_http, httpPlus} from "./request";
import {isDesk} from "@/utils/utils";

export interface ResponseData<T> {
  code: number;
  data: T;
  msg: string;
  time: number;
}
/**
 * 获取文档上传签名
 * @param fileSuffix 文件后缀
 * @returns promise
 */
export const requestUploadSign = (fileSuffix: string) =>
  http("post", `/chat-server/api/read/read-book/upload/sign${isDesk() ? '/v2' : ''}`, {
    fileSuffix: fileSuffix,
  });

/**
 * 上传文件信息
 */
export const requestFileDataUpload = (params: {
  fileId: string;
  fileSize?: number;
  fileType?: string;
  md5?: string;
  name?: string;
}) => http("post", `/chat-server/api/read/read-book/upload`, params);

/**
 * 获取文件解析状态
 * @param id 文件id
 * @returns promise
 */
export const requestFileState = (id: string) =>
  http<LbChatReading.requestFileStateRes>(
    "get",
    `/chat-server/api/read/read-book/get-file-state/${id}`
  );

/**
 * 文件名作者更新
 */
export const requestReadBookUpdate = (
  params: LbChatReading.requestReadBookUpdateParams
) => http("post", "/chat-server/api/read/read-book/update", params);

/**
 * 脑图框架
 */
export const requestMindFramework = (fileDataId: number) =>
  http("post", "/chat-server/api/read/mind/framework", {
    fileDataId,
  });

/**
 * 脑图内容
 */
export const requestMind = (fileDataId: number) =>
  http("post", "/chat-server/api/read/mind/mind", {
    fileDataId,
  });

/**
 * 重新生成
 */
export const requestRegeneration = (fileDataId: number) =>
  http("post", "/chat-server/api/read/mind/regeneration", {
    fileDataId,
  });

/**
 * 获取文件列表
 * @returns promise
 */
export const requestReadBookList = () =>
  http<LbChatReading.requestReadBookListRes[]>(
    "get",
    `/chat-server/api/read/read-book/list`
  );

/**
 * 删除
 */
export const deleteBook = (id: string) =>
  http("post", `/chat-server/api/read/read-book/delete/`, { id });

/**
 * 重命名
 */
export const readBookRename = ({ id, name }: { id: string; name: string }) =>
  http("post", `/chat-server/api/read/read-book/rename`, { id, name });

/**
 * 获取文件详情
 */
export const readBookDetail = (id: string) =>
  http("post", `/chat-server/api/read/read-book/detail`, { id });

/**
 * 公开创作
 */
export const requestOpenDisplay = (id: string) => {
  return httpPlus<void>("post", `/chat-server/api/read/read-book/add-display`, { targetId: id });
}

/**
 * 取消公开创作
 */
export const requestCloseDisplay = (id: string) => {
  return httpPlus<void>("get", `/chat-server/api/read/read-book/delete-display/${id}`);
}

