import { Payload, RootState } from "@/types/type";
import { getDvaApp } from "@@/exports";
import { EffectsCommandMap } from "@umijs/max";
import { WindowMessageType } from "@/const/windowMessageType";
import { payUserInfo } from "@/server/lb-chat-pay";
import { requestUserInfo } from "@/server/lb-login-auth";
import { STORAGE_TOKEN_KEY } from "@/const";

// 缓存用户信息
const STORAGE_USERINFO_KEY = "lbChatUserInfo";

// 内存里缓存一份 token
let localToken: string = "";

const getTokenFromStorage = function (): string {
  return localStorage.getItem(STORAGE_TOKEN_KEY) || "";
};

const saveTokenToStorage = function (token: string) {
  localToken = token || "";

  if (token) {
    if (token !== getTokenFromStorage()) {
      localStorage.setItem(STORAGE_TOKEN_KEY, token);
    }
  } else {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(STORAGE_USERINFO_KEY);
  }
};

const getUserInfoFromStorage = function (): NUser.IUserInfo | null {
  if (!getTokenFromStorage()) {
    localStorage.removeItem(STORAGE_USERINFO_KEY);
    return null;
  }

  const value = localStorage.getItem(STORAGE_USERINFO_KEY);

  if (!value) {
    return null;
  }

  let userInfo: NUser.IUserInfo | null = null;

  try {
    userInfo = JSON.parse(value);
  } catch (e) {
    //
  }

  return userInfo;
};

const saveUserInfoToStorage = function (userInfo: NUser.IUserInfo | null) {
  if (!userInfo) {
    localStorage.removeItem(STORAGE_USERINFO_KEY);
    return;
  }

  const userInfoStr = JSON.stringify(userInfo);

  if (userInfoStr !== localStorage.getItem(STORAGE_USERINFO_KEY)) {
    localStorage.setItem(STORAGE_USERINFO_KEY, userInfoStr);
  }
};

// 把登录信息同步给其它端、父级iframe、chrome插件等
const syncLoginInfoToOtherAgents = function () {
  const token = getLoginToken();

  // 给chrome插件同步
  window.postMessage(
    JSON.stringify({
      type: WindowMessageType.SYNC_TOKEN_FRO_CHROME_PLUGIN,
      data: token,
    }),
    "*",
  );

  // 同步给其它域
  window.parent.postMessage(
    JSON.stringify({ type: WindowMessageType.ASYNC_TOKEN, data: { token } }),
    "*",
  );
};

const dispatch = function (params: { type: string; payload?: unknown }) {
  getDvaApp()?._store?.dispatch(params);
};

// 登录信息有变更时
const onUserInfoChange = function (
  newUserInfo: NUser.IUserInfo | null,
  oldUserInfo: NUser.IUserInfo | null,
) {
  const newHasLogin = !!newUserInfo;
  const oldHasLogin = !!oldUserInfo;

  // 同步给其它域
  window.parent.postMessage(
    JSON.stringify({ type: WindowMessageType.ASYNC_USERINFO_CHANGE, data: {} }),
    "*",
  );
};

// login变更时
const onTokenChange = function (newToken: string, oldToken: string) {
  if (newToken === oldToken) {
    return;
  }

  const newHasLogin = !!newToken;
  const oldHasLogin = !!oldToken;

  // 同步token给其它域
  syncLoginInfoToOtherAgents();

  // 从登录态转成未登录
  if (oldHasLogin && !newHasLogin) {
    // 重置收藏数量
    dispatch({
      type: "chatCollect/setCollectNum",
      payload: 0,
    });
  }

  // 从未登录转成登录
  if (!oldHasLogin && newHasLogin) {
    // 还是需要刷新，因为有太多组价没有去监听登录态的变化, 另外如果处于iframe通信页内 则不刷新
    if (!location.search.match(/contact=/)) {
      setTimeout(() => location.reload(), 0);
    }
  }
};

export const getLoginToken = function (): string {
  return localToken;
};

localToken = getTokenFromStorage();
syncLoginInfoToOtherAgents();

// 同步其他tab页的登录态
window.addEventListener("storage", (e) => {
  // 当storage直接被清理时，e.key为空
  if (e.key && e.key !== STORAGE_TOKEN_KEY) {
    return;
  }

  const newToken = e.key ? e.newValue || "" : getTokenFromStorage();

  // 还有null的情况
  if (newToken !== localToken) {
    dispatch({
      type: "userInfo/updateToken",
      payload: newToken,
    });
  }
});

export default {
  state: {
    lbChatUserInfo: getUserInfoFromStorage(),
    token: localToken,
  } as NUser.IUserInfoState,

  reducers: {
    setLbChatUserInfo: (state: any, { payload }: Payload) => {
      return {
        ...state,
        lbChatUserInfo: payload,
      };
    },

    setToken: (state: any, { payload }: Payload) => {
      return {
        ...state,
        token: payload || "",
      };
    },
  },

  effects: {
    // 请求更新用户数据，
    *refreshUserInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      if (!getLoginToken()) {
        yield put({
          type: "setLbChatUserInfo",
          payload: null,
        });

        saveUserInfoToStorage(null);

        return;
      }

      let oldValue = (yield select(
        (state: RootState) => state.userInfo.lbChatUserInfo,
      )) as NUser.IUserInfo | null;

      if (oldValue) {
        oldValue = { ...oldValue };
      }

      // 用户基础信息
      const userRes = (yield call(
        requestUserInfo,
      )) as NCommon.IHttpRes<NUser.IUserInfo>;
      // 用户chat会员信息
      const payUserInfoRes = (yield call(payUserInfo)) as NCommon.IHttpRes<any>;

      if (!userRes.isSuccess || payUserInfoRes.code !== 0) {
        return;
      }

      // if(userRes?.data?.newUser && window?.bingRegisterEvent){
      //   window.bingRegisterEvent()
      // }

      const newUser: NUser.IUserInfo = {
        ...userRes.data!,
        isVip: payUserInfoRes.data.valid,
        vipEndTime: payUserInfoRes.data.validTime,
        yearMemeber: payUserInfoRes.data.yearMemeber,
      };

      saveUserInfoToStorage(newUser);

      yield put({
        type: "setLbChatUserInfo",
        payload: newUser,
      });

      if (JSON.stringify(newUser) !== JSON.stringify(oldValue)) {
        onUserInfoChange(newUser, oldValue);
      }
    },

    *updateToken(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      let oldToken = (yield select(
        (state: RootState) => state.userInfo.token,
      )) as string;

      const newToken = (payload || "") as string;

      saveTokenToStorage(newToken);

      if (oldToken !== newToken) {
        yield put({
          type: "setToken",
          payload: newToken,
        });

        yield put({
          type: "refreshUserInfo",
        });

        onTokenChange(newToken, oldToken);
      }
    },

    *updateTokenAndUserInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { lbChatUserInfo, token } = (yield select(
        (state: RootState) => state.userInfo,
      )) as NUser.IUserInfoState;

      const newUserInfo = payload as NUser.IUserInfoWithToken;

      if (!newUserInfo || !newUserInfo.loginToken) {
        return;
      }

      const newToken = newUserInfo.loginToken;

      saveTokenToStorage(newToken);

      if (token !== newToken) {
        yield put({
          type: "setToken",
          payload: newToken,
        });

        onTokenChange(newToken, token);
      }

      yield put({
        type: "setLbChatUserInfo",
        payload: newUserInfo,
      });

      if (JSON.stringify(newUserInfo) !== JSON.stringify(lbChatUserInfo)) {
        saveUserInfoToStorage(newUserInfo);
        onUserInfoChange(newUserInfo, lbChatUserInfo);
      }
    },
  },
};
