import { Payload } from "@/types/type";

export interface IWholeSpin {
  wholeSpinTip: string;
  wholeSpinDelay: number;
  wholeSpinning: boolean;
}

export default {
  state: {
    wholeSpinTip: "",
    wholeSpinDelay: 1000,
    wholeSpinning: false,
  } as IWholeSpin,
  reducers: {
    setWholeSpin: (state: IWholeSpin, { payload }: Payload) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
