import { Payload } from "@/types/type";

export interface AcademicQAState {
  showTextarea: boolean;
  contentLoading: boolean;
  referenceInfo: any;
  model: string;
  modelInfo: {
    isVip: boolean;
    timeGpt3: number;
    timeGpt4: number;
    isInit?: true;
  };
  searchText: string;
}

export default {
  state: {
    showTextarea: true,
    contentLoading: false,
    referenceInfo: {},
    model: window.defaultModel?.name || "gpt-4o-mini",
    modelInfo: {
      isVip: false,
      timeGpt3: 0,
      timeGpt4: 0,
      isInit: true,
    },
    searchText: "",
  } as AcademicQAState,
  reducers: {
    setShowTextarea: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        showTextarea: payload,
      };
    },
    setContentLoading: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        contentLoading: payload,
      };
    },
    setReferenceInfo: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        referenceInfo: payload,
      };
    },
    setModel: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        model: payload,
      };
    },
    setModelInfo: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        modelInfo: payload,
      };
    },
    setSearchText: (state: AcademicQAState, { payload }: Payload) => {
      return {
        ...state,
        searchText: payload,
      };
    },
  },
  effects: {},
};
