import {dispatch} from "@/app-initialization/tools";

export const commonModalConfirm = function (config: Partial<NCommon.ICommonModalModelState> | string): Promise<NCommon.TCommonModalModelSubmitType> {
  return new Promise((resolve, reject) => {
    const onSubmit = function (type: NCommon.TCommonModalModelSubmitType) {
      if (type === 'sure') {
        resolve(type)
      } else {
        reject(type)
      }
    }

    if (typeof config === 'string') {
      config = {
        content: config
      }
    }

    config.onClick = (type) => onSubmit(type)

    dispatch({
      type: 'commonModal/create',
      payload: config
    })
  })
}