import { httpPlus, catch_http } from "./request";

// 获取用户学术vip信息
export const requestAcademicVipUserInfo = () => {
  return httpPlus<NVipPay.IUserVipPayInfo>(
    "get",
    `/chat-academic/api/academic-vip/user-vip-info`
  );
};

//用户学术当前VIP信息 用于一些只判断是否会员的业务 // NAcademicVip.IVipInfo
export const requestUserNowVipInfo = () =>
  catch_http("get", "/chat-academic/api/academic-vip/user-now-vip-info");

//用户学术当前VIP剩余信息，获取当前VIP所有权益额度剩余
export const requestUserNowVipBenefit = () =>
  catch_http(
    "get",
    "/chat-academic/api/academic-vip/user-now-vip-benefit-remain"
  );


//用户权益剩余信息
// benefitCode: academic_search:学术搜索 academic_download:下载文献 document_help:求助全文 help_read:AI帮读文献 speed_read:文献速读 deep_read:深度导读 ai_question:文献-AI问答 academic_translate:全文翻译 translate_download:翻译结果下载 net_disk_capacity:网盘容量 knowledge_ai_question:知识库-AI问答 gpt_3.5:GPT3.5 gpt_4:GPT4 claude:CLAUDE;
// businessType: academic_search:学术搜索 academic_help_read:AI帮读 academic_translate:全文翻译 academic_net_disk:网盘 academic_knowledge:知识库;
export const academicVipbenefitInfo = (params: object) =>
  catch_http("post", "/chat-academic/api/academic-vip/benefit-info", params);

// 用户权益校验;
export const academicVipValidBenefitInfo = (params: object) =>
  catch_http(
    "post",
    "/chat-academic/api/academic-vip/valid-benefit-info",
    params
  );
