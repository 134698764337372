import { logout } from "@/server/lb-login-auth";
import { Payload } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import { getCollectNum } from "@/server/lb-chat-collect";

export interface ChatCollect {
  hasShowCollect: boolean;
  collectNum: number;
  collectTabType:
    | "conversation"
    | "painting"
    | "aiReading"
    | "customConversation"
    | "tableImage";
  collectItem: any;
}

export default {
  state: {
    //是否显示收藏夹
    hasShowCollect: false,
    //个数
    collectNum: 0,
    collectTabType: "conversation",
    collectItem: {},
  } as ChatCollect,
  reducers: {
    setHasShowCollect: (state: ChatCollect, { payload }: Payload) => {
      return {
        ...state,
        hasShowCollect: payload,
      };
    },
    setCollectNum: (state: ChatCollect, { payload }: Payload) => {
      return {
        ...state,
        collectNum: payload,
      };
    },
    setCollectTabType: (state: ChatCollect, { payload }: Payload) => {
      return {
        ...state,
        collectTabType: payload,
      };
    },
    setCollectItem: (state: ChatCollect, { payload }: Payload) => {
      return {
        ...state,
        collectItem: payload,
      };
    },
  },
  effects: {
    // 获取收藏总数
    *getCollectNumDva(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      try {
        const res: any = yield call(getCollectNum, payload);
        if (res?.code == 0) {
          yield put({
            type: "setCollectNum",
            payload: res.data.totalNum,
          });
        }
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },
  },
};
