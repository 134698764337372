// 生产一个跳往学术pdf的链接
import {ACADEMIC_VP_HOST, STORAGE_TOKEN_KEY} from "@/const";

interface IGenerateAcademicVpUrlConfig {
  noToken: boolean
}

// 是否是学术vp链接
export const isAcademicVpUrl = function (link: string) {
  return /\/academic-vp/.test(link)
}

export const generateAcademicVpUrl = function (pathUrl: string, config?: IGenerateAcademicVpUrlConfig) {
  pathUrl.replace("/academic-vp", "").replace(/^\/+/, "");

  return `${ACADEMIC_VP_HOST}/academic-vp${pathUrl}`

  // let [urlP1, hashPath] = pathUrl.split("#");
  // let [realPath, searchPath] = urlP1.split("?");
  //
  // const token = localStorage.getItem(STORAGE_TOKEN_KEY) || ''
  //
  // realPath = realPath.replace("/academic-vp", "").replace(/^\/+/, "");
  // searchPath = (searchPath || '') + (searchPath ? "&" : "") + `chatHost=${encodeURIComponent(location.origin)}`;
  //
  // if (token && config?.noToken !== true) {
  //   searchPath += `&token=${encodeURIComponent(token)}`
  // }
  //
  // hashPath = hashPath ? "#" + hashPath : "";
  //
  // return (
  //   ACADEMIC_VP_HOST + `/academic-vp/${realPath}?${searchPath}${hashPath}`
  // );
};
