/**
 *
 * 获取用户信息
 * */
import {dispatch, onStoreReady} from "./tools";
import {getLoginToken} from "@/models/userInfo";

onStoreReady(() => {
  // 请求更新用户信息
  dispatch({type: 'userInfo/refreshUserInfo'})

  // 初始化请求设置模型列表
  dispatch({type: 'chatParameter/initSetModelList'})
  // 初始化站点配置
  dispatch({type: 'site/requestAndSetConfig'})
  dispatch({type: 'site/requestAndSetCustomerInfo'})

  // 朋友圈信息
  if (getLoginToken()) {
    dispatch({type: 'chatModalControl/updateShareDetail'})
    dispatch({type: 'vip/updateVipDailyBenefit'})
  }
})