import {
  getPartUploadUrl,
  uploadPartsCancel,
  uploadPartsInit,
  uploadPartsMerge
} from "@/server/upload";
import {getFileMd5, getFileName, getFileSuffix, getUID} from "@/utils/file";


interface IUploadFileToServerParams {
  file: File | Blob
  url: string
  method: string
  contentType?: string | boolean
  headers?: Record<string, string>
  onProgress?: (e: ProgressEvent<XMLHttpRequestEventTarget>) => void
  onComplete?: () => void
  onError?: (err: Error) => void
}

// 将文件上传到指定的服务器
export const uploadFileToServer = function (params: IUploadFileToServerParams) {
  const xhr = new XMLHttpRequest()

  xhr.open(params.method.toUpperCase(), params.url, true)

  if (params.contentType === false) {
    xhr.setRequestHeader('Content-Type', '')
  }

  if (params.headers) {
    Object.keys(params.headers).forEach((k) => {
      xhr.setRequestHeader(k, params.headers![k])
    })
  }

  // 监听 progress 事件
  if (params.onProgress) {
    // (e.loaded / e.total)
    xhr.upload.addEventListener('progress', function (e) {
      params.onProgress && params.onProgress(e)
    })
  }

  xhr.onload = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        params.onComplete && params.onComplete()
      } else {
        params.onError && params.onError(new Error(xhr.response.message))
      }
    }
  }

  xhr.onerror = function () {
    params.onError && params.onError(new Error(xhr.response.message))
  }

  xhr.send(params.file)

  return xhr
}

export const isFinishedStatus = function (status: string) {
  return ['success', 'error'].includes(status)
}

// 分片上传文件
export const uploadFileByParts = function (params: NUpload.IUploadFileByPartsParams) {
  const file = params.file
  const fileName = getFileName(file)
  const fileSuffix = getFileSuffix(file)
  let hasMerge = false
  // 本次上传分片数量
  let chunkCount = 0
  // 本次上传成功分片数量
  let chunkUploadSuccessCount = 0

  // 取消上传
  const cancel = function () {
    mission.status = 'error'
    mission.reason = '取消上传'

    // 发起取消请求，无视服务器结果
    if (mission.fileId && mission.uploadId && mission.uploadServiceUrl) {
      uploadPartsCancel(mission.fileId, mission.uploadId).then(null, null)
    }
  }

  const mission: NUpload.IUploadPartsMission = {
    uid: params.uid || getUID('upload'),
    name: fileName,
    size: params.file.size,
    suffix: fileSuffix,
    uploadSize: 0,
    progress: 0,
    fileId: '',
    uploadId: '',
    md5: '',
    status: 'waiting',
    reason: '',
    cancel,
    uploadServiceUrl: '',
    ossKey: ''
  }

  // window.xx.push(mission)

  const setUploadSize = function (size: number) {
    if (isFinishedStatus(mission.status)) {
      return
    }

    mission.uploadSize = size
    mission.progress = Math.min(99, Math.round((mission.uploadSize * 100) / mission.size))

    params.onProgress && params.onProgress(mission.progress)
    // console.log('mission.uploadSize', mission.progress, mission.uid, mission.status)
  }

  const setStatus = function (status: NUpload.TStatus, reason?: any) {
    if (mission.status === status || isFinishedStatus(mission.status)) {
      return
    }

    mission.status = status

    if (reason) {
      mission.reason = reason
    }

    params.onStatusChange && params.onStatusChange(status)

    if (isFinishedStatus(mission.status) && params.onComplete) {
      params.onComplete(mission.status, reason)
    }
  }

  // 发起最终合并上传
  const uploadMerge = function () {
    if (hasMerge || isFinishedStatus(mission.status)) {
      return
    }

    hasMerge = true

    uploadPartsMerge({
      parent: params.folderId || '0',
      fileId: mission.fileId,
      fileSize: mission.size,
      fileTitle: mission.name,
      md5: mission.md5,
      suffix: fileSuffix,
      uploadId: mission.uploadId,
      uploadPartitionNum: chunkCount,
      fileSourceType: params?.fileSourceType
    }).then((res) => {
      if (!res || res.code !== 0) {
        if (res.code === -9001) {
          return setStatus('error', res)
        }
        setStatus('error', res.msg || '上传失败(-3)')
      } else {
        mission.fileId = mission.fileId || res.data.id
        mission.progress = 100
        setStatus('success', res)
      }
    })
  }

  // 上传分片
  const uploadChunk = function (chunk: Blob, chunkIndex: number) {
    // 获取分片地址
    getPartUploadUrl({
      fileId: mission.fileId,
      partNumber: chunkIndex + 1,
      uploadId: mission.uploadId
    }).then((res) => {
      if (isFinishedStatus(mission.status)) {
        return
      }

      if (!res.data || !res.data.url) {
        setStatus('error', res.msg || '上传失败(-2)')
        return
      }

      mission.uploadServiceUrl = res.data.url

      let uploadSize = 0

      uploadFileToServer({
        file: chunk,
        method: res.data.method,
        url: res.data.url,
        onProgress(e) {
          setUploadSize(mission.uploadSize + e.loaded - uploadSize)
          uploadSize = e.loaded
        },
        onComplete() {
          chunkUploadSuccessCount += 1

          if (chunkUploadSuccessCount === chunkCount) {
            // 还是延迟一下再合并 加个保险，感觉后端数据同步有点慢。
            setTimeout(uploadMerge, 200)
          }
        }
      })
    })
  }

  // 将文件分成多片， 最多5片
  const splitChunks = function () {
    const fileSize = file.size
    let chunkSize = params.chunkSize * 1024 * 1024
    chunkCount = Math.ceil(fileSize / chunkSize)
    const MAX_CHUNK_COUNT = 20

    if (chunkCount > MAX_CHUNK_COUNT) {
      chunkCount = MAX_CHUNK_COUNT
      chunkSize = Math.ceil(fileSize / chunkCount)
    }

    for (let i = 0; i < chunkCount; i++) {
      const start = i * chunkSize
      const end = Math.min(fileSize, start + chunkSize)
      const chunk = file.slice(start, end)

      uploadChunk(chunk, i)
    }
  }

  // 取文件md5，然后取获取上传基础信息，接着分片上传
  getFileMd5(params.file)
    .then((fileMd5) => {
      mission.md5 = fileMd5

      return uploadPartsInit({
        fileSize: file.size,
        fileTitle: fileName,
        md5: fileMd5,
        parent: params.folderId || '0',
        suffix: fileSuffix
      })
    })
    .then((uploadPartsInitRes) => {
      if (uploadPartsInitRes.code !== 0 || !uploadPartsInitRes.data) {
        setStatus('error', uploadPartsInitRes.msg || '上传失败(-1)')
        return
      }

      if (isFinishedStatus(mission.status)) {
        return
      }

      mission.fileId = uploadPartsInitRes.data.fileId
      mission.uploadId = uploadPartsInitRes.data.uploadId

      // 如果这个文件服务器中存在
      if (uploadPartsInitRes.data.repeat) {
        uploadMerge()
        return
      }

      splitChunks()
    })

  Promise.resolve().then(() => setStatus('uploading'))

  return mission
}
