/**
 *
 * 执行 登录成功之后 预设的命令
 * */
import {dispatch} from "./tools";
import {LoginSuccessCommand, STORAGE_LOGIN_SUCCESS_COMMAND_AFTER, STORAGE_TOKEN_KEY} from "@/const";

const loginSuccessActionKey = sessionStorage.getItem(STORAGE_LOGIN_SUCCESS_COMMAND_AFTER)

if (loginSuccessActionKey) {
  sessionStorage.removeItem(STORAGE_LOGIN_SUCCESS_COMMAND_AFTER)
}

if (loginSuccessActionKey && localStorage.getItem(STORAGE_TOKEN_KEY)) {
  switch (loginSuccessActionKey) {
    case LoginSuccessCommand.openBuyGroupVipPop: {
      dispatch({type: 'groupVip/set', payload: {showGroupVipBuyPop: true}})

      break
    }

    default:
      break
  }
}