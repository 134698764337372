import { Payload } from "@/types/type";
import { EPrefixMenuTypes } from "@/pages/newHome/components/chatInput/components/chatInputPrefix/chatInputPrefixMenu/constants";

export interface IChatInput {
  currentInputMenuType: EPrefixMenuTypes | null;
  hasTour: boolean;
  tourTxt: string;
  tourPlace:'top'|'bottom'
}

export default {
  state: {
    currentInputMenuType: null,
    // 是否出现输入框引导
    hasTour: false,
    // 引导文案
    tourTxt: "",
    // 文件/图片解读 是否出现输入框引导
    hasFileTour: false,
    // 引导文案
    fileTourTxt: "",
    tourPlace:'bottom'

  } as IChatInput,
  reducers: {
    setCurrentInputMenuType: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        currentInputMenuType: payload,
      };
    },

    setHasTour: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        hasTour: payload,
      };
    },

    setTourTxt: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        tourTxt: payload,
      };
    },

    setHasFileTour: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        hasFileTour: payload,
      };
    },

    setFileTourTxt: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        fileTourTxt: payload,
      };
    },
    setTourPlace: (state: IChatInput, { payload }: Payload) => {
      return {
        ...state,
        tourPlace: payload,
      };
    },
  },
};
