import { catch_http } from './request'

export interface IResponsePluginItem {
  id: string
  diagramTypeName: string
  diagramTypeCode: string
  diagramTitle: string
  diagramContent: string
  diagramImgUrl: string
}
// 获取插件列表
export const getChatPluginList = () => catch_http<IResponsePluginItem[]>('get', '/chat-server/api/chat/plugins/list')
