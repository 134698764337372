import { Payload } from "@/types/type";
import { EFunctionMenusTypes } from "@/pages/newHome/components/functionMenus/constants";

export interface IChatFunction {
  currentFunctionMenuType: EFunctionMenusTypes;
  currentFunctionMenuTypeCode: string;
  functionMenus: NNewHome.IHomeFunctionListItem[];
  drawTwicePopEntrance: string;
}

export default {
  state: {
    currentFunctionMenuType: "PopularConversations",
    currentFunctionMenuTypeCode: "hot_chat",
    currentCreateType: "",
    functionMenus: [],
    // 绘图二次弹窗入口，判断用
    drawTwicePopEntrance: "",
  } as IChatFunction,
  reducers: {
    setFunctionMenus: (state: IChatFunction, { payload }: Payload) => {
      return {
        ...state,
        functionMenus: payload,
      };
    },
    setCurrentCreateType: (state: IChatFunction, { payload }: Payload) => {
      return {
        ...state,
        currentCreateType: payload,
      };
    },
    setCurrentFunctionMenuType: (
      state: IChatFunction,
      { payload }: Payload
    ) => {
      return {
        ...state,
        currentFunctionMenuType: payload,
      };
    },
    setCurrentFunctionMenuTypeCode: (
      state: IChatFunction,
      { payload }: Payload
    ) => {
      return {
        ...state,
        currentFunctionMenuTypeCode: payload,
      };
    },

    setDrawTwicePopEntrance: (state: IChatFunction, { payload }: Payload) => {
      return {
        ...state,
        drawTwicePopEntrance: payload,
      };
    },
  },
};
