import { catch_http } from "./request";

// 卡券码信息
export const getCouponCodeInfo = (code: string) =>
  catch_http("get", "/chat-server/api/coupon/dis-coupon-info/" + code);

// 领取优惠券
export const getCoupon = (params: { code: string }) =>
  catch_http("post", "/chat-server/api/coupon/receive", params, null, false);

export type CouponListParams = {
  size: number;
  current: number;
  couponUseState: string;
  payType?: "month" | "quarter" | "year"; //套餐类型
  vipType?: "vip" | "svip" | "upgrade"; //支付会员种类
};
// 用户卡券列表可用、已过期
export const getUserCouponList = (params: CouponListParams) =>
  catch_http("post", "/chat-server/api/coupon/list", params);

// 用户卡券列表-已使用
export const getUsedCouponList = (params: CouponListParams) =>
  catch_http("post", "/chat-server/api/coupon/list-expire", params);

// 用户卡券数量
export const getUserCouponCount = () =>
  catch_http("post", "/chat-server/api/coupon/user-coupon-count");

// 查询卡券可抵扣金额
export const getCouponPrice = (params: {
  businessType: string;
  couponId: string;
  originalPrice: 0;
  targetType: "vip_svip";
}) =>
  catch_http("post", "/chat-server/payment/get-coupon-deduct-money", params);
