import type {MessageInstance} from "antd/es/message/interface";

let messageApi: MessageInstance | null = null

export const setMessageApi = function (api: MessageInstance) {
  messageApi = api
}

export const getMessageApi = function (): MessageInstance | null {
  return messageApi
}


