import { Payload } from "@/types/type";
import { type ScholarSearchParamsSchema } from "@/server/scholar-search";

export interface AiScholar {
  scholarSearchParams: Partial<ScholarSearchParamsSchema>;
  isLoadOverview: boolean;
  tracingIndex: number | undefined;
}

export default {
  state: {
    scholarSearchParams: {},
    isLoadOverview: false,
    tracingIndex: undefined,
  } as AiScholar,
  reducers: {
    setScholarSearchParams: (state: AiScholar, { payload }: Payload) => {
      return {
        ...state,
        scholarSearchParams: payload,
      };
    },
    setIsLoadOverview: (state: AiScholar, { payload }: Payload) => {
      return {
        ...state,
        isLoadOverview: payload,
      };
    },
    setTracingIndex: (state: AiScholar, { payload }: Payload) => {
      return {
        ...state,
        tracingIndex: payload,
      };
    },
  },
};
