import {catch_http, httpPlus} from "./request";

// dalle绘图历史列表
export const dalleHistoryList = (data: object) =>
  catch_http(
    "post",
    "/chat-server/api/paint/dalle/conversation/page-of-list",
    data
  );

// dalle绘图历史列表删除（已弃用）
export const dalleHistoryDel = (id: string) =>
  catch_http("post", "/chat-server/api/paint/dalle/conversation/delete/" + id);

// dalle绘图历史列表批量删除
export const dalleHistoryBatchDel = (data: string[]) =>
  catch_http("post", "/chat-server/api/paint/dalle/conversation/delete-batch", {
    ids: data
  });

// dalle删除单条消息
export const dalleHistorySingleDelete = (id: string) =>
  catch_http("get", "/chat-server/api/paint/dalle/conversation/delete-message/" + id);

  // dalle记录置顶
export const pinDalleHistory = (id: string) =>
  catch_http("post", "/chat-server/api/paint/dalle/conversation/pinned/", {id});
  // dalle记录取消置顶
export const cancelPinDalleHistory = (id: string) =>
  catch_http("post", "/chat-server/api/paint/dalle/conversation/cancel-pinned", {id});

// dalle绘图历史列表修改
export const dalleHistoryUpdate = (id: string, params: object) =>
  catch_http(
    "post",
    "/chat-server/api/paint/dalle/conversation/update/" + id,
    params
  );

// dalle出图配置列表
export const dalleConversationConfigList = (category: string) =>
  catch_http(
    "get",
    "/chat-server/api/paint/dalle3/get-dictonary-List?category=" + category
  );

// dalle获取出图配置
export const dalleConversationConfig = (params: object) =>
  catch_http(
    "post",
    "/chat-server/api/paint/dalle3/get-conversation-config",
    params
  );

// dalle保存出图配置
export const dalleSaveConversationConfig = (params: object) =>
  catch_http(
    "post",
    "/chat-server/api/paint/dalle3/save-conversation-config",
    params
  );

// dalle绘图发送
export const dalleMessageSend = (params: object) =>
  catch_http("post", "/chat-server/api/paint/dalle3/user-message-send", params);

// dalle绘图列表历史消息
export const dalleLineHistoryData = (params: object) =>
  catch_http("post", "/chat-server/api/paint/dalle3/list-of-history", params);

// dalle绘图查询任务
export const dalleTaskQuery = (params: object) =>
  catch_http("post", "/chat-server/api/paint/dalle3/task-query", params);

// 实时查询当前绘画的最新消息Id
export const dalleLatestMsg = (messageId: string) =>
  catch_http("get", "/chat-server/api/paint/dalle3/latest-msg/" + messageId);

// 创作公开
export const requestOpenCreationDisplay = (messageId: string, questionId: string, prompt: string) => {
  return httpPlus<void>("post", "/chat-server/api/collect/paint-creation-display/add-dalle", {
    aiMessageId: messageId,
    targetId: questionId,
    prompt
  })
}

// 取消创作公开
export const requestCloseCreationDisplay = (questionId: string) => {
  return httpPlus<void>("get", `/chat-server/api/collect/paint-creation-display/delete/${questionId}`)
}

