import {EffectsCommandMap} from "@umijs/max";
import {Payload} from "@/types/type";
import {ChatParameter} from "@/models/chatParameter";


export interface ChatShare {
  chatShareItem?: object;

}

export default {
  state: {
    // 是否点击了分享，并将点击的分享数据存入
    chatShareItem: {},

  } as ChatShare,
  reducers: {
    setChatShareItem: (state: ChatShare, {payload}: Payload) => {
      return {
        ...state,
        chatShareItem: payload
      };
    }

  },
  effects: {}

};
