import { Payload } from "@/types/type";
import {
  requestCustomModelCount,
  requestCustomModelTypeList,
} from "@/server/customModel";
import { EffectsCommandMap } from "@umijs/max";

export default {
  state: {
    // 是否选中定制模型
    hasCustomModel: false,
    // 是否能禁用发布
    hasCustomModelPublish: true,
    // 首页模型分类
    customModelTypeList: [],
    // 当前选中模型ID
    activeCustomModelId: "101",
    // 搜索文本
    searchKeyWord: "",
    // 我的模型数量
    count: {
      limitCount: 0,
      useCount: 0,
    },
    // 历史对话选中的对象
    customModelChatItem: {}
  } as NCustomModel.ICustomModelState,
  reducers: {
    setHasCustomModel: (
      state: NCustomModel.ICustomModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        hasCustomModel: payload,
      };
    },

    setHasCustomModelPublish: (
      state: NCustomModel.ICustomModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        hasCustomModelPublish: payload,
      };
    },

    setCustomModelTypeList: (
      state: NCustomModel.ICustomModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        customModelTypeList: payload,
      };
    },

    setActiveCustomModelId: (
      state: NCustomModel.ICustomModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        activeCustomModelId: payload,
      };
    },

    setSearchKeyWord: (
      state: NCustomModel.ICustomModelState,
      { payload }: Payload,
    ) => { 
      return {
        ...state,
        searchKeyWord: payload,
      };
    },

    setCount: (state: NCustomModel.ICustomModelState, { payload }: Payload) => {
      return {
        ...state,
        count: payload,
      };
    },

    setCustomModelChatItem: (state: NCustomModel.ICustomModelState, { payload }: Payload) => {
      return {
        ...state,
        customModelChatItem: payload,
      };
    },

    

  },
  effects: {
    *getCustomModelTypeList(
      { payload }: Payload,
      { call, put }: EffectsCommandMap,
    ): Generator {
      const res: any = yield call(requestCustomModelTypeList);

      if (res?.code == 0 && res?.data) {
        yield put({
          type: "setCustomModelTypeList",
          payload: [...res.data],
        });
      }
    },

    *updateCount(
      { payload }: Payload,
      { call, put }: EffectsCommandMap,
    ): Generator {
      const res: any = yield call(requestCustomModelCount);

      if (res?.code == 0 && res?.data?.limitCount) {
        yield put({
          type: "setCount",
          payload: { ...res.data },
        });
      }
    },
  },
};
