/**
 * 网络请求配置
 */
import { getBiTrackId, getPromoteUser, isDesk, isWin } from "@/utils/utils";
import { getDvaApp, history } from "@umijs/max";
import { message } from "antd";
import axios, { Method } from "axios";
import qs from "qs";
import { ResponseData } from "@/types/type";
import { API_HOST, IS_DESK, IS_DEV } from "@/const";
import { getLoginToken } from "@/models/userInfo";
import useBubbleTips from "@/hooks/useBubbleTips";
import checkDomainAvailability from "./checkDomainAvailability";
import { obj2Params, showVipPayPop } from "@/utils";
import { boolean } from "mathjs";

const { showBubbleTips } = useBubbleTips();

//请求超时时间
axios.defaults.timeout = 90000;
axios.defaults.baseURL = API_HOST;

// 客户端增加检测域名可用性
if (IS_DESK) {
  checkDomainAvailability();
}

export type ResponseType<T> = {
  code: number;
  msg: string;
  data: T;
  type?: string;
};

export type PageDataType<T> = {
  records: T[];
  total: number;
  pages: number;
};

export enum PromiseStrategyEnum {
  CATCH_STRATEGY = 0, // 除了0状态码以外，都走catch
  SUCCESSCODE_STRATEGY = 1, // 除了response error，其他都走then
}

// 状态码拦截
// 5003
/**
 * -2009 会话或绘图已被删除
 * -2010 无权操作
 *
 * -6711,-6712,-6713 网盘分享已删除、过期、取消
 * -2022,-2013,-2014 数据分析
 * -9001,-9002,-9003 学术vip权益不足
 */
let successCode = [
  0, -2001, -1001, -2002, -2003, -2008, -2009, -2010, -3101, -6711, -6712,
  -6713, -9001, -9002, -9003, -9004, -2022, -2013, -2014, -20010,
];

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config: any) => {
    if (isDesk()) {
      // 桌面端将/chat-server/api/ 改成/chat-server/desk/
      config.url = config.url.replace(
        "/chat-server/api/",
        "/chat-server/desk/",
      );
    }

    // config.baseURL = "https://test-chat.checkmore.net/";

    //修改查看config值，请勿随意修改！
    const contentType = config["Content-Type"];
    if (
      "application/x-www-form-urlencoded;charset=utf-8".includes(contentType)
    ) {
      config.data = qs.stringify(config.data);
    }

    (config.headers = getPromoteUser()),
      (config.headers = {
        "Content-Type": contentType,
        token: getLoginToken(),
        "bi-track-id": getBiTrackId(),
        ...config.headers,
      });

    // 桌面端设置headers
    if (isDesk()) {
      config.headers = {
        ...config.headers,
        platForm: isWin() ? "windows" : "mac",
        version: localStorage.getItem("deskVersion"),
      };
    }

    if (IS_DEV && /192/.test(API_HOST)) {
      config.headers.lbSiteKey = "L1h6Jc";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const xueshuTipHandle = (response: any) => {
  let code = response.code;
  let data = response.data;
  let msg = response.msg;
  if (code == -9001 || code == -9002 || code == -9003) {
    // 学术会员权益不足提示
    let visible = true;
    let content = "";
    let tipsDesc = "";
    let subTxt = "";
    if (code === -9001 || code === -9002) {
      content = data?.exceptionDescription || msg;
      tipsDesc = "";
      subTxt = "立即开通学术VIP";
    }

    if (
      code === -9003 &&
      data?.nowVipType == "year" &&
      [
        "net_disk_capacity",
        "academic_chat",
        "academic_chat_file",
        "academic_translate",
      ].includes(data?.benefitCode)
    ) {
      showBubbleTips({
        content: data?.exceptionDescription || msg,
      });
      return Promise.reject(data as any);
    }
    if (code === -9003) {
      content = data?.exceptionDescription || msg;
      tipsDesc = data?.yearBenefitMsg;
      subTxt = data?.nowVipType == "year" ? "" : "立即升级";
    }

    getDvaApp()._store.dispatch({
      type: "academicSearch/setHasVipTipsVisible",
      payload: visible,
    });
    getDvaApp()._store.dispatch({
      type: "academicSearch/setVipTipsContent",
      payload: content,
    });
    getDvaApp()._store.dispatch({
      type: "academicSearch/setVipTipsDesc",
      payload: tipsDesc,
    });
    getDvaApp()._store.dispatch({
      type: "academicSearch/setVipSubTxt",
      payload: subTxt,
    });
  }
};

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    const { promisePolicy, errorTip }: any = response.config;
    let code = response.data.code;
    let data = response.data.data;
    let msg = response.data.msg;

    xueshuTipHandle(response.data);

    if (code === -20010 && data) {
      getDvaApp()._store.dispatch({
        type: "userParameter/setSqueezeInfo",
        payload: {
          show: true,
          vipType: response.data.data.vipType,
          username: response.data.data.username,
          loginTime: response.data.data.loginTime,
          ip: response.data.data.ip,
          ipLocation: response.data.data.ipLocation,
        },
      });
    }
    if (code == -2008) {
      getDvaApp()._store.dispatch({
        type: "userParameter/setSignOutInfo",
        payload: data,
      });
      if (data.logoffStatus == 1) {
        getDvaApp()._store.dispatch({
          type: "userParameter/setHasSignOut",
          payload: {
            show: true,
            progress: 2,
          },
        });
      }
      if (data.logoffStatus == 2) {
        getDvaApp()._store.dispatch({
          type: "userParameter/setHasSignOut",
          payload: {
            show: true,
            progress: 3,
          },
        });
      }
      if (data.logoffStatus == 1 || data.logoffStatus == 2) {
        getDvaApp()._store.dispatch({
          type: "userParameter/setHasResetPassword",
          payload: false,
        });
        getDvaApp()._store.dispatch({
          type: "userParameter/setHasLoginPop",
          payload: false,
        });
        getDvaApp()._store.dispatch({
          type: "chatPay/setHasPayPop",
          payload: false,
        });
        getDvaApp()._store.dispatch({
          type: "chatPay/setHasCustomerPop",
          payload: false,
        });

        getDvaApp()._store.dispatch({
          type: "userInfo/updateToken",
          payload: "",
        });
      }
    } else {
      getDvaApp()._store.dispatch({
        type: "userParameter/setSignOutInfo",
        payload: {},
      });
    }

    if (!successCode.includes(code) && errorTip)
      msag(response.data, false, errorTip);
    if (promisePolicy === PromiseStrategyEnum.CATCH_STRATEGY) {
      return Promise.resolve(response.data as any);
    } else {
      if (code === -20010) return Promise.reject(response.data as any);
      if (!successCode.includes(code))
        return Promise.reject(response.data as any);
      else {
        return Promise.resolve(response.data as any);
      }
    }
  },
  (error) => {
    const { errorTip }: any = error?.config || {};
    error.response && msag(error.response, false, errorTip);

    //手动取消请求
    if (error?.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }

    if (!error.config || !error.config.retry) {
      // errorTip && message.error(`请求超时：${error.config.url}`);
      errorTip && message.error(`服务响应超时，请稍后重试`);
    }

    return Promise.reject(error);
  },
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export const get: (
  url: string,
  params?: any,
  headers?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
) => Promise<ResponseType<any> | any> = (
  url,
  params,
  headers = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.CATCH_STRATEGY,
) =>
  axios.get(`${url}${params ? "?" : ""}${qs.stringify(params)}`, {
    ...headers,
    errorTip,
    promisePolicy,
  });

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export const post: (
  url: string,
  data?: any,
  headers?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
  responseType?: any,
) => Promise<ResponseType<any> | any> = (
  url,
  data = {},
  headers = {
    "Content-Type": "application/json",
  },
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.CATCH_STRATEGY,
  responseType,
) =>
  axios.post(url, data, {
    ...headers,
    errorTip,
    promisePolicy,
    responseType,
  });

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export const patch: (
  url: string,
  data?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
) => Promise<ResponseType<any> | any> = (
  url,
  data = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.CATCH_STRATEGY,
) =>
  axios.patch(url, data, {
    errorTip,
    promisePolicy,
  } as any);

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export const put: (
  url: string,
  data?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
) => Promise<ResponseType<any> | any> = (
  url,
  data = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.CATCH_STRATEGY,
) =>
  axios.put(url, data, {
    errorTip,
    promisePolicy,
  } as any);

//统一接口处理，返回数据
const http: <T>(
  fecth: Method | string,
  url: string,
  param?: any,
  headers?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
  responseType?: any,
  timeout?: number,
) => Promise<ResponseType<T>> = (
  fecth,
  url,
  param,
  headers = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.SUCCESSCODE_STRATEGY,
  responseType,
  timeout,
) => {
  fecth = fecth.toLowerCase();
  switch (fecth) {
    case "get":
      return axios({
        method: "get",
        url: `${url}${param ? "?" : ""}${qs.stringify(param)}`,
        ...(headers || {}),
        promisePolicy,
        errorTip,
        responseType,
        timeout: timeout ? timeout : axios.defaults.timeout,
      });
    case "post":
      return axios.post(url, param, {
        "Content-Type": "application/json",
        ...(headers || {}),
        promisePolicy,
        errorTip,
        timeout: timeout ? timeout : axios.defaults.timeout,
      });
    default:
      return axios({
        method: fecth,
        url,
        params: param,
        data: param,
        headers,
        promisePolicy,
        errorTip,
      } as any);
  }
};

//统一接口处理，返回数据
export const httpPlus: <T>(
  fecth: Method | string,
  url: string,
  param?: any,
  headers?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
  responseType?: any,
) => Promise<NCommon.IHttpRes<T>> = (
  fecth,
  url,
  param,
  headers = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.SUCCESSCODE_STRATEGY,
  responseType,
) => {
  return http(
    fecth,
    url,
    param,
    headers,
    errorTip,
    promisePolicy,
    responseType,
  ).then(
    (res) => {
      if (res.code === 0) {
        return Promise.resolve(
          Object.assign(res, {
            isSuccess: true,
          }),
        );
      }

      return Promise.resolve(
        Object.assign(res, {
          isSuccess: false,
        }),
      );
    },
    (res) => {
      return Promise.resolve(
        Object.assign(res, {
          isSuccess: false,
        }),
      );
    },
  );
};

const CachePool: Record<string, any> = {};

const saveToCache = function (key: string, data: any) {
  CachePool[key] = JSON.stringify(data);
};

const getFromCache = function <T>(cacheKey: string): NCommon.IHttpRes<T> {
  return {
    isSuccess: true,
    code: 0,
    msg: "",
    timestamp: 0,
    data: JSON.parse(CachePool[cacheKey]) as T,
  };
};

export const clearRequestCache = function (matchKey: (key: string) => boolean) {
  const keys = Object.keys(CachePool);

  keys.forEach((key) => {
    if (matchKey(key)) {
      delete CachePool[key];
    }
  });
};

export const cacheHttp = async function <T>(
  fecth: string,
  url: string,
  param?: any,
  ...others: any
): Promise<NCommon.IHttpRes<T>> {
  const noCache = param?.noCache;

  if (param && (noCache !== undefined || noCache !== null)) {
    delete param.noCache;
  }

  const cacheKey = url + "?" + obj2Params(param);

  if (CachePool[cacheKey] && !noCache) {
    return getFromCache<T>(cacheKey);
  }

  const res = (await httpPlus(
    fecth,
    url,
    param,
    ...others,
  )) as NCommon.IHttpRes<T>;

  if (res?.code === 0 && cacheKey) {
    saveToCache(cacheKey, res.data);
  }

  return res;
};

export const catch_http: <T>(
  fecth: Method | string,
  url: string,
  param?: any,
  headers?: any,
  errorTip?: boolean,
  promisePolicy?: PromiseStrategyEnum,
) => Promise<ResponseData<any>> = (
  fecth: string,
  url: string,
  param: object | undefined,
  headers = {},
  errorTip = true,
  promisePolicy = PromiseStrategyEnum.CATCH_STRATEGY,
) => http(fecth, url, param, headers, errorTip, promisePolicy);

export default http;

/**
 * 失败提示
 * @param {RequestError | {code: number, msg: string, data: any}} err
 * @param {boolean} isLBEditor 是否编辑器报错
 * @returns
 */
export const msag = (
  err: ResponseType<any> | any,
  isLBEditor: boolean = false,
  errorTip: boolean = true,
) => {
  if (!errorTip) return; // 如果配置errorTipTag为false，则不弹窗提示错误
  const code = err.code || err.status;
  const url = err.config?.url || err.url;

  switch (code) {
    case 400:
      message.error("请求错误");
      break;
    // 站点未配置
    case -401:
      if (!IS_DESK) {
        // window.location.replace(err?.data || "https://www.chatgptok.com");
        getDvaApp()._store.dispatch({
          type: "common/setRequestRedirectDomain",
          payload: err?.data || "https://www.chatgptok.com",
        });
      }
      break;
    case 401:
      //登录过期

      getDvaApp()._store.dispatch({
        type: "userParameter/setHasResetPassword",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "userParameter/setHasLoginPop",
        payload: true,
      });
      getDvaApp()._store.dispatch({
        type: "chatPay/setHasPayPop",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "chatPay/setHasCustomerPop",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "userInfo/updateToken",
        payload: "",
      });
      localStorage.setItem("openBeautifyNum", "0");
      break;
    case 403:
      history.replace("/404");
      break;
    case 404:
      message.error(`请求地址找不到${url ? ":" + url : ""}`);
      break;

    case 405:
      !isLBEditor && message.error(`方法不被访问`);
      break;

    case 408:
      message.error("请求超时");
      break;

    case 500:
      message.error("服务器内部错误");
      break;

    case 501:
      message.error("服务未实现");
      break;

    case 502:
      message.error("网关错误");
      break;

    case 503:
      message.error("服务不可用");
      break;

    case 504:
      message.error("网关超时");
      break;

    case 505:
      message.error("HTTP版本不受支持");
      break;
    case -2003:
      message.warning(err.msg);
      break;
    case -2008:
      message.error(err.msg);
      localStorage.clear();
      break;
    //   权益不够，去支付
    case -2006:
      showVipPayPop();
      break;
    case successCode.find((item) => item === code):
      break;
    default:
      err.msg && message.error(err.msg);
      break;
  }
};

export const checkEventSourceData = (responseData: any) => {
  const { data, code } = responseData;
  xueshuTipHandle(responseData);
  if (code === -20010 && data) {
    getDvaApp()._store.dispatch({
      type: "userParameter/setSqueezeInfo",
      payload: {
        show: true,
        vipType: data.vipType,
        username: data.username,
        loginTime: data.loginTime,
        ip: data.ip,
        ipLocation: data.ipLocation,
      },
    });
  }
  if (code == -2008) {
    getDvaApp()._store.dispatch({
      type: "userParameter/setSignOutInfo",
      payload: data,
    });
    if (data.logoffStatus == 1) {
      getDvaApp()._store.dispatch({
        type: "userParameter/setHasSignOut",
        payload: {
          show: true,
          progress: 2,
        },
      });
    }
    if (data.logoffStatus == 2) {
      getDvaApp()._store.dispatch({
        type: "userParameter/setHasSignOut",
        payload: {
          show: true,
          progress: 3,
        },
      });
    }
    if (data.logoffStatus == 1 || data.logoffStatus == 2) {
      getDvaApp()._store.dispatch({
        type: "userParameter/setHasResetPassword",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "userParameter/setHasLoginPop",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "chatPay/setHasPayPop",
        payload: false,
      });
      getDvaApp()._store.dispatch({
        type: "chatPay/setHasCustomerPop",
        payload: false,
      });

      getDvaApp()._store.dispatch({
        type: "userInfo/updateToken",
        payload: "",
      });
    }
  } else {
    getDvaApp()._store.dispatch({
      type: "userParameter/setSignOutInfo",
      payload: {},
    });
  }
  if (!successCode.includes(code)) msag(responseData);
  if (code === -20010) return false;
  if (!successCode.includes(code)) return false;
  else {
    return true;
  }
};
