import { httpPlus } from "./request";
import { isDesk } from "@/utils/utils";

// 获取每日剩余权益
export const requestVipDailyBenefit = () => {
  return httpPlus<NVip.IDailyBenefit>(
    "get",
    `/chat-server/api/user/pay/vip/daily/interest${isDesk() ? "/v2" : ""}`,
  );
};

// 获取权益列表
export const requestReceiveVipList = () => {
  return httpPlus<NVip.IReceiveVipResponse>(
    "get",
    `/chat-server/api/new-user-benifit/list${isDesk() ? "/v2" : ""}`,
  );
};

// 领取权益
export const requestReceiveVip = (params: object) => {
  return httpPlus<any>(
    "post",
    "/chat-server/api/new-user-benifit/receiveBenefit",
    params,
  );
};
