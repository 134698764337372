import {httpPlus, catch_http} from "./request";

// 获取生产力工具列表
export const requestProductivityList = () => {
  return httpPlus<NLeftSideX.IProductivityItem[]>(
    "post",
    `/chat-server/api/feature/productivity-list`
  );
};

// 获取AI玩法列表
export const requestAIFunctionsList = () => {
  return httpPlus<NLeftSideX.IAIFunctions>(
    "get",
    `/chat-server/api/feature/sidebar`
  )
}

// 获取AI玩法列表
export const requestAIFunctionsListV2 = () => {
  return httpPlus<NLeftSideX.IAIFunctions>(
    "get",
    `/chat-server/api/feature-v2/sidebar`
  )
}

// 获取生产力工具 置顶
export const requestProductivitySetTopping = (id: string) => {
  return httpPlus<void>(
    "post",
    `/chat-server/api/feature/add/productivity`,
    {id}
  );
};

// 获取生产力工具 取消置顶
export const requestProductivityCancelTopping = (id: string) => {
  return httpPlus<void>(
    "post",
    `/chat-server/api/feature/delete/productivity`,
    {id}
  );
};


// 获取对话历史记录数量
export const requestChatConversationCount = () => {
  return httpPlus<number>(
    "post",
    `/chat-server/api/user/conversation-record/chat/count`
  );
};

// 获取绘图历史记录数量
export const requestPaintConversationCount = () => {
  return httpPlus<NLeftSideX.IPaintingCounts>(
    "post",
    `/chat-server/api/paint/conversation/paint/count`
  );
};


// 获取是否有vip，以及对应的过期时间
export const requestVipsExpiredTime = () => {
  return httpPlus<NLeftSideX.IVipsEndTimeItem[]>(
    "get",
    `/chat-server/payment/pay-config/user/pay/list`
  );

}


// 最近使用的菜单
export const requestRecentlyUsed = () => {
  return httpPlus<NLeftSideX.IRecentlyUsedItem[]>(
    "get",
    `/chat-server/api/feature/recent/list`
  );
}

// 清空最近使用的菜单
export const requestClearRecentlyUsed = () => {
  return httpPlus<void>(
    "get",
    `/chat-server/api/feature/clear/recent`
  );
}


// 获取小微论文链接
export const getSmallPaperUrl = () => {
  return httpPlus<{url: string}>("post", "/chat-server/api/dictionary/thesis/url");
}
