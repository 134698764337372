import { isDesk } from "@/utils/utils";
import {cacheHttp, httpPlus} from "./request";
 
// 获取团购vip配置
export const requestGroupVipBuyConfig = () => {
  return cacheHttp<NGroupVip.IBuyConfig>(
    "get",
    `/chat-server/api/group-purchase/config/list${isDesk() ? "/v2" : ""}`
  );
};


// 获取团购vip支付二维码
export const requestGroupVipPayCode = (businessId: string) => {
  return httpPlus<NGroupVip.IPayQrCodeResData>(
    "post",
    `/chat-server/payment/qrcode/v2`,
    {
      businessId,
      businessType: 'Group_Purchase'
    }
  );
};

// 获取团购vip 支付状态
export const requestGroupVipPayStatus = (sign: string) => {
  return httpPlus<NGroupVip.IPayStateResData>(
    "get",
    `/chat-server/payment/state/v2/${sign}`
  );
};


// 团购会员购买记录
export const requestGroupVipPayRecords = () => {
  return httpPlus<NCommon.RecordResponse<NGroupVip.IPayRecordsItem>>(
    "post",
    `/chat-server/api/group-purchase/user/pay-list`,
    {current: 1, size: 999}
  );
};

// 团购会员购买记录
export const requestGroupVipOrderSeatList = (scanPayId: string) => {
  return httpPlus<NCommon.RecordResponse<NGroupVip.IOrderSeatItem>>(
    "post",
    `/chat-server/api/group-purchase/user/list`,
    {current: 1, size: 99999, scanPayId}
  );
};




// 团购会员 根据code 获取会员详情
export const requestGroupVipCodeDetail = (exchangeCode: string) => {
  return httpPlus<NGroupVip.ICodeDetail>(
    "get",
    `/chat-server/api/group-purchase/user/receive-detail`,
    {exchangeCode},
    null,
    false
  );
};

// 团购会员 领取卡
export const requestGroupVipExchange = (exchangeCode: string) => {
  return httpPlus<NGroupVip.IExchangeResult>(
    "post",
    `/chat-server/api/group-purchase/user/exchange`,
    {exchangeCode},
    null,
    false
  );
};

// 团购会员 解除绑定
export const requestUnbind = (exchangeCode: string) => {
  return httpPlus<void>(
    "post",
    `/chat-server/api/group-purchase/user/unbind`,
    {exchangeCode},
    null,
    false
  );
};