import {getDvaApp, history} from "@@/exports";
import {checkConfirmBeforeLeaveChatting ,customModelConfirmBeforeLeaveChatting} from "@/utils/conversation";
import {PubSubType} from "@/const";
import {getLocationPath} from "@/utils/utils";

interface IToVipPayPageParams {
  vipType?: NVipPay.TVipPayPopType | 'any'
  payType?: NVipPay.TPayType | 'any'
  dontPaySuccessBack?: boolean
}

const getNowStoreCurrentType = function () {
  return getDvaApp()?._store.getState()?.chatParameter?.currentType || ''
}

export const obj2Params = function (obj: Record<string, any> | undefined): string {
  const list = []

  if (!obj) {
    return ''
  }

  for (let name in obj) {
    list.push(name + '=' + obj[name])
  }

  return list.join('&')
}

// 所传的链接是否为支付页面
export const isVipPayLink = function (url?: string) {
  return (url || getLocationPath()).includes('/vip-pay')
}

// 打开vip会员支付界面
export const showVipPayPop = function (params?: IToVipPayPageParams) {
  const paraList: string[] = []
  const locationPath = getLocationPath()

  if (locationPath.includes('/vip-pay')) {
    const data: Record<string, string> = {}

    if (params?.vipType) {
      data.theVipPayPopType = params.vipType
    }

    if (params?.payType) {
      data.payType = params.payType
    }

    if (Object.keys(data).length) {
      PubSub.publish(PubSubType.SWITCH_PAY_TYPE, data)
    }

    return;
  }

  if (!params) {
    params = {}
  }

  if (!params.vipType) {
    if (/netdisk|search/i.test(locationPath)) {
      params.vipType = 'academicVip'
    } else if (/painting/i.test(locationPath)) {
      params.vipType = 'chatSVip'
    } else if (getNowStoreCurrentType() === 'painting' || getNowStoreCurrentType() === 'paintDalle') {
      params!.vipType = 'chatSVip'
    }
  }

  if (params?.vipType) {
    let vipType = params.vipType

    if (vipType === 'any') {
      vipType = 'chatSVip'
    }

    if (["chatVip", "chatSVip", "academicVip"].includes(vipType)) {
      paraList.push('vipType=' + vipType)
    }
  }

  if (params?.payType) {
    let payType = params.payType

    if (payType === 'any') {
      payType = 'year'
    }

    if (["month", "quarter", "year"].includes(payType)) {
      paraList.push('payType=' + payType)
    }
  }

  checkConfirmBeforeLeaveChatting().then(() => {
    // 存上临时的值，当支付成功后要跳回原页面
    if (params?.dontPaySuccessBack !== true) {
      sessionStorage.setItem('lbPaySuccessNeedBack', '1')
    }

    history.push(`/vip-pay${paraList.length ? '?' : ''}${paraList.join('&')}`)
  })
}

// 跳链接。 在跳之前会检查当前是否有对话进行中
// subClick  绘画历史中tab切换专用，防止点击tab时，先切换了再弹出弹窗提示
export const navigateTo = function (path: string, config?: { replace: boolean }, subClick?: () => void) {
  let checkBeforeLeave = location.pathname === '/custom-model-chat' ? customModelConfirmBeforeLeaveChatting : checkConfirmBeforeLeaveChatting
  checkBeforeLeave().then(() => {
    subClick && subClick();
    if (config?.replace) {
      history.replace(path)
    } else {
      history.push(path)
    }
  })
}



