import { catch_http, httpPlus } from "./request";

// 添加收藏
export const addCollect = (
  data: LbChatCollect.addCollectParams,
): Promise<LbChatCollect.addCollectResponseData> => {
  return catch_http("post", "/chat-server/api/conversation/collect/add", data);
};

// 会话收藏列表
export const collectList = (
  data: LbChatCollect.collectListParams,
): Promise<LbChatCollect.collectListResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/page-list",
    data,
  );
};
// 自定义模型收藏
export const customModelcollectList = (
  data: LbChatCollect.collectListParams,
): Promise<LbChatCollect.collectListResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/custom-collect/page-list",
    data,
  );
};
// 根据收藏ID---取消收藏
export const cancelCollectByCollectId = (
  id: string,
): Promise<LbChatCollect.cancelCollectByCollectIdResponse> => {
  return catch_http(
    "post",
    `/chat-server/api/conversation/collect/cancel/${id}`,
  );
};

// 会话收藏详情信息
export const getConversationListServer = (
  data: LbChatCollect.conversationListParams,
): Promise<LbChatCollect.conversationResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/detail-data-info",
    data,
  );
};
// 会话定制模型收藏详情信息
export const getCustomConversationListServer = (
  data: LbChatCollect.conversationListParams,
): Promise<LbChatCollect.conversationResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/custom/detail-data-info",
    data,
  );
};

// 搜索会话收藏列表
export const searchCollectList = (
  data: LbChatCollect.searchCollectListParams,
): Promise<LbChatCollect.searchCollectListResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/search",
    data,
  );
};

// 单条消息取消收藏
export const cancelCollectByMessageId = (
  data: LbChatCollect.cancelCollectByMessageIdParams,
): Promise<LbChatCollect.cancelCollectByMessageIdResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/cancel",
    data,
  );
};

// 搜索结果列表
export const searchResultList = (
  data: LbChatCollect.searchResultListParams,
): Promise<LbChatCollect.searchResultListResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/search-page",
    data,
  );
};

// 置顶
export const setTop = (
  data: LbChatCollect.topParams,
): Promise<LbChatCollect.topResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/add-top",
    data,
  );
};

// 取消置顶
export const cancelTop = (
  data: LbChatCollect.cancelTopParams,
): Promise<LbChatCollect.cancelTopResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/conversation/collect/cancel-top",
    data,
  );
};

// 获取原对话页码
export const getOriginalPage = (
  data: LbChatCollect.getOriginalPageParams,
): Promise<LbChatCollect.getOriginalPageResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/user/conversation-record/collect-to-conversation",
    data,
  );
};
// 获取定制模型原对话页码
export const getCustomOriginalPage = (
  data: LbChatCollect.getOriginalPageParams,
): Promise<LbChatCollect.getOriginalPageResponse> => {
  return catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/collect-to-conversation",
    data,
  );
};

//获取最新对话id
export const getNewMessageId = (
  data: any,
): Promise<LbChatCollect.ResponseData<any>> => {
  return catch_http("post", `/chat-server/api/chat/latest-msg`, data);
};

// 获取收藏数量
export const getCollectNum =
  (): Promise<LbChatCollect.getCollectNumResponse> => {
    return catch_http(
      "get",
      "/chat-server/api/conversation/collect/statistic-info",
    );
  };

// 获取最新的promptid
export const getNewPaintingId = (
  messageId: string,
): Promise<LbChatCollect.ResponseData<any>> => {
  return catch_http(
    "get",
    `/chat-server/api/paint/latest-msg/${messageId}`,
    null,
    null,
    false,
  );
};

// ai阅读收藏列表
export const fetchAiReadingCollect = (params: {
  current: number;
  keyword: string;
  size: number;
}) => {
  return httpPlus<LbChatCollect.IAiReadingCollectRes>(
    "post",
    "/chat-academic/api/user/read/collect/record/read-list",
    params,
  );
};

// 图表公式收藏列表
export const fetchTableImageCollectList = (params: {
  current: number;
  keyword: string;
  size: number;
}) => {
  return httpPlus<LbChatCollect.IAiReadingCollectRes>(
    "post",
    "/chat-academic/api/user/file/collect/record/read-list",
    params,
  );
};

// 图表公式收藏列表
export const fetchTableImageCollectDetail = (params: {
  current: number;
  size: number;
  diskFileId: string;
}) => {
  return httpPlus<{
    imageVo: {
      records: Array<LbChatCollect.ITableImageCollect>;
    };
  }>(
    "post",
    "/chat-academic/api/user/file/collect/record/list-by-disk-file-id",
    params,
  );
};

// 取消图表公式所有收藏
export const tableImageCollectCancleAll = (readId: string) => {
  return httpPlus<unknown>(
    "post",
    "/chat-academic/api/user/file/collect/record/cancel-all",
    { readId },
  );
};

// 收藏详情
export const fetchAiReadingCollectDetail = (params: {
  current: number;
  size: number;
  // 1：速读 2: 图表
  searchType: number;
  readId: string;
}) => {
  return httpPlus<{
    collectId: string | null;
    fastContentVo: LbChatCollect.IQuickData | null;
    imageVo: { records: LbChatCollect.IFomulaListItem[] } | null;
  }>(
    "post",
    "/chat-academic/api/user/read/collect/record/list-by-type",
    params,
  );
};

// 取消ai阅读所有收藏
export const aiReadingCollectCancleAll = (readId: string) => {
  return httpPlus<unknown>(
    "post",
    "/chat-academic/api/user/read/collect/record/cancel-all",
    { readId },
  );
};

// 取消ai阅读收藏
export const aiReadingCollectCancle = (targetId: string) => {
  return httpPlus<unknown>(
    "get",
    `/chat-academic/api/user/read/collect/record/delete/${targetId}`,
  );
};

// 添加ai阅读收藏
export const aiReadingCollectAdd = (params: {
  targetId: string;
  diskFileId: string;
  collectType: number;
}) => {
  return httpPlus<unknown>(
    "post",
    "/chat-academic/api/user/read/collect/record/add",
    params,
  );
};

// 添加图表公式收藏
export const addTableImageCollect = (params: {
  targetId: string;
  diskFileId: string;
  collectType: number;
}) => {
  return httpPlus<unknown>(
    "post",
    "/chat-academic/api/user/file/collect/record/add",
    params,
  );
};

// 图表公式删除收藏
export const removeTableImageCollect = (id: string) => {
  return httpPlus<unknown>(
    "get",
    `/chat-academic/api/user/file/collect/record/delete/${id}`,
  );
};

// 图表公式删除所有收藏
export const removeAllTableImageCollect = (data: { diskFileId: string }) => {
  return httpPlus<unknown>(
    "post",
    `/chat-academic/api/user/file/collect/record/cancel-all`,
    data,
  );
};
