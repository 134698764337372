import { EffectsCommandMap } from "@umijs/max";
import { Payload } from "@/types/type";
import { ChatParameter } from "@/models/chatParameter";
import { IApplyChatRes } from "@/server/netdisk";

export interface IDiskState {
  // 单选或批量选择操作的item
  selectedItems: NNetDisk.IListItem[] 

  // 分享弹窗
  shareModalShow: boolean;

  // 分享弹窗要用到的数据
  shareItems: NNetDisk.IListItem[]

  // 文件提取码，用于提取后的分享文件列表（根目录）接口
  exdrawCode: string

  // 分享记录Id
  shareRecordId: string

  // 应用到会话接口返回
  applyToChatData: IApplyChatRes | null

  // 应用到绘图接口返回
  applyToPaintData: NNetDisk.IChatSelectImageDetail | null
}

export default {
  state: {
    selectedItems: [],
    shareModalShow: false,
    shareItems: [],
    exdrawCode: '',
    shareRecordId: '',
    applyToChatData: null,
    applyToPaintData: null
  } as IDiskState,
  reducers: {
    setSelectedItems: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        selectedItems: payload,
      };
    },
    setShareModalShow: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        shareModalShow: payload,
      };
    },
    setShareItems: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        shareItems: payload,
      };
    },
    setExdrawCode: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        exdrawCode: payload,
      };
    },
    setShareRecordId: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        shareRecordId: payload,
      };
    },
    setApplyToChatData: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        applyToChatData: payload,
      };
    },
    setApplyToPaintData: (state: IDiskState, { payload }: Payload) => {
      return {
        ...state,
        applyToPaintData: payload,
      };
    },
  },
  effects: {},
};
