import { message } from "antd";

interface IBubbleTips {
  content: string;
}

const useBubbleTips = () => {
  const showBubbleTips = ({ content }: IBubbleTips) => {
    message.open({
      className: "read-translate-message-box",
      content: (
        <div className="read-translate-msg-con">
          <img
            src={require("@/assets/images/xueshu/xueshu_vip_tips_icon.png")}
            alt=""
          />
          {content}
        </div>
      ),
    });
  };

  return {
    showBubbleTips,
  };
};

export default useBubbleTips;
