import { Payload } from "@/types/type";

export interface TranslateModelsProps {
  curSessionId: string;
  isRefreshLeft: boolean;
  imageTranslate: boolean;
  originContent: string;
  gptModel: string;
  imageInfo: {
    imageResult: string;
    originalImageUrl: string;
    textResult: string;
  },
  createNew: boolean;
}

export default {
  state: {
    curSessionId: "",
    isRefreshLeft: false,
    imageTranslate: false,
    originContent: "",
    gptModel: "",
    imageInfo: {
      imageResult: "",
      originalImageUrl: "",
      textResult: "",
    },
    createNew: false,
  } as TranslateModelsProps,
  reducers: {
    setCurSessionId: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        curSessionId: payload,
      };
    },
    setIsRefreshLeft: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        isRefreshLeft: payload,
      };
    },
    setImageTranslate: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        imageTranslate: payload,
      };
    },
    setOriginContent: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        originContent: payload,
      };
    },
    setGptModel: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        gptModel: payload,
      };
    },
    setImageInfo: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        imageInfo: payload,
      };
    },
    setCreateNew: (state: TranslateModelsProps, { payload }: Payload) => {
      return {
        ...state,
        createNew: payload,
      };
    },
  },
};
