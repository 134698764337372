import {Payload} from "@/types/type";
import {EffectsCommandMap} from "@umijs/max";
import {payConfigUser} from "@/server/lb-chat-pay";
import {getShareDetail, ShareDetailType} from "@/server/share-moments";

export interface ModelState {
  showShareMoment: boolean; // 分享朋友圈得会员显隐
  validTime: number; // 新用户截止时间
  shareDetail: ShareDetailType | null
}

export default {
  state: {
    showShareMoment: false,
    validTime: 0,
    shareDetail: {},
  } as ModelState,

  reducers: {
    setShowShareMoment: (state: ModelState, {payload}: Payload) => {
      return {
        ...state,
        showShareMoment: payload,
      };
    },

    setValidTime: (state: ModelState, {payload}: Payload) => {
      return {
        ...state,
        validTime: payload,
      };
    },

    setShareDetail: (state: ModelState, {payload}: Payload) => {
      return {
        ...state,
        shareDetail: payload,
      };
    },
  },

  effects: {
    * getCountDown(
      {reload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield payConfigUser(0);
        if (res.code == 0) {
          if (res.data.validTimeLong > Date.now() && res.data.newUser) {
            yield put({
              type: "setValidTime",
              payload: res.data.validTimeLong,
            });
          } else {
            yield put({
              type: "setValidTime",
              payload: null,
            });
          }
        }
      } catch (error) {
      }
    },

    * updateShareDetail(
      {reload}: Payload,
      {call, put, select}: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield getShareDetail();

        if (res.code == 0) {
          // console.log(res.data);
          res.data.requireText = JSON.parse(res.data.requireText as string);

          yield put({
            type: 'setShareDetail',
            payload: res.data
          })
        }
      } catch (e) {
        //
      }

    }

  },
};
