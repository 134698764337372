import { logoPromptKeywordsTs } from "@/components/painting-textarea/paint-textarea-type";
import http, {catch_http, clearRequestCache, httpPlus} from "./request";
import { GetPresetPromptPageParamType } from "./types/lb-painting";

// 用户绘画记录添加
export const paintingAdd = (data: object) =>
  catch_http("post", "/chat-server/api/paint/conversation/add", data);

// 用户绘画记录删除（已弃用）
export const paintingDel = (id: string) =>
  catch_http("post", "/chat-server/api/paint/conversation/delete/" + id);

// 用户绘图记录批量删除
export const paintingBatchDel = (data: string[]) =>
  catch_http("post", "/chat-server/api/paint/conversation/delete-batch", {
    ids: data
  });

// 用户绘图记录单条消息删除
export const paintingSingleDel = (id: string) =>
  catch_http("get", "/chat-server/api/paint/conversation/delete-message/" + id);

// 用户绘画记录修改
export const paintingUpdate = (id: string, data: object) =>
  catch_http("post", "/chat-server/api/paint/conversation/update/" + id, data);
  // 记录置顶
export const pinPainting = (id: string) =>
  catch_http("post", "/chat-server/api/paint/conversation/paint/pinned", {id});
  // 取消置顶
export const cancelPinPainting = (id: string) =>
  catch_http("post", "/chat-server/api/paint/conversation/paint/cancel-pinned", {id});

// 用户绘画记录列表
export const paintingPageList = (data: object) =>
  catch_http("post", "/chat-server/api/paint/conversation/page-of-list", data);

// 用户绘画发送
export const paintingMessageSend = (data: object) =>
  catch_http("post", "/chat-server/api/paint/user-message-send", data);

// 用户单次绘画查询
export const paintingMessageSearch = (data: object) =>
  catch_http<NMJ.IMJMessageItem>("post", "/chat-server/api/paint/task-query", data, {}, false);

// 用户绘画消息历史记录
export const paintingHistory = (data: object) =>
  catch_http("post", "/chat-server/api/paint/list-of-history", data);

// 保存会话配置
export const saveConversationConfig = (data: object) =>
  catch_http("post", "/chat-server/api/paint/save-conversation-config", data);

// 重置会话配置
export const resetConversationConfig = (data: object) =>
  catch_http("post", "/chat-server/api/paint/reset-conversation-config", data);

// 获取配置id
export const getIdbyCanversationId = (data: object) =>
  catch_http("post", "/chat-server/api/paint/get-conversation-config", data);

// 用户获取绘图oss信息
export const paintOssInfo = (data: object) =>
  catch_http<NOSS.IOSS_INFO>("post", "/chat-server/api/paint/get-paint-oss-info", data);

// 用户获取绘图获取上传的图片
export const paintUploadImg = (data: object) =>
  catch_http<string>("post", "/chat-server/api/paint/get-upload-paint", data);

// 用户绘画张数
export const getUserPayPro = () =>
  catch_http("get", "/chat-server/payment/pay-config/user/pay/pro");

// 获取使用案例
export const getPaitingDemos = () =>
  http("post", "/chat-server/api/paint/get-preset-category-list");

// 查询预设prompt分页
export const getPresetPromptPage = (data: GetPresetPromptPageParamType) =>
  http("post", "/chat-server/api/paint/get-preset-prompt-page", data);

// 查询GPT4.0绘图配置
export const getPaintConfig = () =>
  catch_http("get", "/chat-server/api/paint/get-config");

// 最近使用垫图列表
export const paintLatelyUpload = (data: object) =>
  catch_http("post", "/chat-server/paint/image/upload/page-of-list", data);

// 网络图片搜索垫图列表
export const paintSearchImg = (data: object) =>
  catch_http("post", "/chat-server/paint/image/search", data);

// 网络图片垫图图片解析
export const paintImgAnalysis = (data: object) =>
  catch_http("post", "/chat-server/paint/image/analysis", data);

//绘图 猜你想要
export const paintRecommendList = (data: object) =>
  catch_http(
    "post",
    "/chat-server/api/paint/ask/prompt/recommend/list",
    data,
    undefined,
    false
  );
//绘图 默认要求获取
export const getPaintAskCategoryList = () =>
  catch_http("get", "/chat-server/api/paint/ask-category/list");
//绘图 默认要求获取
export const getPaintUserCategoryList = () =>
  catch_http("get", "/chat-server/api/paint/ask-category/user/list");
//绘图 用户要求新增
// /chat-server/api/paint/ask-category/user/add
export const addPaintAskCategory = (data: object) =>
  catch_http("post", "/chat-server/api/paint/ask-category/user/add", data);
//绘图 用户要求修改
export const editPaintAskCategory = (data: object) =>
  catch_http("post", "/chat-server/api/paint/ask-category/user/update", data);
//绘图 用户要求修改
export const removePaintAskCategory = (data: object) =>
  catch_http("post", "/chat-server/api/paint/ask-category/user/delete", data);

// ai绘图收藏
export const painCollect = (data: {
  aiAskMessageId?: string;
  aiMessageId?: string;
  isSystem: boolean;
  targetType?: string;
  sourceUserId: string;
}) => {
  return http("post", "/chat-server/api/conversation/collect/paint/add", data).then(res => {
    if(res.code === 0){
      clearRequestCache(key => key.includes('collect/paint-creation-display/page-of-list'))
    }

    return Promise.resolve(res)
  })
};

// ai绘图取消收藏
export const painCollectCancel = (data: { aiAskMessageIds: string[] }) => {
  return http("post", "/chat-server/api/conversation/collect/paint/cancel", data).then(res => {
    if(res.code === 0){
      clearRequestCache(key => key.includes('collect/paint-creation-display/page-of-list'))
    }

    return Promise.resolve(res)
  })
}


// 新增公开展示
export const paintCreationDisplay = (data: {
  aiMessageId: string;
  prompt: string;
  targetId: string;
}) => http("post", "/chat-server/api/collect/paint-creation-display/add", data);

// 新增公开展示
export const paintCreationDisplayCancel = (data: { id: string }) =>
  http(
    "get",
    `/chat-server/api/collect/paint-creation-display/delete/${data.id}`
  );

// 新增公开展示
export const paintCreationDisplayCancel2 = (data: { id: string }) =>
  http(
    "get",
    `/chat-server/api/collect/paint-creation-display/delete-v2/${data.id}`
  );
export type GetPublicCreationType = {
  current: number;
  size: number;
  timeSort?: boolean;
  viewsNumSort?: boolean;
};

// 查询公开创作列表
export const getPublicCreationList = (data: GetPublicCreationType) =>
  catch_http(
    "post",
    "/chat-server/api/collect/paint-creation-display/page-of-list",
    data
  );

export type PaintingCollectType = {
  aiAskMessageId: string;
  aiMessageId?: string;
  isSystem: boolean;
  targetType: PaintingCollectTargetTypeEnum;
  sourceUserId: string;
};

export enum PaintingCollectTargetTypeEnum {
  PAINT = "paint",
}

// 绘画收藏
export const paintingCollect = (data: PaintingCollectType) =>
  painCollect(data);

// 取消绘画收藏
export const paintingCancelCollect = (data: string[]) => painCollectCancel({aiAskMessageIds: data})

// 浏览量增加
// export const paintingViewIncreament = (data: {
//   id?: string,
//   targetId?: string,
// }) =>
//   catch_http("post", "/chat-server/api/collect/paint-creation-display/views/incr", data);

// export const addBroswerCount =
export type AddBroswerCountType = {
  id?: string;
  targetId?: string;
};

// 新增浏览量
export const addBroswerCount = (data: AddBroswerCountType) =>
  catch_http(
    "post",
    "/chat-server/api/collect/paint-creation-display/views/incr",
    data
  );

export type GetCollectDrawListType = {
  current: number;
  keyword: string;
  size: number;
  type: PaintingCollectTargetTypeEnum;
};

// 获取收藏绘画列表
export const getCollectDrawList = (data: GetCollectDrawListType) =>
  catch_http(
    "post",
    "/chat-server/api/conversation/collect/paint/search",
    data
  );

export type GetPictureDetailType = {
  aiAskMessageId: string;
  isSystem: boolean;
};

// 获取图片详情
export const getPictureDetail = (data: GetPictureDetailType) =>
  catch_http(
    "post",
    "/chat-server/api/conversation/collect/paint/detail-data-info",
    data
  );

export type CreatePublicType = {
  aiMessageId: string;
  prompt: string;
  targetId: string;
};

// 创作公开
export const createPublic = (data: CreatePublicType) =>
  catch_http(
    "post",
    "/chat-server/api/collect/paint-creation-display/add",
    data
  );

// 取消公开展示
export const cancalPublic = (id: string) =>
  catch_http(
    "get",
    `/chat-server/api/collect/paint-creation-display/delete/${id}`
  );

export type GetCollectCountType = {
  id: string;
  targetId: string;
};

// 获取收藏数量
export const getCollectCount = (data: GetCollectCountType) =>
  catch_http(
    "post",
    "/chat-server/api/collect/paint-creation-display/views/get-num",
    data
  );

// 获取logo prompt优化
export const logoPromptKeywords = (data: logoPromptKeywordsTs) =>
  catch_http(
    "post",
    "/chat-server/api/paint/ask/prompt/recommend/keywords",
    data
  );

export function easeBackground (data: {
  image_link: string,
  image_index: number,
}) {
  return catch_http('post', '/chat-server/api/paint/remove-background', data) as Promise<{
    data: string
    code: number,
    msg: string
  }>
}

export function getOptimizeHistoryList (data: {
  current: number,
  size: number,
}) {
  return catch_http('post', '/chat-server/api/paint/opt/page-of-list', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

/**
 * 写真相关
 */
//获取当前数字分身
export function getPhotoAvatar (data: {
  defaultFlag: boolean,
  id?: string,
  name?:""
}) {
  return catch_http('post', '/chat-server/api/paint/photo/avatar/get-avatar', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//添加写真草稿
export function setPhotoAddDraft(data: {
  action: number,
  originImageOssKey: string,
  taskId:string 
}) {
  return catch_http('post', '/chat-server/api/paint/photo/add-draft', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//添加写真/数字分身
export function setPhotoAddTask(data: {
  action: number,
  originImageOssKey: string,
  taskId?:string,
  productionId?:string
}) {
  return catch_http('post', '/chat-server/api/paint/photo/add-task', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//添加写真/数字分身
export function removePhotoTask(data: {
  id:string
}) {
  return catch_http('post', '/chat-server/api/paint/photo/avatar/delete', {
    ...data,
    deleted: true
  }) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//查询写真制作进度
// status 	状态: （1：草稿；2：未提交；3：已提交）
export function getPhotoProductionTask(data: {
  action: number,
  status: number,
  taskId:string,
}) {
  return catch_http('post', '/chat-server/api/paint/photo/add-task', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//获取草稿
export function getPhotoTask(data: {
  action: number,
  status: number,
  taskId?:string,
  productionId?:string
}) {
  return catch_http('post', '/chat-server/api/paint/photo/get-task', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//查询数字分身训练进度
export function getPhotoQueryPhotoTask(data: {
  action: number,
  status: number,
  taskId?:string,
  productionId?:string
}) {
  return catch_http('post', '/chat-server/api/paint/photo/query-photo-task', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}
//查询写真制作进度
export function getPhotoQueryProductionTask(data: {
  action: number,
  status: number,
  taskId?:string,
}) {
  return catch_http('post', '/chat-server/api/paint/photo/query-production-task', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//修改数字分身
export function updateAvatarInfo(data: {
  defaultFlag: boolean,
  id: string,
  name?:string,
}) {
  return catch_http('post', '/chat-server/api/paint/photo/avatar/update', data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//获取写真结果列表
export function getPortrayPhotoList() {
  return catch_http('post', '/chat-server/api/paint/photo/get-task-list') as Promise<{
    data: any
    code: number,
    msg: string
  }>
}

//获取数字分身列表
export function getPhotoAvatarList() {
  return catch_http('post', '/chat-server/api/paint/photo/avatar/list') as Promise<{
    data: any
    code: number,
    msg: string
  }>
}
//查询写真模版列表
export function getTemplateList(data:{
  current:number,
  type:number | null,
  size:number
}) {
  return catch_http('post', '/chat-server/api/paint/photo/template/page-of-list',data) as Promise<{
    data: any
    code: number,
    msg: string
  }>
}


// 公开创作
export const requestOpenDisplay = (params: {aiMessageId: string, prompt: string}) => {
  return httpPlus<void>("post", "/chat-server/api/collect/paint-creation-display/add", params);
};

// 取消公开创作
export const requestCloseDisplay = (aiMessageId: string) => {
  return httpPlus<void>("get", `/chat-server/api/collect/paint-creation-display/delete-v2/${aiMessageId}`);
};

// 根据id获取左侧历史绘画记录详情
export const paintGetDetail = (data: object) =>
  catch_http("post", "/chat-server/api/paint/conversation/get-detail", data);

// 根据taskId获取绘图信息
export const getOptionConfig = (data: object) =>
  catch_http("post", "/chat-server/api/paint/get-paint-config", data);

// 根据taskId获取绘图信息
export const setSameOption = (data: object) =>
  catch_http("post", "/chat-server/api/paint/set-same-config", data);

// 获取写真管理列表
export const getPhotoManageList = (data: object) =>
  catch_http("post", "/chat-server/api/paint/photo/page-of-list", data);

// 获取写真管理列表
export const removePhoto = (data: object) =>
  catch_http("post", "/chat-server/api/paint/photo/delete-tasks", data);