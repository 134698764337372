/**
 * AI搜索相关服务
 */
import http, { catch_http, httpPlus } from "./request";
import { isDesk } from "@/utils/utils";

// 获取AI搜索参考信息
export const getAiSearchRefsToList = (params: { searchKeyWords: string }) => {
  return new Promise((resolve, reject) => {
    http("get", "/chat-server/api/searcher/ai/refer-to-list", params)
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        resolve({
          code: -1,
          data: [],
        });
      });
  });
  // catch_http("get", "/chat-server/api/searcher/ai/refer-to-list", params);
};

// 获取AI搜索答复信息
export const AiSearchAnswerInfoUrl = "/chat-server/api/searcher/ai/answer-info";
export const getAiSearchAnswerInfo = (params: { searchKeyWords: string }) =>
  catch_http("post", AiSearchAnswerInfoUrl, params);

// 获取AI搜索追问
export const getAiSearchSuperaddition = (params: {
  answerStr?: string;
  searchKeyWords: string;
}) =>
  catch_http(
    "post",
    "/chat-server/api/searcher/ai/superaddition-request",
    params,
  );

// 获取AI搜索问题脱敏判断
export const getAiSearchjudgeSensitiveApi = (params: { text: string }) =>
  catch_http("post", "/chat-server/api/searcher/ai/judge-sensitive", params);

//导读推荐列表
export const getAiSearchIntroductionList = (params: {
  current: number;
  size: number;
}) => catch_http("post", "/chat-server/api/introduction/data/page", params);

//导读推荐文章详情
export const getAiSearchIntroductionDetail = (params: { id: string }) =>
  catch_http("get", "/chat-server/api/introduction/data/detail", params);

/**
 * 阅读
 */
// Integer INTRODUCTION_DATA_TYPE_READ = 1;

/**
 * 收藏
 */
// Integer INTRODUCTION_DATA_TYPE_COLLECT = 2;
/**
 * 点赞
 */
// Integer INTRODUCTION_DATA_TYPE_LIKE = 3;
/**
 * 求助原文
 */
// Integer INTRODUCTION_DATA_TYPE_HELP = 4;
//用户导读操作记录相关接口添加
export const setIntroductionOperationAdd = (params: {
  introductionId: string | number;
  type: number;
}) => catch_http("post", "/chat-server/api/introduction/operation/add", params);
//用户导读操作记录相关接口 删除
export const setIntroductionOperationRemove = (params: {
  introductionId: string;
  type: number;
}) => catch_http("post", "/chat-server/api/introduction/operation/del", params);

//求助全文
export const setScholarHelp = (params: {
  email: string;
  htmlUrl: string;
  title: string;
  docId: string;
}) => catch_http("post", "/chat-server/api/scholar/help", params, null, false);
//用户导读
export const setIntroductionHelp = (params: {
  introductionId: string;
  userEmail: string;
}) =>
  catch_http(
    "post",
    "/chat-server/api/introduction/seek/add",
    params,
    null,
    false,
  );

//用户导读
export const getIntroductionSeek = (params: {
  introductionId?: string;
  link?: string;
  docId?: string;
}) =>
  catch_http(
    "get",
    "/chat-server/api/introduction/seek/check",
    params,
    null,
    false,
  );

//搜索词总结
export const getKeyWordSummary = (params: { searchContent: string }) =>
  catch_http(
    "post",
    "/chat-server/api/scholar/keyword/summary",
    params,
    null,
    false,
  );

//搜索词推荐
export const getKeyWordRecommend = (params: { searchContent: string }) =>
  catch_http(
    "post",
    "/chat-server/api/scholar/keyword/recommend",
    params,
    null,
    false,
  );
//获取上次求助email
export const getLastEmail = (params: {}) =>
  catch_http(
    "post",
    "/chat-server/api/scholar/get-last-help-email",
    params,
    null,
    false,
  );

//最近保存文件
export const diskLatestFile = (size: number, suffix: string) =>
  catch_http("post", "/chat-academic/api/disk/latest-file", {
    size: size,
    suffix: suffix,
  });

//文件资源各类型占比
export const diskCapacityStat = () =>
  catch_http("get", "/chat-academic/api/disk/capacity/stat");

//网盘容量和策略获取
export const diskFileInfo = () =>
  catch_http("get", "/chat-server/api/disk/file/disk-info");

//查询用户完成的全文翻译记录
export const translatePageList = (params: object) =>
  catch_http("post", "/chat-academic/api/user-translate/query-list", params);

//用户最近一次帮读
export const readLastList = (params?: object) =>
  catch_http("post", "/chat-academic/api/read/record/last", params);
//用户最近一次帮读
export const readLastListV2 = (params?: object) =>
  catch_http("post", "/chat-academic/api/read/record/document/last", params);

//获取示例公共配置
export const academicConfigInfo = () =>
  catch_http("get", "/chat-academic/api/common-config/info");

// 求助--提交 0：导读求助，1学术搜索求助(google) 2学术问答求助 3 semantic学术求助 4 arxiv学术求助 5 pubmed学术求助
export const knowledgeHelpSub = (params: {
  type: number;
  id: string;
  title: string;
  doi?: string;
  extraId?: string;
}) => catch_http("post", "/chat-academic/api/knowledge/help-submit", params);

// 下载网盘文件夹列表
export const diskFileList = (params: object) =>
  catch_http("post", "/chat-server/api/disk/file/list", params);

// 学术文档-下载到网盘
export const academicDocDownload = (params: object) =>
  catch_http(
    "post",
    "/chat-academic/api/knowledge/academic-doc-download",
    params,
  );

// 学术文档- 解读、翻译保存
export const academicDocSave = (params: object) =>
  catch_http("post", "/chat-academic/api/knowledge/academic-doc-save", params);

// 获取下载全文翻译译文链接
export const translateDownUrl = (id: string | number) =>
  catch_http(
    "get",
    "/chat-academic/api/user-translate/download-url?fileId=" + id,
  );

// 创建翻译结果分享链接;
export const translateShareInfo = (params: object) =>
  catch_http("post", "/chat-academic/api/user-translate/share-info", params);

// 单文件直接上传地址;
export const diskFileUploadUrl = (md5: string, suffix: string) =>
  catch_http<NCommon.IUploadUrlInfo>(
    "get",
    "/chat-server/api/disk/file/upload-url?md5=" + md5 + "&suffix=" + suffix,
  );

// 上传文件;
export const diskFileUploadFun = (params: object) =>
  catch_http("post", "/chat-server/api/disk/file/upload", params);

// 创建文件夹;
export const diskFileCreateFolder = (params: object) =>
  catch_http("post", "/chat-server/api/disk/file/create-folder", params);

// 用户求助结果(单个状态);
export const knowledgeHelpHistoryState = () =>
  catch_http("get", "/chat-academic/api/knowledge/help-history-state");

// 获取用户学术vip信息
interface IXueshuPolishList {
  id: string;
  title: string;
  createTime: string;
  state: number;
  stateName: string;
  fileType: string;
}
export const articlePolishRecent = (size: number) => {
  return httpPlus<IXueshuPolishList[]>(
    "post",
    `/chat-academic/api/polish/latest?size=${size}`,
  );
};

// 润色文档下载地址
export const getPolishDownloadUrl = (id: string) => {
  return httpPlus<string>(
    "post",
    "/chat-academic/api/polish/result-url?id=" + id,
  );
};

// 批量查询文档翻译进度
export const getTranslateProgress = (fileIds: string[]) => {
  return httpPlus<TranslateProgress.Item[]>(
    "post",
    "/chat-academic/api/user-translate/translate-progress",
    {
      fileIds,
    },
  );
};
