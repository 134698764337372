// 学术搜索关键词存取
import { v4 as uuidv4 } from "uuid";

export const storeKeyword = (keyword: string, searchType: string): string => {
  const obj = JSON.parse(localStorage.getItem("scholarKeywords") || "{}");
  const keys = Object.keys(obj);

  // 查找是否存在已经有此keyword的key
  const existingKey = keys.find(
    (key) => obj[key].keyword === keyword && obj[key].searchType === searchType
  );

  if (existingKey) {
    // 如果存在，返回之前的key
    return existingKey;
  } else {
    // 如果不存在，生成一个新的key并返回
    const newKey = uuidv4();
    obj[newKey] = {
      keyword,
      searchType,
    };

    // 如果本地存储中的scholarKeywords条数超出100，删掉一条旧数据
    if (keys.length >= 100) {
      const oldestKey = keys[0];
      delete obj[oldestKey];
    }

    localStorage.setItem("scholarKeywords", JSON.stringify(obj));
    return newKey;
  }
};

//获取关键词
export const getKeyword = (key: string): string | null => {
  const obj = JSON.parse(localStorage.getItem("scholarKeywords") || "{}");
  return obj[key]?.keyword || null;
};

//获取搜索类型
export const getSearchType = (key: string): string | null => {
  const obj = JSON.parse(localStorage.getItem("scholarKeywords") || "{}");
  return obj[key]?.searchType || null;
};
