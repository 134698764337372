/**
 * 与对话相关的工具
 * */
import { getDvaApp, history } from "@@/exports";
import { RootState } from "@/types/type";
import { commonModalConfirm } from "@/utils/commonModal";
import { dispatch } from "@/app-initialization/tools";
import { dalleHistoryList } from "@/server/paint-dalle";
import { GptModeType } from "@/pages/chat-int/types";
import { getCodeByType } from "@/pages/newHome/components/functionMenus/constants";
import { isDesk, customByMediaScreen, userAgent } from "@/utils/utils";
import useSyncCallback from "@/hooks/useSyncCallback";
import PubSub from "pubsub-js";
import { PubSubType } from "@/const";
import { message } from "antd";
import { getDefaultChatGptConfig } from "@/models/chatMessage";
import { customModelHistoryList } from "@/server/customModel";

export const getDav = function (): RootState {
  return getDvaApp()?._store.getState();
};

// @ts-ignore
window.x = getDav;

export const getConversationTypeTitle = function (
  type: NConversation.TConversationType,
): string {
  if (type === "conversation") {
    return "AI对话";
  }

  if (type === "painting") {
    return "midjourney";
  }

  if (type === "paintDalle") {
    return "DALL·E 3";
  }

  return "AI对话";
};

export const getConversationDetailPathList = function (): string[] {
  return ["/chat", "/painting", "/dalle", "/create-new-page"];
};

// 获取Conversation的链接
export const getConversationDetailPath = function (
  type: NConversation.TConversationType,
): string {
  if (type === "conversation") {
    return "/chat";
  }

  if (type === "painting") {
    return "/painting";
  }

  if (type === "paintDalle") {
    return "/dalle";
  }

  return "/chat";
};

// 获取Conversation的链接链接
export const getConversationDetailLink = function (
  type: NConversation.TConversationType,
  sessionId: string,
): string {
  return getConversationDetailPath(type) + "?sessionid=" + sessionId;
};

// 检查在对话/绘图进行中跳转链接离开前
export const checkConfirmBeforeLeaveChatting = function (
  actionText: string = "进行切换",
): Promise<void> {
  return new Promise((resolve) => {
    const dav = getDav();
    const pathname = isDesk()
      ? location.hash.slice(1).split("?")[0]
      : location.pathname;

    if (!dav) {
      return resolve();
    }

    let message: string = "";

    if (pathname === "/chat" && dav.chatMessage.chatPending) {
      message = `当前有会话正在生成中，是否${actionText}？`;
    }

    if (pathname === "/painting") {
      const parameter = dav.paintingParameter;

      if (
        parameter.drawLoading ||
        parameter.image2promptLoading ||
        dav.paintDalle.dalleCurrentHasGenerate
      ) {
        message = `当前有绘图正在生成中，是否${actionText}？`;
      }
    }

    if (pathname === "/dalle" && dav.paintDalle.dalleCurrentHasGenerate) {
      message = `当前有绘图正在生成中，是否${actionText}？`;
    }

    if (!message) {
      dispatch({ type: "chatParameter/setConversationItem", payload: {} });
      dispatch({
        type: "chatMessage/setData",
        payload: { chatGptConfig: getDefaultChatGptConfig() },
      });
      return resolve();
    }

    commonModalConfirm({
      mainTitle: "温馨提示",
      content: message,
    }).then(() => {
      // 会话停止
      dispatch({ type: "chatMessage/stop" });
      dispatch({ type: "chatParameter/setConversationItem", payload: {} });
      dispatch({
        type: "chatMessage/setData",
        payload: { chatGptConfig: getDefaultChatGptConfig() },
      });
      // 取消加载动画
      dispatch({
        type: "paintingParameter/setPaintingChatLoading",
        payload: false,
      });
      dispatch({
        type: "paintingParameter/setDrawLoading",
        payload: false,
      });
      dispatch({
        type: "paintingParameter/setImage2promptLoading",
        payload: false,
      });

      dispatch({
        type: "paintDalle/setDalleCurrentHasGenerate",
        payload: false,
      });
      dispatch({
        type: "paintingParameter/setPaintOssKey",
        payload: "",
      });
      dispatch({
        type: "paintingParameter/setDiagram",
        payload: {
          imgUploadUrl: "",
        },
      });
      resolve();
    });
  });
};

// 取历史记录里的第一条id
export const getFirstConversationHistoryItemSessionId = async function (
  type: NConversation.TConversationType,
): Promise<string> {
  const isChat = type == "conversation" ? true : false;
  const isDalle = type == "paintDalle" ? true : false;
  const dav = getDav();

  let theFirstItemId: string = "";

  if (isDalle) {
    // 去请求
    if (dav.leftSideX.paintConversationCount.dalle3Count > 0) {
      const res = await dalleHistoryList({ current: 1, size: 1 });

      if (res?.data?.records && res.data.records[0]) {
        theFirstItemId = res.data.records[0].id;
      }
    }
    return theFirstItemId;
  }

  const count = isChat
    ? dav.leftSideX.chatConversationCount
    : dav.leftSideX.paintConversationCount.midjourneyCount;

  if (count > 0) {
    // 去请求
    const res = await dispatch({
      type: isChat
        ? "chatParameter/getConversationPageList"
        : "paintingParameter/getPaintingPageList",
      payload: { current: 1, size: 1 },
    });

    if (res?.data?.records && res.data.records[0]) {
      theFirstItemId = res.data.records[0].id;
    }
  }

  return theFirstItemId;
};

export const leftHistoryXToggle = async (isChat: boolean) => {
  const dav = getDav();

  let theType: NConversation.TConversationType = isChat
    ? "conversation"
    : dav.chatParameter.currentType === "conversation"
    ? "painting"
    : dav.chatParameter.currentType;

  // 要确保当前tab有对话数量
  if (!isChat) {
    const theCount =
      theType === "painting"
        ? dav.leftSideX.paintConversationCount.midjourneyCount
        : dav.leftSideX.paintConversationCount.dalle3Count;

    if (theCount <= 0) {
      theType = theType === "painting" ? "paintDalle" : "painting";
    }
  }

  const theFirstHistoryItemId: string =
    await getFirstConversationHistoryItemSessionId(theType);

  if (!theFirstHistoryItemId) {
    message.warning(`"${getConversationTypeTitle(theType)}"没有记录`);
    return;
  }

  // 跳页
  navigateToConversationPage(theType, theFirstHistoryItemId);
};

export const leftCustomModelHistoryXToggle = async () => {
  const dav = getDav();

  const count = dav.leftSideX.customModelChatListCount;

  let theFirstItemId: string = "";

  let theFirstmodelRecordId: string = "";

  if (count > 0) {
    // 去请求
    const res = await customModelHistoryList({ current: 1, size: 1 });
    if (res?.data?.records && res.data.records[0]) {
      theFirstItemId = res.data.records[0].id;
      theFirstmodelRecordId = res.data.records[0].modelRecordId;
    }
  }

  if (!theFirstItemId) {
    message.warning(`"定制模型对话"没有记录`);
    return;
  }

  customModelConfirmBeforeLeaveChatting().then(() => {
    history.push(
      "/custom-model-chat?sessionid=" +
        theFirstItemId +
        "&id=" +
        theFirstmodelRecordId,
    );
  });
};

// 切换到对话或者绘图页面 (拷贝的之前的逻辑)
export const navigateToConversationPage = (
  type: NConversation.TConversationType,
  sessionId?: string,
  config?: { replace: boolean; homePath?: string },
) => {
  checkConfirmBeforeLeaveChatting().then(() => {
    //暂停对话生成
    dispatch({ type: "chatMessage/stop" });

    const isH5 = customByMediaScreen(1010);

    dispatch({ type: "paintingParameter/setPaintingTextarea", payload: "" });

    if (isH5) {
      dispatch({ type: "chatParameter/setHasSmallMenu", payload: false });
    }

    dispatch({ type: "chatParameter/setCurrentType", payload: type });

    dispatch({
      type: "chatFunction/setCurrentFunctionMenuType",
      payload:
        type === "conversation" ? "PopularConversations" : "PopularPaintings",
    });
    dispatch({
      type: "chatFunction/setCurrentFunctionMenuTypeCode",
      payload: getCodeByType(
        type === "conversation" ? "PopularConversations" : "PopularPaintings",
      ),
    });

    dispatch({ type: "paintingParameter/setPaintingSwitch", payload: "stop" });
    dispatch({ type: "paintingParameter/setDrawLoading", payload: false });
    dispatch({
      type: "paintingParameter/setIsPaintScrollEnd",
      payload: true,
    });
    dispatch({ type: "paintingParameter/setPaintingLoading", payload: true });

    // 详情数据重置
    dispatch({ type: "chatParameter/setConversationItem", payload: {} });
    dispatch({ type: "paintingParameter/setPaintingItem", payload: {} });

    dispatch({
      type: "paintingParameter/setPaintingMessageResult",
      payload: [],
    });

    // 重置定制模型选中
    dispatch({ type: "customModel/setHasCustomModel", payload: false });

    // 重置左侧历史记录
    PubSub.publish(PubSubType.RESET_LEFT_HISTORY);

    let homePathUrl = config?.homePath || "/";

    if (!config?.homePath && (type === "painting" || type === "paintDalle")) {
      homePathUrl = "/?hometype=painting";
    } else if (!config?.homePath && type === "conversation") {
      homePathUrl = "/?hometype=chat";
    }

    if (!sessionId) {
      config?.replace
        ? history.replace(homePathUrl)
        : history.push(homePathUrl);
    } else {
      (config?.replace ? history.replace : history.push)(
        getConversationDetailLink(type, sessionId),
      );
    }
  });
};

// 检查在定制模型对话跳转链接离开前
export const customModelConfirmBeforeLeaveChatting = function (
  actionText: string = "进行切换",
): Promise<void> {
  return new Promise((resolve) => {
    const dav = getDav();
    const pathname = location.pathname;

    let message: string = "";

    if (
      pathname === "/custom-model-chat" &&
      dav.customChatMessage.chatLoading
    ) {
      message = `当前有对话正在生成中，是否${actionText}？`;
    }

    if (!message) {
      dispatch({
        type: "chatParameter/setCurrentType",
        payload: "conversation",
      });
      dispatch({ type: "customModel/setHasCustomModel", payload: true });
      dispatch({
        type: "chatFunction/setCurrentFunctionMenuType",
        payload: "CustomModel",
      });
      return resolve();
    }

    commonModalConfirm({
      mainTitle: "温馨提示",
      content: message,
    }).then(() => {
      dispatch({ type: "customModel/setHasCustomModel", payload: true });
      resolve();
    });
  });
};

// 检查当前用户是否有指定的gpt权限
export const checkHasModelPermission = (
  item: GptModeType,
  userBenefit?: NUser.IUserBenefit,
) => {
  if (!item) {
    console.error(`[checkHasModelPermission]传入模型为空`);
    return true;
  }

  userBenefit = userBenefit || getDav().userParameter.userBenefit;

  if (
    (item.modelCategory === "GPT-4" && !userBenefit?.modelPrivilege) ||
    (item.name === "Claude" && !userBenefit?.claudeModelPrivilege) ||
    (item.name === "wenxinyiyan" && !userBenefit?.wenxinPrivilege) ||
    (item.name === "tongyiqianwen" && !userBenefit?.tongyiPrivilege) ||
    (item.name === "zhipu-AI" && !userBenefit?.zhipuPrivilege) ||
    (item.name === "gemini" && !userBenefit?.geminiPrivilege) ||
    (item.name === "Mistral Large" && !userBenefit?.mistralPrivilege) ||
    (item.name === "kimi" && !userBenefit?.kimiPrivilege) ||
    (item.name === "Doubao" && !userBenefit?.doubaoPrivilege) ||
    (item.name === "o1-mini" && !userBenefit?.o1MiniPrivilege) ||
    (item.name === "o1-preview" && !userBenefit?.o1PreviewPrivilege)
  ) {
    return false;
  }

  return true;
};

// 检查当前用户剩余的模型次数
export const getModelLeftTimes = (
  item: GptModeType,
  userBenefit?: NUser.IUserBenefit,
) => {
  if (!item) {
    console.error(`[getModelLeftTimes]传入模型为空`);
    return 0;
  }

  userBenefit = userBenefit || getDav().userParameter.userBenefit;

  if (item.modelCategory === "GPT-4") {
    return userBenefit.remainDayNum;
  }

  if (item.model === "claude") {
    return userBenefit.claudeRemainDayNum;
  }

  if (item.model === "wenxinyiyan") {
    return userBenefit.wenxinRemainDayNum;
  }

  if (item.model === "tongyiqianwen") {
    return userBenefit.tongyiRemainDayNum;
  }

  if (item.model === "zhipu-AI") {
    return userBenefit.zhipuRemainDayNum;
  }

  if (item.model === "gemini-pro-vision") {
    return userBenefit.geminiRemainDayNum;
  }

  if (item.model === "moonshot-v1-8k") {
    return userBenefit.kimiRemainDayNum;
  }

  if (item.model === "Doubao-pro-32k") {
    return userBenefit.doubaoRemainDayNum;
  }

  if (item.model === "o1-mini") {
    return userBenefit.o1MiniRemainDayNum;
  }

  if (item.model === "o1-preview") {
    return userBenefit.o1PreviewRemainDayNum;
  }

  return 0;
};

export const getGPT4Model = function () {
  return getDav().chatParameter.GPT_MODEL4;
};
export const getGPT3_5Model = function () {
  return getDav().chatParameter.GPT_MODEL3_5;
};

export const getDefaultModel = function () {
  const list = getDav().chatParameter.gptModeList;

  return list.find((a) => a.isDefault) || list[0];
};
