import { SqueezeInfoType } from "@/layouts/components/squeezeModal/types";
import { logout } from "@/server/lb-login-auth";
import { Payload } from "@/types/type";
import { isDesk, setClearExtendArgument, userAgent } from "@/utils/utils";
import { EffectsCommandMap } from "@umijs/max";
import { checkBenefitInfo } from "@/server/lb-chat-prompt";
import {getDvaApp} from "@@/exports";

export interface UserParameter {
  hasResetPassword?: boolean;
  loginType?: string;
  hasLoginPop?: boolean;
  squeezeInfo: Partial<SqueezeInfoType> & { show: boolean };
  hasSignOut?: {
    show: boolean;
    progress: number;
  };
  signOutInfo?: object;
  userBenefit: NUser.IUserBenefit;
  isShowBindPop: boolean;
  currentToken: string;
  showMoreScene: boolean
}

const getDefaultUserBenefit = function (): NUser.IUserBenefit {
  return {
    "modelPrivilege": false,
    "remainDayNum": 0,
    "claudeModelPrivilege": false,
    "claudeRemainDayNum": 0,
    "wenxinRemainDayNum": 0,
    "tongyiRemainDayNum": 0,
    "zhipuRemainDayNum": 0,
    "fileParseDayNum": 0,
    "wenxinPrivilege": false,
    "tongyiPrivilege": false,
    "zhipuPrivilege": false,
    "gptFilesLimit": 0
  }
}

export default {
  state: {
    // 找回密码成功弹窗开关
    hasResetPassword: false,
    // 登录类型
    loginType: "mobile",
    // 是否显示登录弹窗
    hasLoginPop: false,
    squeezeInfo: { show: false },
    // 是否是移动端
    isMobile: userAgent(),
    // 是否注销账户
    hasSignOut: {
      show: false,
      progress: 0, //0 注销协议  1 注销验证码 2 注销中 3 注销成功
    },
    // 账号注销信息
    signOutInfo: {},
    userBenefit: getDefaultUserBenefit(),
    // 是否展示绑定手机号弹窗
    isShowBindPop: false,
    // 登录存储
    currentToken: "",
    // 更多场景弹窗
    showMoreScene: false
  } as UserParameter,

  reducers: {
    setHasResetPassword: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        hasResetPassword: payload,
      };
    },
    setSqueezeInfo: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        squeezeInfo: payload,
      };
    },
    setLoginType: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        loginType: payload,
      };
    },
    setHasLoginPop: (state: UserParameter, { payload }: Payload) => {
      // 关闭弹窗检查是否有创建角色扮演操作的缓存，有就清除掉
      if (!payload) {
        sessionStorage.removeItem("create-role-play-action");
      }

      return {
        ...state,
        hasLoginPop: payload,
      };
    },
    setHasSignOut: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        hasSignOut: payload,
      };
    },
    setSignOutInfo: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        signOutInfo: payload,
      };
    },
    setUserBenefit: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        userBenefit: payload,
      };
    },
    setIsShowBindPop: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        isShowBindPop: payload,
      };
    },
    setCurrentToken: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        currentToken: payload,
      };
    },
    setShowMoreScene: (state: UserParameter, { payload }: Payload) => {
      return {
        ...state,
        showMoreScene: payload,
      };
    },
  },

  effects: {
    // 退出登录
    *logout(
      { reload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(logout);
        if (res?.code == 0) {
          getDvaApp()._store.dispatch({ type: "userInfo/updateToken", payload: '' });
          setClearExtendArgument();
          sessionStorage.clear();
          localStorage.setItem("openBeautifyNum", "0");
          // 桌面端得用reload
          if (isDesk() || reload) {
            location.reload();
          } else {
            location.href = location.origin;
          }
          localStorage.setItem("translate-files-ids", JSON.stringify([]));
        }
      } catch (err) {}
    },

    *getUserBenefit(
      { payload }: Payload,
      { call, put }: EffectsCommandMap
    ): Generator {
      const res: any = yield call(checkBenefitInfo);
      if (res?.code == 0) {
        yield put({
          type: "setUserBenefit",
          payload: res.data,
        });
      }
      return res;
    },
  },
};
