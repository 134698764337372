import { Payload } from "@/types/type";

export interface ICommonStore {
  requestRedirectDomain: string; // 是否显示重定向弹窗，来自于axios拦截-401
}

export default {
  state: {
    requestRedirectDomain: "",
  } as ICommonStore,
  reducers: {
    setRequestRedirectDomain: (state: ICommonStore, { payload }: Payload) => {
      return {
        ...state,
        requestRedirectDomain: payload,
      };
    },
  },
};
