import { EffectsCommandMap } from "@umijs/max";
import { Payload } from "@/types/type";
import {
  requestReadBookList,
  readBookRename,
  deleteBook,
} from "@/server/lb-chat-reading";

export interface IChatReadingState {
  modeIndex: "0" | "1";
  showExample: boolean;
  showMind: boolean;
  step: 0 | 1 | 2;
  isGenerated: boolean;
  fileDataId: number | undefined;
  readBookList: [];
  currentId: string;
  activeObj: {
    index: number;
    sIndex: number;
  };
  showSlidebar: boolean;
}

export default {
  state: {
    modeIndex: "0",
    showExample: false,
    showMind: false,
    step: 0,
    readBookList: [],
    currentId: "",
    activeObj: {},
    showSlidebar: true,
  } as IChatReadingState,
  reducers: {
    setModeIndex: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        modeIndex: payload,
      };
    },
    setShowMind: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        showMind: payload,
      };
    },
    setShowExample: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        showExample: payload,
      };
    },
    setStep: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        step: payload,
      };
    },
    setIsGenerated: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        isGenerated: payload,
      };
    },
    setReadBookList: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        readBookList: payload,
      };
    },
    setCurrentId: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        currentId: payload,
      };
    },
    setActiveObj: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        activeObj: payload,
      };
    },
    setShowSlidebar: (state: IChatReadingState, { payload }: Payload) => {
      return {
        ...state,
        showSlidebar: payload,
      };
    },
  },
  effects: {
    // 获取左侧阅读列表
    *getReadBookList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(requestReadBookList, payload);
        if (res?.code == 0) {
          yield put({
            type: "setReadBookList",
            payload: res.data,
          });
        }
        return Promise.resolve(res.data);
      } catch (err) {
        return Promise.reject();
      }
    },
    *rename(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(readBookRename, payload);
        if (res.code != 0) {
          return Promise.reject();
        }
        yield put({ type: "getReadBookList" });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },
    *del(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(deleteBook, payload);
        if (res.code != 0) {
          return Promise.reject();
        }
        yield put({ type: "getReadBookList" });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject();
      }
    },
  },
};
