import { createSimpleExternalStore } from '@/utils/store'
import { GptModeType } from './types'

export enum SwitchModeType {
  GPT,
  MJ,
  DALLE
}

type ChatInitState = {
  switchModeType: SwitchModeType
}

type ChatInitActions = {
  switchModeType2GPT: []
  switchModeType2MJ: []
  switchModeType2DALLE: []
}

export const useChatInitStore = createSimpleExternalStore<ChatInitState, ChatInitActions>({
  switchModeType: SwitchModeType.GPT
}, {
  switchModeType2GPT(state) {
    console.log('set', SwitchModeType.GPT)
    return {
      switchModeType: SwitchModeType.GPT
    }
  },
  switchModeType2MJ(state) {
    console.log('set', SwitchModeType.MJ)
    return {
      switchModeType: SwitchModeType.MJ
    }
  },
  switchModeType2DALLE(state) {
    console.log('set', SwitchModeType.DALLE)
    return {
      switchModeType: SwitchModeType.DALLE
    }
  }
})

// 处理新gpt模型数据转换
export const useGptModelList = (data: GptModeType[]): GptModeType[] => {
  // 模型数据处理
  const uniqueChildren: GptModeType[] = [];

  for (const i in data) {
    const item = data[i]
    const children = item.children || []

    item.children = []
    uniqueChildren.push(item)

    if (children.length) {
      children.forEach(a => {
        a.children = []

        if(!uniqueChildren.some(b => b.model === a.model)){
          uniqueChildren.push(a)
        }
      })
    }
  }
  return uniqueChildren;
}