import { Payload } from "@/types/type";

export interface FinancialModelsProps {
  curSessionId: string;
  isRefreshLeft: boolean;
}
export default {
  state: {
    curSessionId:"",
    isRefreshLeft:false,
  } as FinancialModelsProps ,
  reducers: {
    setCurSessionId:(state:FinancialModelsProps,{payload}:Payload)=>{
      return {
        ...state,
        curSessionId:payload
      }
    },
    setIsRefreshLeft:(state:FinancialModelsProps,{payload}:Payload)=>{
      return {
        ...state,
        isRefreshLeft:payload
      }
    },

  }
};
