// 对话item中 对话类型
export enum MessageType {
  // 用户发起的提问
  USER_ASK = 1,

  // 机器人的回答
  ROBOT_ANSWER = 2,
}

export enum RoleType {
  // 用户
  USER = "user",
  // gpt
  ASSISTANT = "assistant",
}
