import { httpPlus } from "./request";

export const getAcademicRecentlyChat = () => {
  return httpPlus<string>("get", "/chat-academic/api/academic/latest");
};

// 用户会话记录列表
export const chatSessionList = (data: any) =>
  httpPlus<{
    total: number;
    records: any[];
  }>("post", "/chat-academic/api/academic/conversation/page-of-list", data);

// pdf解读 文件预解析
export const beforehandAnalysis = (docId: string) =>
  httpPlus("post", "/chat-academic/api/academic/beforehand/analysis", {
    docId,
  });

export interface IDoQueryRes {
  state: number;
  md5: string;
  reason: string | null;
  title: string | null;
}

// 文件解析状态轮询
export const analysisDoQuery = (ids: string[]) =>
  httpPlus<IDoQueryRes[]>("post", "/chat-academic/api/academic/do-query", {
    ids,
  });
