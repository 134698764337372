/*
 * 左侧边栏
 * */
import { Payload, RootState } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import {
  getSmallPaperUrl,
  requestAIFunctionsListV2,
  requestChatConversationCount,
  requestClearRecentlyUsed,
  requestPaintConversationCount,
  requestProductivityCancelTopping,
  requestProductivityList,
  requestProductivitySetTopping,
  requestRecentlyUsed,
  requestVipsExpiredTime,
} from "@/server/left-side-x";
import { message } from "antd";
import { getDvaApp, useSelector } from "@@/exports";
import { getNewBenifitCount } from "@/server/lb-chat-pay";
import { customModelChatCount } from "@/server/customModel";
import { chatSessionList } from "@/server/academicVip-chat";
import { PubSubType } from "@/const";
import PubSub from "pubsub-js";

const getDefaultPaintCounts = function (): NLeftSideX.IPaintingCounts {
  return {
    count: 0,
    dalle3Count: 0,
    midjourneyCount: 0,
  };
};

const getDvaStore = () => {
  return getDvaApp()._store;
};

export default {
  state: {
    // AI对话按钮列表
    chatFeatureList: [],

    // AI绘图按钮列表
    paintFeatureList: [],

    // 生产力工具按钮列表
    productivityList: [],

    academicFeatureList: [],

    jobFeatureList: [],

    paperFeatureList: [],

    // 历史对话数量
    chatConversationCount: 0,

    // 历史绘画数量
    paintConversationCount: getDefaultPaintCounts(),

    // gpts定制模型历史对话数量
    customModelChatListCount: 0,

    // 学术对话历史数量
    academicChatCount: 0,

    // 是否为团购会员
    isPurchaseVip: false,

    // 用户的会员过期信息
    vipsEndTimeList: [],

    // 最近使用的菜单列表
    recentlyUsedList: [],

    // 小微论文链接
    xiaoweilunwenLink: "",

    // 小微论文名称
    xiaoweilunwenName: "",

    // 一些弹框的显隐控制
    pops: {
      // 导出对话询问框
      showExportConversationConfirm: false,
      // 导出对话记录
      showExportConversationRecordPop: false,
      // 分享链接管理框
      showShareLinkManagerPop: false,
      // 清空对话询问框
      showClearConversationConfirm: false,
      // 邀请得会员框
      showInvitePop: false,
      // 邀请分成记录弹框
      showInviteDivideHistoryPop: false,
      // 卡券
      showCouponPop: false,
      // h5下个人中心弹框
      h5UserPop: false,
      // 个人vip权益弹框
      showVipBenefitPop: false,
    },

    // 左侧历史列表数据，仅供获取历史列表数据使用，不要做修改操作，修改这个是无效的
    histortListData: {},

    // 获取赠送权益个数
    vipBenifitData: {
      count: 0,
      hasBenefit: false,
    },
  } as NLeftSideX.IModelState,

  reducers: {
    setChatFeatureList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        chatFeatureList: payload,
      };
    },
    setAcademicFeatureList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        academicFeatureList: payload,
      };
    },
    setJobFeatureList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        jobFeatureList: payload,
      };
    },
    setPaperFeatureList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paperFeatureList: payload,
      };
    },
    setPaintFeatureList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paintFeatureList: payload,
      };
    },
    setProductivityList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        productivityList: payload,
      };
    },

    setChatConversationCount: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        chatConversationCount: payload,
      };
    },

    setPaintConversationCount: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        paintConversationCount: payload,
      };
    },

    setCustomModelChatListCount: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        customModelChatListCount: payload,
      };
    },

    setAcademicChatCount(state: NLeftSideX.IModelState, { payload }: Payload) {
      return {
        ...state,
        academicChatCount: payload,
      };
    },

    setVipsEndTimeList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        vipsEndTimeList: payload,
        isPurchaseVip: !!payload.find(
          (a: NLeftSideX.IVipsEndTimeItem) => a.type === "purchaseVip",
        ),
      };
    },

    setRecentlyUsedList: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        recentlyUsedList: payload,
      };
    },

    setXiaoweilunwenLink: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        xiaoweilunwenLink: payload,
      };
    },

    setXiaoweilunwenName: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        xiaoweilunwenName: payload,
      };
    },

    setPops: (state: NLeftSideX.IModelState, { payload }: Payload) => {
      return {
        ...state,
        pops: payload,
      };
    },

    setHistortListData: (
      state: NLeftHistoryX.THistortData,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        histortListData: payload,
      };
    },

    setVipBenifitData: (
      state: NLeftSideX.IModelState,
      { payload }: Payload,
    ) => {
      return {
        ...state,
        vipBenifitData: payload,
      };
    },
  },

  effects: {
    // 刷新所有数据
    *refreshAll(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      yield put({ type: "refreshAIFunctionsList" });

      const lbChatUserInfo = (yield select(
        (state: RootState) => state.userInfo.lbChatUserInfo,
      )) as NUser.IUserInfo;

      if (lbChatUserInfo?.id) {
        yield put({ type: "refreshConversationCount" });
        yield put({ type: "refreshVipsEndTimeList" });
        yield put({ type: "refreshRecentlyUsedList" });
      } else {
        yield put({ type: "setChatConversationCount", payload: 0 });
        yield put({ type: "setCustomModelChatListCount", payload: 0 });
        yield put({
          type: "setPaintConversationCount",
          payload: getDefaultPaintCounts(),
        });
        yield put({ type: "setVipsEndTimeList", payload: [] });
        yield put({ type: "setRecentlyUsedList", payload: [] });
      }

      // 更新小微论文链接
      const { xiaoweilunwenLink } = (yield select(
        (state: RootState) => state.leftSideX,
      )) as NLeftSideX.IModelState;

      if (!xiaoweilunwenLink) {
        const { isSuccess, data } = (yield call(
          getSmallPaperUrl,
        )) as NCommon.IHttpRes<{ url: string; name: string }>;

        if (isSuccess && data.url) {
          yield put({ type: "setXiaoweilunwenLink", payload: data.url });
          yield put({ type: "setXiaoweilunwenName", payload: data.name });
        }
      }
    },

    // 刷新历史对话数量
    *refreshConversationCount(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestChatConversationCount,
      )) as NCommon.IHttpRes<number>;

      if (isSuccess) {
        yield put({
          type: "setChatConversationCount",
          payload: data,
        });
      }

      const res2 = (yield call(
        customModelChatCount,
      )) as NCommon.IHttpRes<number>;

      if (res2.code == 0) {
        yield put({
          type: "setCustomModelChatListCount",
          payload: res2.data,
        });
      }

      const res = (yield call(
        requestPaintConversationCount,
      )) as NCommon.IHttpRes<NLeftSideX.IPaintingCounts>;

      if (res.isSuccess) {
        yield put({
          type: "setPaintConversationCount",
          payload: res.data,
        });
      }

      const chatSessionListResult = (yield call(chatSessionList, {
        current: 1,
        size: 1,
      })) as NCommon.IHttpRes<{
        total: number;
      }>;

      if (chatSessionListResult.isSuccess) {
        yield put({
          type: "setAcademicChatCount",
          payload: chatSessionListResult.data.total,
        });
      }
    },

    // 刷新AI玩法列表
    *refreshAIFunctionsList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestAIFunctionsListV2,
      )) as NCommon.IHttpRes<NLeftSideX.IAIFunctions>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setChatFeatureList",
        payload: data.chatFeatureList || [],
      });

      yield put({
        type: "setPaintFeatureList",
        payload: data.paintFeatureList || [],
      });

      yield put({
        type: "setProductivityList",
        payload: data.productivityFeatureList || [],
      });

      yield put({
        type: "setPaperFeatureList",
        payload: data.paperFeatureList || [],
      });

      yield put({
        type: "setJobFeatureList",
        payload: data.jobFeatureList || [],
      });

      yield put({
        type: "setAcademicFeatureList",
        payload: data.academicFeatureList || [],
      });
    },

    // 刷新生产力工具列表
    *refreshProductivityList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestProductivityList,
      )) as NCommon.IHttpRes<NLeftSideX.IProductivityItem[]>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setProductivityList",
        payload: data,
      });
    },

    // 刷新会员数据
    *refreshVipsEndTimeList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestVipsExpiredTime,
      )) as NCommon.IHttpRes<NLeftSideX.IVipsEndTimeItem[]>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setVipsEndTimeList",
        payload: data,
      });

      yield put({ type: "refreshVipBenifitCount" });
    },

    // 获取会员权益个数
    *refreshVipBenifitCount(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const res: any = yield getNewBenifitCount();
      if (res.code == 0) {
        yield put({
          type: "setVipBenifitData",
          payload: res.data,
        });
      }
    },

    // 刷新最近菜单列表
    *refreshRecentlyUsedList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestRecentlyUsed,
      )) as NCommon.IHttpRes<NLeftSideX.IRecentlyUsedItem[]>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setRecentlyUsedList",
        payload: data,
      });
    },

    // 刷新最近菜单列表
    *clearRecentlyUsedList(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { isSuccess } = (yield call(
        requestClearRecentlyUsed,
      )) as NCommon.IHttpRes<void>;

      if (!isSuccess) {
        return;
      }

      message.success("已清空");

      yield put({
        type: "setRecentlyUsedList",
        payload: [],
      });
    },

    // 置顶/取消置顶某个生产力工具 payload: {id: string, isTop: boolean}
    *setToppingProductivityItem(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const { id, isTop } = payload;

      const requestFun = () => {
        return (
          isTop
            ? requestProductivitySetTopping
            : requestProductivityCancelTopping
        )(id);
      };

      const { isSuccess, data } = (yield call(
        requestFun,
      )) as NCommon.IHttpRes<void>;

      if (!isSuccess) {
        return false;
      }

      const {
        chatFeatureList = [],
        paintFeatureList = [],
        productivityList = [],
        academicFeatureList = [],
        jobFeatureList = [],
        paperFeatureList = [],
      } = (yield select(
        (state: RootState) => state.leftSideX,
      )) as NLeftSideX.IModelState;

      const item = [
        ...chatFeatureList,
        ...paintFeatureList,
        ...productivityList,
        ...academicFeatureList,
        ...jobFeatureList,
        ...paperFeatureList,
      ].find((a) => a.id === id);

      if (!item) {
        return false;
      }

      // 成功的清空，已经在通过组件的形式出来了
      if (!isTop) {
        message.success("已取消置顶");
      }

      item.userPinned = !!isTop;

      yield put({
        type: "setChatFeatureList",
        payload: [...chatFeatureList],
      });

      yield put({
        type: "setPaintFeatureList",
        payload: [...paintFeatureList],
      });

      yield put({
        type: "setProductivityList",
        payload: [...productivityList],
      });

      yield put({
        type: "setPaperFeatureList",
        payload: paperFeatureList || [],
      });

      yield put({
        type: "setJobFeatureList",
        payload: jobFeatureList || [],
      });

      yield put({
        type: "setAcademicFeatureList",
        payload: academicFeatureList || [],
      });

      // 通知首页-更新全部模块
      setTimeout(() => {
        PubSub && PubSub.publish(PubSubType.REFRESH_HOME_PAGE_ALL_CONTENT);
      }, 100);

      return true;
    },

    // 设置某一个弹框
    *setPop(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap,
    ): Generator {
      const pops = (yield select(
        (state: RootState) => state.leftSideX.pops,
      )) as NLeftSideX.IModelStatePop;

      yield put({
        type: "setPops",
        payload: { ...pops, ...payload },
      });
    },
  },
};
