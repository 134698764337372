/**
 *
 * 桌面端的一些处理
 * */
if (window.require) {
  const {ipcRenderer} = window.require("electron");

  ipcRenderer.on("getVersion", (event: any, arg: string) => {
    localStorage.setItem("deskVersion", arg);
  });

  ipcRenderer.send("getVersion");
}