import { Payload } from "@/types/type";

export interface RefuelBagPay {
  hasRebuelBagPayPop: boolean;
  rebuelBagPayEntrance: string;
}

export default {
  state: {
    // 是否打开加油包弹窗: boolean;
    hasRebuelBagPayPop: false,
    // 加油包点击入口   chat 对话   paint 绘画
    rebuelBagPayEntrance: "",
  } as RefuelBagPay,
  reducers: {
    setHasRebuelBagPayPop: (state: RefuelBagPay, { payload }: Payload) => {
      return {
        ...state,
        hasRebuelBagPayPop: payload,
      };
    },

    setRebuelBagPayEntrance: (state: RefuelBagPay, { payload }: Payload) => {
      return {
        ...state,
        rebuelBagPayEntrance: payload,
      };
    },
  },
  effects: {},
};
