import { resolutionDataTs } from "@/pages/newHome/components/paint-dalle-textarea/paint-dalle-textarea-type";
import { Payload } from "@/types/type";

export interface PaintDalle {
  refreshLeftSidebar: boolean;
  conversationConfigId: string;
  dalleCurrentHasGenerate: boolean;
  dalleTextareaTxt: string;
  dalleCurrentPaintItem: object;
  dalleHistoryFirstId: string;
  logoTemplateInfo: {
    templatePrompt: string;
    img: string;
    originOssKey: string;
  };
  logoTemporaryStorage: {
    logoRequire: string;
    logoContent: string;
    selectLogoType: number;
  };
  resolutionItem: resolutionDataTs | null;
  configSettingId: string;
}

export default {
  state: {
    // 是否刷新左侧边栏
    refreshLeftSidebar: false,
    // 出图设置id
    conversationConfigId: "",
    // 当前最新的消息是否已经生成图片
    dalleCurrentHasGenerate: false,
    // dalle文本框内容
    dalleTextareaTxt: "",
    // dalle当前选中的绘画列表对象
    dalleCurrentPaintItem: {},
    // dalle历史第一条的id
    dalleHistoryFirstId: "",
    //模板信息
    logoTemplateInfo: {
      templatePrompt: "",
      img: "",
      originOssKey: "",
    },
    //临时存储
    logoTemporaryStorage: {
      logoRequire: "",
      logoContent: "",
      selectLogoType: 0,
    },
    resolutionItem: null,
    configSettingId: "",
  } as PaintDalle,
  reducers: {
    setRefreshLeftSidebar: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        refreshLeftSidebar: payload,
      };
    },
    setResolutionItem: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        resolutionItem: payload,
      };
    },
    setConfigSettingId: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        configSettingId: payload,
      };
    },
    setConversationConfigId: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        conversationConfigId: payload,
      };
    },

    setDalleCurrentHasGenerate: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        dalleCurrentHasGenerate: payload,
      };
    },

    setDalleTextareaTxt: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        dalleTextareaTxt: payload,
      };
    },

    setDalleCurrentPaintItem: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        dalleCurrentPaintItem: payload,
      };
    },

    setDalleHistoryFirstId: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        dalleHistoryFirstId: payload,
      };
    },
    setLogoTemplateInfo: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        logoTemplateInfo: payload,
      };
    },
    setLogoTemporaryStorage: (state: PaintDalle, { payload }: Payload) => {
      return {
        ...state,
        logoTemporaryStorage: payload,
      };
    },
  },
  effects: {},
};
