// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/academicQA';
import model_2 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/academicSearch';
import model_3 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/academicVip';
import model_4 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/aiScholar';
import model_5 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatCollect';
import model_6 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatDataAnalysis';
import model_7 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatDisk';
import model_8 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatFunction';
import model_9 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatInput';
import model_10 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatMessage';
import model_11 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatModalControl';
import model_12 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatOptimizeQuestion';
import model_13 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatParameter';
import model_14 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatPay';
import model_15 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatReading';
import model_16 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatRolePlay';
import model_17 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/chatShare';
import model_18 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/common';
import model_19 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/commonModal';
import model_20 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/commonPops';
import model_21 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/copywriting';
import model_22 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/customChatMessage';
import model_23 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/customModel';
import model_24 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/financial';
import model_25 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/groupVip';
import model_26 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/leftSideX';
import model_27 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/paintDalle';
import model_28 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/paintingParameter';
import model_29 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/refuelBagPay';
import model_30 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/site';
import model_31 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/translate';
import model_32 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/userInfo';
import model_33 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/userParameter';
import model_34 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/useUploadFiles';
import model_35 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/vip';
import model_36 from '/data/jenkins/workspace/test-lb-kyj-open-ui/src/models/wholeSpin';

export const models = {
model_1: { namespace: 'academicQA', model: model_1 },
model_2: { namespace: 'academicSearch', model: model_2 },
model_3: { namespace: 'academicVip', model: model_3 },
model_4: { namespace: 'aiScholar', model: model_4 },
model_5: { namespace: 'chatCollect', model: model_5 },
model_6: { namespace: 'chatDataAnalysis', model: model_6 },
model_7: { namespace: 'chatDisk', model: model_7 },
model_8: { namespace: 'chatFunction', model: model_8 },
model_9: { namespace: 'chatInput', model: model_9 },
model_10: { namespace: 'chatMessage', model: model_10 },
model_11: { namespace: 'chatModalControl', model: model_11 },
model_12: { namespace: 'chatOptimizeQuestion', model: model_12 },
model_13: { namespace: 'chatParameter', model: model_13 },
model_14: { namespace: 'chatPay', model: model_14 },
model_15: { namespace: 'chatReading', model: model_15 },
model_16: { namespace: 'chatRolePlay', model: model_16 },
model_17: { namespace: 'chatShare', model: model_17 },
model_18: { namespace: 'common', model: model_18 },
model_19: { namespace: 'commonModal', model: model_19 },
model_20: { namespace: 'commonPops', model: model_20 },
model_21: { namespace: 'copywriting', model: model_21 },
model_22: { namespace: 'customChatMessage', model: model_22 },
model_23: { namespace: 'customModel', model: model_23 },
model_24: { namespace: 'financial', model: model_24 },
model_25: { namespace: 'groupVip', model: model_25 },
model_26: { namespace: 'leftSideX', model: model_26 },
model_27: { namespace: 'paintDalle', model: model_27 },
model_28: { namespace: 'paintingParameter', model: model_28 },
model_29: { namespace: 'refuelBagPay', model: model_29 },
model_30: { namespace: 'site', model: model_30 },
model_31: { namespace: 'translate', model: model_31 },
model_32: { namespace: 'userInfo', model: model_32 },
model_33: { namespace: 'userParameter', model: model_33 },
model_34: { namespace: 'useUploadFiles', model: model_34 },
model_35: { namespace: 'vip', model: model_35 },
model_36: { namespace: 'wholeSpin', model: model_36 },
} as const
