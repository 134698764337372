import { PaginationType } from "@/components/vip-pay-history/types";
import { getPromoteUser, isDesk } from "@/utils/utils";
import http, { catch_http, httpPlus } from "./request";
import { PayTypeEnum } from "./types/lb-chat-pay";

// 支付套餐配置
export const payConfigList = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/pay-config/pay/list`
  );

// 支付获取二维码
export const paymentQrcode = (data: object) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/qrcode`,
    data,
    {
      headers: getPromoteUser(),
    }
  );

// 获取订单支付状态
export const paymentState = (sign?: string) =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/state/` + sign
  );

// 用户充值信息
export const payUserInfo = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/pay-config/user/pay`
  );

// 用户充值记录
export const payUserPageList = (data: object) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/user-recharge/page-of-list`,
    data
  );

// 用户开通各种会员记录
export const userOpenAllVipRecords = (data: object) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/user-recharge/member-all`,
    data
  );

// 学术版 用户充值记录
export const requestAcademicVipOrderList = (data: object) =>
  catch_http("post", `/chat-academic/api/academic-vip/order-page`, data);

// 微信支付
export const wxChatPrePay = (data: object) =>
  catch_http(
    "post",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/pre-pay`,
    data
  );

export const getPayPaintingList = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/pay-config/pay/pro/list`
  );

// 移动端微信支付开关
export const wxPayEnable = () =>
  catch_http(
    "get",
    `/chat-server${isDesk() ? "/desk" : ""}/payment/get-enable-pay-state`
  );

// 轮询获取拼团进度
export const getGroupProcess = () =>
  catch_http("get", "/chat-server/api/group/pay-group/get-process");

// 获取拼团支付项列表
export const getGroupPayList = () =>
  catch_http("get", "/chat-server/api/group/pay-group/all-list");

export type GroupPricePropsType = {
  id: string;
  payConfigId: string;
};
// 获取拼团价格
export const getGroupPrice = (data: GroupPricePropsType) =>
  catch_http("post", "/chat-server/api/group/pay-group/discount-price", data);

// 根据key获取分享团购状态
export const getGroupShareByKey = (groupKey: string) =>
  catch_http("get", `/chat-server/api/group/pay-group/invite/${groupKey}`);

// 获取拼团记录
export const groupPageList = (params: PaginationType) =>
  http("post", "/chat-server/api/group/pay-group/page-list", params);

// 轮询拼团成功的记录
export const groupSuccessList = () =>
  catch_http("get", "/chat-server/api/group/pay-group/success-list");

// 获取拼团规则
export const getGroupRule = () =>
  catch_http("get", "/chat-server/api/group/pay-group/group-rules");

// 重新拼团
export const groupReGroup = (groupOrderId?: string) =>
  catch_http("get", `/chat-server/api/group/pay-group/restart/${groupOrderId}`);

// 更新拼团成功弹窗状态
export const updatePopup = (groupOrderId?: string) =>
  catch_http(
    "get",
    `/chat-server/api/group/pay-group/update-popup/${groupOrderId}`
  );

// 获取是否隐藏拼团接口
export const hiddenGroup = () =>
  catch_http("get", `/chat-server/api/group/pay-group/hidden`);

// 获取用书支付数据
export const payConfigUser = (type: any | number | PayTypeEnum) =>
  catch_http(
    "post",
    `/chat-server${
      isDesk() ? "/desk" : ""
    }/payment/pay-config/new-user-pay-info`,
    {
      payConfigType: type,
    }
  );

// 获取支付成功之后显示的二维码
export const paySuccessCode = () =>
  catch_http("post", "/chat-server/api/dictionary/get-by-key", {
    key: "payFinishShowCode",
  });

// 是否显示升级svip按钮
export const svipBtnEnabled = () =>
  catch_http("get", "/chat-server/api/user-update-recharge/vip/enabled");

// vip有效记录列表
export const vipEnabledList = () =>
  catch_http("get", "/chat-server/api/user-update-recharge/vip/enabled/list");

// 可升级的SVIP列表
export const svipConfigList = (params: object) =>
  catch_http("post", "/chat-server/api/user-update-recharge/svip/list", params);

// 新用户升级福利
export const newUpdateBonus = () =>
  catch_http(
    "get",
    "/chat-server/api/user-update-recharge/new-update-pay-info"
  );

// 使用卡券支付
export const payByCard = (params: {
  couponId: string | undefined;
  businessType: string;
  sign: string;
  targetType: string;
}) => catch_http("post", "/chat-server/payment/choose-coupon", params);

// 取消卡券绑定
export const cancelCouponApi = (params: {
  businessType: string;
  couponId: string;
  sign: string;
  targetType: string;
}) => catch_http("post", "/chat-server/payment/choose-coupon-cancel", params);


// 新用户赠送会员支付成功后回调提示
export const newBenifitPaySuccessTip = () =>
  catch_http(
    "get",
    `/chat-server/${
      isDesk() ? "desk" : "api"
    }/new-user-benifit/paySuccessTip`
  );

// 查询赠送权益个数
export const getNewBenifitCount = () =>
  catch_http(
    "get",
    `/chat-server/${
      isDesk() ? "desk" : "api"
    }/new-user-benifit/count`
  );
