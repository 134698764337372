import { getSwitchDomain } from "@/server/checkDomainAvailability";

// 发布环境
export const UMI_ENV = process.env.UMI_ENV;
// console.log('UMI_ENV------------', UMI_ENV)

// 是否为生产环境
export const IS_PROD = UMI_ENV === "p";

export const IS_TEST = UMI_ENV === "t";

// 移动端域名前缀
export const MOBILE_PREFIX = "/m/";

// 是否为本地开发环境
export const IS_DEV = !IS_PROD && !IS_TEST;

// 是否为桌面端环境
export const IS_DESK = process.env.UMI_PLATFORM === "desk";

// 本地开发时的api-host, 开发对接不同的服务端地址 请改这里 (只在本地开发时生效)
// const DEV_API_HOST = "https://dev-chat.checkmore.net";
const DEV_API_HOST = "https://test-chat.checkmore.net";

const switchDomain = getSwitchDomain();

export const DESK_SKIP_HOST = IS_PROD
  ? "https://www.chatgptok.com"
  : DEV_API_HOST;

export const SITE_HOST = location.origin;

// 请求的域名，编译后会根据浏览器当前访问的域名来
export const API_HOST = (function () {
  if (switchDomain) {
    return switchDomain;
  }

  if (IS_DESK) {
    return IS_PROD ? "https://api.xueshuweishi.com" : DEV_API_HOST;
  }

  if (IS_DEV) {
    return DEV_API_HOST;
  }

  // 学术卫士 模式
  const sps = location.host.split(".");
  const lastHost = sps.slice(-2).join(".");

  //  if(['test-chat.checkmore.net'].includes(location.host)){
  //   return  location.protocol + '//'+'dev3-chat.checkmore.net'
  // }

  if (location.host === "wisdom.chatleft.com") {
    return location.origin;
  }

  if (
    ["chatleft.com", "chatthree.com", "chatyy.org", "chatgptok.com"].includes(
      lastHost,
    )
  ) {
    // if(['chatgptok.com'].includes(lastHost)){
    return (
      location.protocol +
      "//" +
      [...sps.slice(0, sps.length - 2), "xueshuweishi.com"].join(".")
    );
  }

  return location.origin;
})();

// 本地开发时的 跳转到的学术pdf域名  (只在本地开发时生效)
const DEV_ACADEMIC_WEB_HOST = "https://dev-chat.checkmore.net";

// 学术Vp网站域名
export const ACADEMIC_VP_HOST = IS_DESK
  ? IS_PROD
    ? "https://www.chatgptok.com"
    : DEV_ACADEMIC_WEB_HOST
  : IS_DEV
  ? "http://localhost:5173"
  : location.origin;
declare global {
  interface Window {
    defaultModel: any; // 根据实际类型替换 `any`
  }
}
window.defaultModel = {
  description: "使用最多的模型，能够进行广泛领域的自然对话和问题解答",
  model: "gpt-4o-mini",
  modelCategory: "GPT4o-mini",
  name: "GPT4o-mini",
  showName: "Chat4o-mini",
};
