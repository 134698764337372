import { AxiosRequestConfig } from "axios";
import http, { catch_http } from "./request";
import { httpPlus } from "./request";

// 首页定制模型分类
export const requestCustomModelTypeList = () => {
  return httpPlus<NCustomModel.ICustomModelType[]>(
    "get",
    `/chat-server/api/customized-model-record/home/type-list`,
  );
};

// 首页定制模型列表
export const requestCustomModelList = (params: {
  current: number;
  size: number;
  typeConfigId: number | string;
  word: string;
}, config?: AxiosRequestConfig) => {
  return httpPlus<NCustomModel.ICustomModelListItem[]>(
    "post",
    `/chat-server/api/customized-model-record/page-of-list`,
    params,
    config
  );
};

// 最近使用列表
export const requestRecentList = () => {
  return httpPlus<any>(
    "get",
    "/chat-server/api/customized-model-record/recent/list",
  );
};

// 清空最近使用
export const requestDeleteRecent = () => {
  return httpPlus(
    "get",
    "/chat-server/api/customized-model-record/delete-recent",
  );
};

// 删除模型
export const requestDeleteModel = (id: string) => {
  return httpPlus("post", "/chat-server/api/customized-model-record/delete", {
    id,
  });
};

// 取消发布
export const requestUnpublish = (modelRecordId: string) => {
  return httpPlus("post", "/chat-server/api/customized-model-record/unpublish", {
    modelRecordId,
  });
}

// 收藏模型
export const requestAddCollect = (modelRecordId: string) => {
  return httpPlus(
    "post",
    "/chat-server/api/customized-model-record/add-collect",
    {
      modelRecordId,
    },
  );
};

// 取消收藏
export const requestDeleteCollect = (modelRecordId: string) => {
  return httpPlus(
    "post",
    "/chat-server/api/customized-model-record/delete-collect",
    {
      modelRecordId,
    },
  );
};

// 申请公开
export const requestAddDisplay = (modelRecordId: string) => {
  return httpPlus(
    "post",
    "/chat-server/api/customized-model-record/add-display",
    {
      modelRecordId,
    },
  );
};

// 取消公开
export const requestDeleteDisplay = (modelRecordId: string) => {
  return httpPlus(
    "post",
    "/chat-server/api/customized-model-record/delete-display",
    {
      modelRecordId,
    },
  );
};

// 我的模型数量
export const requestCustomModelCount = () => {
  return httpPlus<{
    limitCount: number;
    useCount: number;
  }>("get", "/chat-server/api/customized-model-record/model-count");
};

// 模板类型下拉框内容
export const requestGetModelRecordList = () =>
  http<{ name: string; id: string }[]>(
    "get",
    "/chat-server/api/customized-model-record/edit/type-list",
  );

/**
 * 消息历史记录
 */
export const getCustomizedChatHistoryList = (params: {
  current: number;
  size: number;
  sessionId: string;
}, hide = true) =>
  http<NCustomModel.ICustomModelChatMessage>("post", "/chat-server/api/customized/chat/list-of-history", params, {}, hide);
/**
 * 模型头像图标列表
 */
export const getCustomizedAvatarList = () =>
  http<NCustomModel.TAvatarList>(
    "get",
    "/chat-server/api/customized-model-record/icon-list",
  );
/**
 * 模型分类
 */
export const getCustomizedModeTypes = () =>
  http<Array<{ name: string; id: string }>>(
    "get",
    "/chat-server/api/customized-model-record/edit/type-list",
  );
/**
 * 模型分类
 */
export const getCustomizedModeTip = () =>
  http<NCustomModel.IFormPlaceholderConfig>(
    "get",
    "/chat-server/api/customized-model-record/model-default-tip",
  );
/**
 * 获取文件上传上限
 */
export const getCustomizedFileNumLimit = () =>
  http<number>(
    "get",
    "/chat-server/api/customized-model-file-data/fileNum-limit",
  );
/**
 * 保存模型，新增或修改操作
 */
export const saveCustomizedMode = (data: {
  avatarPath: string;
  exampleQuestionList: string[];
  id?: string;
  introduction: string;
  modelInstructions: string;
  name: string;
  prologue: string;
  typeConfigId: number;
  fileDataIds?: string[];
}) =>
  http<NCustomModel.ICreateInfo>(
    "post",
    "/chat-server/api/customized-model-record/save",
    data,
    {},
    false
  );
/**
 * 发布模型
 */
export const publishCustomizedMode = (data: { modelRecordId: string, openFlag?: number }) =>
  http<any>("post", "/chat-server/api/customized-model-record/publish", data);

/**
 * 清空角色扮演
 */
export const clearCustomizedChat = () =>
  http<any>('post', "/chat-server/api/user/conversation-record/clean-role-play-conversations");


/**
 * 获取上传文件列表
 */
export const getCustomizedFileList = (data: {
  current: number;
  size: number;
  modelRecordId?: string;
}) =>
  http<NCommon.RecordResponse<NCustomModel.IFileListItem>>(
    "post",
    "/chat-server/api/customized-model-file-data/list",
    data,
  );
/**
 * 获取文件上传签名
 */
export const getCustomizedUploadSign = (data: { fileSuffix: string, modelRecordId: string }) =>
  http<NCommon.RecordResponse<NCustomModel.IFileListItem>>(
    "post",
    "/chat-server/api/customized-model-file-data/upload/sign",
    data,
    {},
    false
  );
/**
 * 文件上传
 */
export const uploadCustomizedFile = (data: NCustomModel.IUploadFileQuery) =>
  http<{
    failCount: number,
    failReasonList: string[],
    successCount: number,
  }>(
    "post",
    "/chat-server/api/customized-model-file-data/upload",
    data,
    {},
    false
  );

// 获取用户有效对话数量
export const customModelChatCount = () =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/chat/count",
  );

// 获取左侧历史对话列表
export const customModelHistoryList = (params: object) =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/page-of-list",
    params,
  );

// 定制模型用户会话记录修改
export const customModelHistoryItemUpdate = (id: string, data: object) =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/update/" + id,
    data,
  );

// 定制模型用户会话记录删除
export const customModelHistoryItemDel = (id: string) =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/delete/" + id,
  );
  // 定制模型记录置顶
export const pinCustomModelHistory = (id: string) =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/chat/pinned", {id}
  );
  // 定制模型记录取消置顶
export const cancelPinCustomModelHistory = (id: string) =>
  catch_http(
    "post",
    "/chat-server/api/user/custom-conversation-record/chat/cancel-pinned",{id}
  );

// 根据网盘文件获取上传信息
export const getNetdiskFileInfo = (id: string) =>
  http<NNetDisk.IChatSelectFileDetail>(
    "get",
    "/chat-server/api/customized-model-file-data/diskFile-select",
    { id },
  );

// 删除文件
export const deleteCustomizedFile = (id: string) =>
  http<NNetDisk.IChatSelectFileDetail>(
    "post",
    "/chat-server/api/customized-model-file-data/delete",
    { id },
  );
// 获取详情
export const getCustomizedDetail = (data: {
  modelRecordId: string
}) =>
  http<NCustomModel.ICreateInfo>(
    "post",
    "/chat-server/api/customized-model-record/detail",
    data,
  );
// 获取sessionId
export const getCustomSessionId = (data: {
  modelRecordId: string
}) =>
  http<NCustomModel.ISessionInfo>(
    "post",
    "/chat-server/api/customized/chat/create/by/model-id",
    data,
  );
// 获取测试sessionId
export const getCustomTestSessionId = (data: {
  modelRecordId: string
}) =>
  http<NCustomModel.ISessionInfo>(
    "get",
    "/chat-server/api/user/custom-conversation-record/query-sessionId",
    data,
  );
// 模型是否可用
export const checkCustomModelIsValible = (data: {
  modelRecordId: string
}) =>
  http<boolean>(
    "post",
    "/chat-server/api/customized-model-record/check-is-enable",
    data,
  );
// 获取测试sessionId
export const getCustomLimitCount = () =>
  http<NCustomModel.IChatModelLimitConfig>(
    "get",
    "/chat-server/api/customized-model-record/model-count",
  );
// 添加定制模型对话记录收藏
export const collectCustomChat = (data: {
  aiAskMessageId: string,
  aiMessageId: string,
  gptModel: string,
  sessionId: string,
  targetType: string
}) =>
  http<any>(
    "post",
    "/chat-server/api/conversation/collect/custom/add",
    data
  );
// 添加定制模型对话记录收藏
export const cancleCollectCustomChat = (data: {
  aiMessageId: string,
  targetType: string
}) =>
  http<any>(
    "post",
    `/chat-server/api/conversation/collect/cancel`,
    data
  );
// 添加定制模型对话记录收藏
export const getCustomLastMsg = (data: {
  sessionId: string
}) =>
  http<NCustomModel.IChatLastMessageItem[]>(
    "post",
    `/chat-server/api/customized/chat/latest-msg`,
    data
  );
// 添加定制模型对话记录收藏
export const getCustomFileParseStatus = (data: {
  modelFileDataIds: string[]
}) =>
  http<NCustomModel.IFileListItem[]>(
    "post",
    `/chat-server/api/customized-model-file-data/poll-file-parse-status`,
    data
  );
