import { useSyncExternalStore } from 'react'


export function createSimpleExternalStore<
  T,
  A extends {
    [key: string]: any[]
  },
  A2 = {
    [Key in keyof A]: (state:T, ...args: A[Key]) => Partial<T> | void | Promise<Partial<T>> | Promise<void>
  },
  R = {
    [Key in keyof A]: (...args: A[Key]) => T | Promise<T>
  }
>(initState: T, actions: A2) {
  let listeners: (() => void)[] = []
  let state = initState

  function getSnapshot() {
    return state
  }

  function subscribe(listener:(() => void)) {
    listeners = [...listeners, listener]

    return function () {
      listeners = listeners.filter(l => l !== listener)
    }
  }

  function setState(val?: Partial<T>) {
    if (val === void 0 || val === state) {
      return
    }

    state = {
      ...state,
      ...val
    }

    for (let listener of listeners) {
      listener()
    }
  }

  return function (): T & R & { getState: () => T }{
    const _actions = Object.keys(actions as object).reduce((prev: Partial<R> , cur: keyof A) => {
      const fn = actions[cur as keyof A2] as (state:T, ...args: any) => Partial<T> | undefined | Promise<Partial<T>> | Promise<undefined>

      Object.assign(prev, {
        [cur](...args: any[]) {
          const result = fn(state, ...args)

          if (result instanceof Promise) {
            return result.then(data => {
              setState(data)
              return Promise.resolve(state)
            })
          } else {
            setState(result)
            return state
          }
        }
      })

      return prev
    }, {}) as R

    useSyncExternalStore(subscribe, getSnapshot)

    return {
      ...state,
      getState: getSnapshot,
      ..._actions
    }
  }
}