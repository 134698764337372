/*
 * 通用询问组件
 * */
import {Payload, RootState} from "@/types/type";

const getDefaultConfig: NCommon.ICommonModalModelState = function (config?: Partial<NCommon.ICommonModalModelState>) {
  return {
    // 是否展示
    isShow: false,

    // 主标题
    mainTitle: '提示',
    // 询问内容
    content: '你好吗',
    // 副询问内容
    subContent: '',
    // 不展示关闭按钮
    noCloseButton: false,
    // 不展示取消按钮
    noCancelButton: false,
    // 取消按钮文案
    cancelButtonText: '取 消',
    // 确认按钮文案
    sureButtonText: '确 定',
    // 点击回调
    onClick: null,

    ...config
  }
}

export default {
  state: getDefaultConfig(),

  reducers: {

    update: (
      state: NCommon.ICommonModalModelState,
      {payload}: Payload
    ) => {
      return {
        ...state,
        ...payload,
      };
    },

    create: (
      state: NCommon.ICommonModalModelState,
      {payload}: Payload
    ) => {
      return {
        ...state,
        ...getDefaultConfig(payload),
        isShow: true,
      };
    },

  },

  effects: {},
};
