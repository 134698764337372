import { PayStatusEnum } from "@/components/chat-pay/types";
import { Payload } from "@/types/type";

export interface AcademicSearch {
  // 是否打开vip提示弹窗
  hasVipTipsVisible: boolean;

  // vip提示弹窗内容;
  VipTipsContent: string | HTMLElement;

  // 是否显示vip提示中的小提示;
  vipTipsDesc: string;

  // vip提示中的小提示文案;
  vipSubTxt: string;
}

export default {
  state: {
    hasVipTipsVisible: false,
    VipTipsContent: "",
    vipTipsDesc: "",
    vipSubTxt: "",
  } as AcademicSearch,
  reducers: {
    setHasVipTipsVisible: (state: AcademicSearch, { payload }: Payload) => {
      return {
        ...state,
        hasVipTipsVisible: payload,
      };
    },

    setVipTipsContent: (state: AcademicSearch, { payload }: Payload) => {
      return {
        ...state,
        VipTipsContent: payload,
      };
    },

    setVipTipsDesc: (state: AcademicSearch, { payload }: Payload) => {
      return {
        ...state,
        vipTipsDesc: payload,
      };
    },

    setVipSubTxt: (state: AcademicSearch, { payload }: Payload) => {
      return {
        ...state,
        vipSubTxt: payload,
      };
    },

    setCancelClose: (state: AcademicSearch, { payload }: Payload) => {
      return {
        ...state,
        hasVipTipsVisible: false,
        VipTipsContent: "",
        vipTipsDesc: "",
        vipSubTxt: "",
      };
    },
  },
  effects: {},
};
