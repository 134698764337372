import { PayStatusEnum } from "@/components/chat-pay/types";
import { Payload } from "@/types/type";
import { getUserCouponCount } from "@/server/chat-coupon-service";
import { history } from 'umi';
import {showVipPayPop} from "@/utils";

type TPayHistoryPopDataType = '' | 'chatVip' | 'academicVip'

export interface ChatPay {
  hasPayPop?: boolean;
  hasPaySuccessPop?: boolean;
  hasPayHistoryPop?: boolean;
  payHistoryPopDataType: TPayHistoryPopDataType
  hasCustomerPop?: boolean;
  payStatus?: PayStatusEnum;
  disablePolling?: boolean;
  serviceCode: string;
  groupInfo?: {
    id: string; //团购id
    title: string; //团购人数content
    discount: number; //折扣
    discountNum: number; //几折
    description: string; //折扣content
    groupNumber: string; //团购需要的总人数
    enabled: boolean; //
    sortNo: number; //
  };
  inviteInfo?: {
    id: string;
    groupConfigId: string; //小类下单个团购id
    groupStatus: number;
    userId: string;
    userName: string;
    discount: number;
    discountNum: number;
    groupNumber: number;
    shareLink: string;
    payGroupOrderSuccessMsgs: string[];
    hideGroup: boolean;
    defaultPayTab: number;
  };
  paySuccessMsg?: string;
}

export default {
  state: {
    // 是否打开支付弹窗
    hasPayPop: false,
    // 是否打开支付成功弹窗
    hasPaySuccessPop: false,

    // 是否打开支付记录弹窗
    hasPayHistoryPop: false,
    // 支付记录的类型
    payHistoryPopDataType: '',

    // 是否打开客服弹窗
    hasCustomerPop: false,
    // 拼团状态
    payStatus: PayStatusEnum.START, // start: 开始支付, fail: 支付失败, invite: 邀请支付, group: 拼团支付
    // 当前是否禁用查询拼团状态
    disablePolling: false,
    // 当前选择团购信息
    groupInfo: {} as any,
    //邀请进来的信息
    inviteInfo: {} as any,
    // 是否隐藏拼团接口
    hideGroup: true,
    serviceCode: "",
    paySuccessMsg: "恭喜您成为尊贵会员!", //支付成功文案
    // 初始选中tab
    defaultPayTab: null,
    // 初始选中的套餐id
    defaultPayConfigId: "",
    // 用户卡券数量
    userCouponCount: 0,
    // 弹起卡券气泡
    isPopCoupon: false,
    // 选中的卡券id
    selectedCouponId: "",

    // 当打开chatVip弹框时，指定当前选中哪个case
    chatDefaultCasePayType: ''
  } as ChatPay,
  reducers: {
    setHasPayPop: (state: ChatPay, { payload }: Payload) => {
      if(!location.pathname.includes('/vip-pay') && payload === true){
        // 直接跳链接
        setTimeout(showVipPayPop, 0)

        return {
          ...state
        }
      }

      return {
        ...state,
        hasPayPop: payload,
      };
    },

    // 打开vip弹框，并指定默认打开哪个case（月度、季度、年度）
    openChatPayPop: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        hasPayPop: true,
        chatDefaultCasePayType: payload || '',
        defaultPayTab: 0
      };
    },

    clearChatDefaultCasePayType: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        chatDefaultCasePayType: '',
        defaultPayTab: null
      };
    },

    setHasPaySuccessPop: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        hasPaySuccessPop: payload,
      };
    },
    setHasPayHistoryPop: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        hasPayHistoryPop: payload,
        payHistoryPopDataType: 'chatVip'
      };
    },

    // 打开/关闭支付记录弹框
    setPayHistoryPop: (state: ChatPay, { payload }: Payload) => {
      const {isShow, type} = payload as {isShow: boolean, type?: TPayHistoryPopDataType}

      return {
        ...state,
        hasPayHistoryPop: isShow,
        payHistoryPopDataType: type || ''
      };
    },

    setHasCustomerPop: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        hasCustomerPop: payload,
      };
    },
    setPayStatus: (state: ChatPay, { payload }: Payload) => {
      console.log("setPayStatus=", payload);
      return {
        ...state,
        payStatus: payload,
      };
    },
    setDisablePolling: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        disablePolling: payload,
      };
    },
    setGroupInfo: (state: ChatPay, { payload }: Payload) => {
      console.log("payload", payload);
      return {
        ...state,
        groupInfo: payload,
      };
    },
    setInviteInfo: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        inviteInfo: payload,
      };
    },
    setHideGroup: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        hideGroup: payload,
      };
    },
    setServiceCode: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        serviceCode: payload,
      };
    },
    setPaySuccessMsg: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        paySuccessMsg: payload,
      };
    },
    setDefaultPayTab: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        defaultPayTab: payload,
      };
    },
    setDefaultPayConfigId: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        defaultPayConfigId: payload,
      };
    },
    setCouponCount: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        userCouponCount: payload,
      };
    },
    setIsPopCoupon: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        isPopCoupon: payload,
      };
    },
    setSelectedCouponId: (state: ChatPay, { payload }: Payload) => {
      return {
        ...state,
        selectedCouponId: payload,
      };
    },
  },
  effects: {
    *getCouponCount({ payload }: Payload, { put }: any): Generator {
      const res: any = yield getUserCouponCount();
      if (res.code == 0) {
        yield put({
          type: "setCouponCount",
          payload: res.data.canUseCount,
        });
      }
    },
  },
};
