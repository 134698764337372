import claudePng from "@/assets/images/claude.png";
import gpt3_5Png from "@/assets/images/answer.png";
import gpt4_0Png from "@/assets/images/gpt4.png";
import tyqwPng from "@/assets/images/tyqw.png";
import wxyyPng from "@/assets/images/wxyy.png";
import zpaiPng from "@/assets/images/zpai.png";
import geminiPng from "@/assets/images/gemini.png";
import mistralLarge from "@/assets/images/mistralLarge.png";
import kimiPng from "@/assets/images/kimi.png";
import doubaoPng from "@/assets/images/doubao.png";
import o1miniPng from "@/assets/images/o1-mini.png";
import o1previewPng from "@/assets/images/o1-preview.png";

export const avatarMap = {
  [window.defaultModel.name]: gpt3_5Png,
  "GPT-4": gpt4_0Png,
  "GPT-4-Turbo": gpt4_0Png,
  wenxinyiyan: wxyyPng,
  tongyiqianwen: tyqwPng,
  "zhipu-AI": zpaiPng,
  Claude: claudePng,
  Gemini: geminiPng,
  "Mistral Large": mistralLarge,
  kimi: kimiPng,
  Doubao: doubaoPng,
  "o1-mini": o1miniPng,
  "o1-preview": o1previewPng,
};
