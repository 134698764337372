import { catch_http } from "./request";

export interface ShareDetailType {
  exampleUrlH5: string;
  exampleUrlPc: string;
  requireText: any;
  // 状态 -1-未通过 0-待领取 1-待审核 2
  state: number;
  vipBenefitDayText: string;
  vipBenefits: {
    fileBenefit: string;
    gptModel: string;
  };
  wechatUrl: string;
  receiveDay: number;
  effectiveTime: string;
  shareImgUrl: string;
}
// 获取分享朋友圈详情
export function getShareDetail() {
  return catch_http(
    "get",
    "/chat-server/api/wechat-share/vip/detail"
  ) as Promise<{
    data: ShareDetailType;
    code: number;
  }>;
}

// 上传截图-获取oss
export function getOssToken(fileSuffix: string) {
  return catch_http(
    "get",
    "/chat-server/api/wechat-share/vip/oss-info?fileSuffix=" + fileSuffix
  );
}

// 提交截图
export function submitImg(ossKey: string) {
  return catch_http("post", "chat-server/api/wechat-share/vip/submit", {
    ossKey,
  });
}

// 使用使用过
export function useShare() {
  return catch_http("get", "/chat-server/api/wechat-share/vip/is-used");
}
