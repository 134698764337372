// 消息订阅事件类型
export enum PubSubType {
  // 刷新网盘列表
  REFRESH_NET_DIST_LIST = "REFRESH_NET_DIST_LIST",

  // 刷新PC分享管理列表
  REFRESH_PC_DIST_SHARE_LIST = "REFRESH_PC_DIST_SHARE_LIST",

  // 网盘页面，网盘列表滚动到最顶部
  NET_DISK_SCROLL_TO_TOP = "NET_DISK_SCROLL_TO_TOP",

  // 网盘 刷新容量
  NET_DIST_REFRESH_CAPACITY = "NET_DIST_REFRESH_CAPACITY",

  // 登录成功
  ON_LOGIN_SUCCESS = "ON_LOGIN_SUCCESS",

  // 聊天会员 支付成功
  ON_CHAT_VIP_PAY_SUCCESS = "ON_CHAT_VIP_PAY_SUCCESS",

  // 学术会员 支付成功
  ON_ACADEMIC_VIP_PAY_SUCCESS = "ON_ACADEMIC_VIP_PAY_SUCCESS",

  // 加油包 支付成功
  ON_REFUEL_PAY_SUCCESS = "ON_REFUEL_PAY_SUCCESS",

  // 支付成功弹框界面，点击关闭按钮
  ON_PAY_SUCCESS_POP_CLICK_CLOSE = "ON_PAY_SUCCESS_POP_CLICK_CLOSE",

  // 在vip支付页面，主动点击了左侧边栏的卡券，立即使用
  VIP_PAY_USE_COUPON = "VIP_PAY_USE_COUPON",

  // 打开左侧栏的对话历史记录界面
  OPEN_SIDEBAR_HISTORY_LIST = "OPEN_SIDEBAR_HISTORY_LIST",

  // 关闭左侧栏的对话历史记录界面
  CLOSE_SIDEBAR_HISTORY_LIST = "CLOSE_SIDEBAR_HISTORY_LIST",

  // 左侧栏 唤起置顶成功提示
  SHOW_LEFT_SIDE_TOPPING_SUCCESS = "SHOW_LEFT_SIDE_TOPPING_SUCCESS",

  // h5切换 对话历史记录弹框 的类型
  H5_SWITCH_HISTORY_LIST_TYPE = "H5_SWITCH_HISTORY_LIST_TYPE",

  // AI玩法立即体验
  AI_FUNCTIONS_EXPERIENCE = "AI_FUNCTIONS_EXPERIENCE",

  // 切换支付方式
  SWITCH_PAY_TYPE = "SWITCH_PAY_TYPE",

  // 重置左侧历史列表
  RESET_LEFT_HISTORY = "RESET_LEFT_HISTORY",

  // 移动端重置左侧历史列表
  MOBILE_RESET_LEFT_HISTORY = "MOBILE_RESET_LEFT_HISTORY",

  // 更新左侧历史列表item数据
  UPDATE_LEFT_HISTORY_LIST_ITEM_DATA = "UPDATE_LEFT_HISTORY_LIST_ITEM_DATA",

  // h5里，打开引导进入web站点的弹框
  H5_OPEN_TO_WEBSITE_MODAL = "H5_OPEN_TO_WEBSITE_MODAL",

  // 更新首页svip权益数据
  RESET_HOME_SVIP_BENEFIT_DATA = "RESET_HOME_SVIP_BENEFIT_DATA",

  // 刷新首页全部模块
  REFRESH_HOME_PAGE_ALL_CONTENT = "REFRESH_HOME_PAGE_ALL_CONTENT",
}
