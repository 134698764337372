import { httpPlus} from "./request";


// 获取订单支付状态
export const requestSiteInfoConfig = () => {
  return httpPlus<NSite.ISiteInfoItem[]>(
    "post",
    `/chat-server/site/site-info/site-info`
  )
}
