/*
 * 学术vip
 * */
import { Payload } from "@/types/type";
import { EffectsCommandMap } from "@umijs/max";
import { history } from 'umi';
import {
  academicVipbenefitInfo,
  requestAcademicVipUserInfo,
  requestUserNowVipBenefit,
  requestUserNowVipInfo,
} from "@/server/academicVip";
import { diskFileInfo } from "@/server/lb-chat-ai-search";
import {isVipPayLink, showVipPayPop} from "@/utils";

export default {
  state: {
    // 是否展示vip弹框
    isShowAcademicVipPopup: false,

    // 用户学术vip信息
    academicVipInfo: null,

    // 用户学术当前VIP信息 用于一些只判断是否会员的业务,
    academicUserNowVipInfo: null,

    // 用户学术当前VIP剩余信息，获取当前VIP所有权益额度剩余list
    academicUserNowVipBenefit: [],

    // 用户权益剩余信息,
    academicVipBenefitInfo: null,

    // 搜索权益信息
    searchVipBenefitInfo: null,

    // 网盘容量
    diskFileInfoData: null,
  } as NAcademicVip.IState,

  reducers: {
    setIsShowAcademicVipPopup: (
      state: NAcademicVip.IState,
      { payload }: Payload
    ) => {
      if(!isVipPayLink() && payload === true){
        // 直接跳链接
        showVipPayPop({vipType: 'academicVip'})

        return {
          ...state
        }
      }

      return {
        ...state,
        isShowAcademicVipPopup: payload,
      };
    },

    setAcademicVipInfo: (state: NAcademicVip.IState, { payload }: Payload) => {
      return {
        ...state,
        academicVipInfo: payload,
      };
    },

    setAcademicUserNowVipInfo: (
      state: NAcademicVip.IState,
      { payload }: Payload
    ) => {
      return {
        ...state,
        academicUserNowVipInfo: payload,
      };
    },

    setAcademicUserNowVipBenefit: (
      state: NAcademicVip.IState,
      { payload }: Payload
    ) => {
      return {
        ...state,
        academicUserNowVipBenefit: payload,
      };
    },

    setAcademicVipBenefitInfo: (
      state: NAcademicVip.IState,
      { payload }: Payload
    ) => {
      return {
        ...state,
        academicVipBenefitInfo: payload,
      };
    },

    setSearchVipBenefitInfo: (
      state: NAcademicVip.IState,
      { payload }: Payload
    ) => {
      return {
        ...state,
        searchVipBenefitInfo: payload,
      };
    },

    setDiskFileInfoData: (state: NAcademicVip.IState, { payload }: Payload) => {
      return {
        ...state,
        diskFileInfoData: payload,
      };
    },
  },

  effects: {
    *updateAll(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      yield put({ type: "updateAcademicVipInfo" });
      yield put({ type: "updateAcademicUserNowVipInfo" });
      yield put({ type: "updateAcademicUserNowVipBenefit" });
      yield put({ type: "updateDiskFileInfo" });
    },

    *updateAcademicVipInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      const { isSuccess, data } = (yield call(
        requestAcademicVipUserInfo
      )) as NCommon.IHttpRes<NAcademicVip.IConfigListItem[]>;

      if (!isSuccess) {
        return;
      }

      yield put({
        type: "setAcademicVipInfo",
        payload: data,
      });
    },

    *updateAcademicUserNowVipInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(requestUserNowVipInfo);
        if (res?.code == 0) {
          yield put({
            type: "setAcademicUserNowVipInfo",
            payload: res.data,
          });
        }
      } catch (err) {}
    },

    *updateAcademicUserNowVipBenefit(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(requestUserNowVipBenefit);
        if (res?.code == 0) {
          yield put({
            type: "setAcademicUserNowVipBenefit",
            payload: res.data,
          });
        }
        return Promise.resolve(res);
      } catch (err) {}
    },

    // 需传参用户权益剩余信息统一总接口
    *updateAcademicVipBenefitInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(academicVipbenefitInfo, payload);
        if (res?.code == 0) {
          if (res.data.benefitCode == "academic_search") {
            yield put({
              type: "setSearchVipBenefitInfo",
              payload: res.data,
            });
          } else {
            yield put({
              type: "setAcademicVipBenefitInfo",
              payload: res.data,
            });
          }
        }
        return Promise.resolve(res);
      } catch (err) {}
    },

    // 网盘容量更新
    *updateDiskFileInfo(
      { payload }: Payload,
      { call, put, select }: EffectsCommandMap
    ): Generator {
      try {
        const res: any = yield call(diskFileInfo, payload);
        if (res?.code == 0) {
          // 将已使用百分比添加到数据中
          const totalCapacity = parseInt(res.data.totalCapacity);
          const usedCapacity = parseInt(res.data.usedCapacity);
          const usedCapacityPercentage = (usedCapacity / totalCapacity) * 100;
          res.data.usedCapacityPercentage =
            usedCapacityPercentage > 0 && usedCapacityPercentage < 2
              ? 2
              : usedCapacityPercentage;
          yield put({
            type: "setDiskFileInfoData",
            payload: res.data,
          });
        }
        return Promise.resolve(res);
      } catch (err) {}
    },
  },
};
